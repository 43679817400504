import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal, Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useAlert } from "react-alert";
import FeeReturnModal from "./FeeReturnModal";
import EditFeeReturn from "./EditFeeReturn";
import DeleteFeeReturn from "./DeleteFeeReturn";
const FeeReturn = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");

  const [feetypeslist, setfeetypeslist] = useState([]);
  const [feereturnList, setfeereturnList] = useState([]);
  const [admission_id, setadmission_id] = useState("");
  const [loading, setloading] = useState(false);
  const [returnModal, setreturnModal] = useState(false);
  const [selectedfeeId, setselectedfeeId] = useState("");
  const [studentObject, setstudentObject] = useState([]);
  const [editStudentObject, setEditStudentObject] = useState([]);
  const [challanHistory, setChalanHistory] = useState([]);
  const [feeReturnHistory, setfeeReturnHistory] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [editmodal, seteditmodal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [past6monthsChallans, setpast6monthsChallans] = useState([]);

  useEffect(() => {
    getFeeList();
    // getfeeReturnList();
    sessions();
  }, []);
  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFeeList = () => {
    fetch(`${baseUrl}/feestypes`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("fee type list", data);
        setfeetypeslist(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getfeeReturnList = () => {
    setloading(true);
    const data = {
      year_id: yearId,
    };
    fetch(`${baseUrl}/fee_return_index`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        setfeereturnList(data.payload);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);

        console.log(err);
      });
  };

  const getStudentDetails = () => {
    if (admission_id == "") {
      alert.error("Please enter admission Id");
    } else {
      const data = {
        student_admission_id: admission_id,
      };
      const data2 = {
        admission_id: admission_id,
        registration_id: "",
      };
      setSearchLoading(true);
      console.log(data);

      fetch(`${baseUrl}/get_student_for_fee_return`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            //fee_returns
            setChalanHistory(data.payload.fee_details);
            setstudentObject(data.payload.student);
            setfeeReturnHistory(data.payload.fee_returns);
            setreturnModal(true);
            setSearchLoading(false);
          } else if (!data.metadata.success) {
            Object.values(data?.metadata?.message).map((item) => {
              alert.error(item[0]);
            });
            setSearchLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      ////secondapi

      fetch(`${baseUrl}/student_chllan_by_admission_id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data2),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("this is whole", data);
          if (data.metadata.success) {
            setpast6monthsChallans(data.payload.past_6_month_challan_details);
          } else {
          }
        })
        .catch((err) => {});
    }
  };

  const editStudent = (student) => {
    setEditStudentObject(student);
    seteditmodal(true);
  };

  const deleteFee = (id) => {
    setselectedfeeId(id);
    setdeleteModal(true);
  };

  return (
    <div>
      <Paper className="p-4 mt-3">
        <div className="row mt-5">
          <div className="col-md-3">
            <div className="d-flex flex-column ">
              <label>
                <b>Select Session</b>
              </label>
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-1 mt-9">
            <button
              disabled={loading}
              onClick={getfeeReturnList}
              className="btn btn-primary"
            >
              Show
            </button>
          </div>

          <div className="col-md-4">
            <label>
              <b>Enter Student Name / Addmission ID</b>
            </label>
            <input
              onChange={(e) => setadmission_id(e.target.value)}
              className="form-control"
              placeholder="Enter Student Name/Addmission ID"
            />
          </div>

          <div className="col-md-2 mt-9">
            <button
              disabled={searchLoading}
              onClick={getStudentDetails}
              className="btn btn-primary"
            >
              Search and Return
            </button>
          </div>
        </div>
        {feereturnList.length > 0 ? (
          <Table responsive style={{ marginTop: "30px" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Sr.No</th>
                <th style={{ textAlign: "center" }}>Name</th>
                <th style={{ textAlign: "center" }}>Fee Return Amount</th>
                <th style={{ textAlign: "center" }}>Date</th>
                <th style={{ textAlign: "center" }}>Student Class</th>
                <th style={{ textAlign: "center" }}>Status</th>
                <th style={{ textAlign: "center" }}>Edit</th>
                <th style={{ textAlign: "center" }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {feereturnList.map((item, index) => (
                <tr key={item.id}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td style={{ textAlign: "center" }}>{item?.student?.name}</td>
                  <td style={{ textAlign: "center" }}>
                    {item.fee_return_amount}
                  </td>
                  <td style={{ textAlign: "center" }}>{item.date}</td>
                  <td style={{ textAlign: "center" }}>
                    {item?.student?.student_class?.name}
                  </td>
                  <td
                    style={{
                      color:
                        item?.status == 1
                          ? "orange"
                          : item?.status == 2
                          ? "Green"
                          : "",
                    }}
                    className="text-center"
                  >
                    {item?.status == 1 ? "Under Process" : "Processed"}
                  </td>
                  {item.status == "1" ? (
                    <td className="text-center">
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => editStudent(item)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  {item.status == "1" ? (
                    <td className="text-center">
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => deleteFee(item.id)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : null}

        {searchLoading || loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : null}
      </Paper>

      <FeeReturnModal
        challanHistory={challanHistory}
        feeReturnHistory={feeReturnHistory}
        past6monthsChallans={past6monthsChallans}
        reload={() => getfeeReturnList()}
        show={returnModal}
        hide={() => setreturnModal(false)}
        studentDetails={studentObject}
      />

      <EditFeeReturn
        reload={() => getfeeReturnList()}
        show={editmodal}
        hide={() => seteditmodal(false)}
        studentDetails={editStudentObject}
      />

      <DeleteFeeReturn
        show={deleteModal}
        hide={() => setdeleteModal(false)}
        reload={() => getfeeReturnList()}
        fee_id={selectedfeeId}
      />
    </div>
  );
};

export default FeeReturn;
