import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";

function DeleteFeeReturn({ hide, show, reload, fee_id }) {
  const authtoken = localStorage.getItem("userToken");

  const [loading, setloading] = useState(false);
  const alert = useAlert();

  const deleteFee = () => {
    setloading(true);
    fetch(`${baseUrl}/fee_return/${fee_id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setloading(false);
          hide();
          reload();
          alert.success(data.metadata.message);
        } else {
          setloading(false);
          alert.error(data.metadata.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };
  console.log(fee_id);
  return (
    <Modal
      onHide={hide}
      // size='lg'
      show={show}
    >
      <Modal.Header className="modal_header_style" closeButton>
        <Modal.Title
          className="Modal_Heading_font"
          id="contained-modal-title-vcenter"
        >
          Are you sure you want to delete?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        {loading ? (
          <Spinner animation="border" variant="danger" />
        ) : (
          <>
            <button onClick={deleteFee} className="btn btn-primary">
              Delete
            </button>
            <button onClick={hide} className="btn btn-danger">
              Cancel
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteFeeReturn;
