import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import EditAppointedStaff from "./EditAppointedStaff/EditAppointedStaff";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Pagination from "@material-ui/lab/Pagination";

import SVG from "react-inlinesvg";
import DefineSallary from "./DefineSallary/DefineSallary";
import { Link, useHistory } from "react-router-dom";
import GenerateSallary from "./GenerateSallary/GenerateSallary";
import ViewStaffDetails from "./ViewStaffDetails/ViewStaffDetails";
import ViewStafStudents from "./ViewStafStudents/ViewStafStudents.js";
import Paper from "@material-ui/core/Paper";
import usePagination from "../../../../components/usePagination/usePagination";
import { PremissionChecker } from "../../../../services/PremissionChecker.js";

const AppointedStaff = () => {
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);
  // console.log(user.user.campus.id)
  const [searchtext, setsearchtext] = useState("");
  const [generateModal, setgenerateModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [currentObject, setcurrentObject] = useState({});
  const [studentDetails, setStudentDetails] = useState([]);
  const [AllStaff, setAllStaff] = useState([]);
  const [viewModal, setviewModal] = useState(false);
  const [studentView, setStudentView] = useState(false);
  const [defineSalaryModal, setdefineSalaryModal] = useState(false);
  const [viewLoading, setviewLoading] = useState(false);
  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  const [campusID, setcampusID] = useState("");
  const [searchtext2, setsearchtext2] = useState("");

  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      getAllStaff(campusidget);
    }
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };
  const getAllStaff = (e) => {
    let id = "";
    if (e == undefined) {
      id = campusID;
    } else {
      id = e;
    }
    setcampusID(id);

    setpreviousselectedcampus(id);
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
  };

  const openEditModal = (staff) => {
    setcurrentObject(staff);
    seteditModal(true);
  };
  const showStudents = (staff) => {
    console.log(staff);
    setStudentDetails(staff.students);
    setStudentView(true);
  };

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;
  // const currentPosts = AllStaff.slice(indexOfFirstPost, indexOfLastPost);

  const dataSearch = AllStaff.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.full_name?.toLowerCase().includes(text) ||
          user?.emp_code
            ?.toString()
            .toLowerCase()
            .includes(text)
      : true;
  });

  const currentPosts = dataSearch.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  // const dataSearch = currentPosts.filter((user) => {
  //   let text = searchtext.toLowerCase();
  //   return searchtext ? user?.full_name?.toLowerCase().includes(text)||user?.emp_code?.toLowerCase().includes(text) : true;
  // });
  const defineSalaryFunc = (staff) => {
    setcurrentObject(staff);
    setdefineSalaryModal(true);
  };

  const gotoDetails = (id, staff) => {
    setStudentDetails(staff.students);
    setviewLoading(true);
    fetchDetails(id);
  };

  const fetchDetails = async (id) => {
    // console.log('run ho rha h ',id)
    await fetch(`${baseUrl}/emp-salary-detail/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then(async (res) => {
        res.json().then((data) => {
          console.log(data.payload + "yyyyyyesdsdsdsdsdsd");
          setcurrentObject(data.payload);
          setviewModal(true);
          setviewLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setviewLoading(false);
      });
  };

  const opensalarygenerate = (staff) => {
    setcurrentObject(staff);
    setgenerateModal(true);
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(dataSearch.length / PER_PAGE);
  const _DATA = usePagination(dataSearch, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const searchbyRegId = (e) => {
    e.preventDefault();
    const data = {
      name_code: searchtext2.toLocaleUpperCase(),
    };

    fetch(`${baseUrl}/appointed-employees/filter/name-code`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          if (data.payload.length === 0) {
            alert.error("Match not found");
          }
          setAllStaff(data.payload);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg[0]);
          });
        }
      })
      .catch((err) => {
        console.log("err is re", err);
      });
  };
  const handleShowstaff = () => {
    setloading(true);

    fetch(`${baseUrl}/emp_appointed/${campusID}/1`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const arr = data.payload;
        arr.forEach((item, i) => {
          item["Sr"] = i + 1;
        });
        setAllStaff(arr);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row mx-1 mt-5">
          <div className="col-md-4">
            <label className="form-control-label">
              <b>Select Campus</b>
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              className="form-control form-select h-40px"
              name="campus_id"
              value={allcampuses.length == 0 ? "" : previousselectedcampus}
              onChange={(e) => getAllStaff(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Campus</option>
              {allcampuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-5">
            <label className="form-control-label">
              <b>Search Employee</b>
            </label>
            <input
              className="form-control"
              onChange={(e) => setsearchtext(e.target.value)}
              id="searchInput"
              placeholder="Search ..."
            />
          </div>
          <div className="col-md-2 my-7">
            <button onClick={handleShowstaff} className="btn btn-primary">
              Show
            </button>
          </div>
        </div>
        <form onSubmit={searchbyRegId}>
          <div className="row mx-1">
            <div className="col-md-6">
              <input
                onChange={(e) => setsearchtext2(e.target.value)}
                className="form-control"
                placeholder="Enter Name or Employee ID"
              />
            </div>
            <div className="col-md-4">
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </form>
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <span className="spinner-border"></span>
          </div>
        ) : (
          <>
            <Table responsive className="mt-5">
              <thead>
                <tr className="text-center">
                  <th style={{ textAlign: "start" }}>Sr. no</th>
                  <th style={{ textAlign: "start" }}>Full Name</th>
                  <th style={{ textAlign: "start" }}>Email</th>
                  <th style={{ textAlign: "start" }}>Cnic No</th>
                  <th style={{ textAlign: "start" }}>Emp Code</th>
                  <th style={{ textAlign: "start" }}>Mobile No</th>
                  <th style={{ textAlign: "start" }}>Joining Date</th>
                  <th style={{ textAlign: "start" }}>Define Salary</th>
                  <th style={{ textAlign: "start" }}>Edit</th>
                  <th style={{ textAlign: "start" }}>View</th>
                  <th style={{ textAlign: "start" }}>Generate Salary</th>
                </tr>
              </thead>
              <tbody>
                {_DATA.currentData().map((staf, index) => (
                  <tr key={staf.id}>
                    <td>{staf.Sr}</td>
                    <td
                      title={staf.full_name}
                      style={{ textAlign: "start", cursor: "pointer" }}
                    >
                      {staf.full_name}
                    </td>
                    {staf.email != null ? (
                      <td
                        title={staf.email}
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {staf.email}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    <td style={{ textAlign: "start" }}>{staf.cnic_no}</td>
                    <td style={{ textAlign: "center" }}>{staf.emp_code}</td>

                    <td style={{ textAlign: "start" }}>
                      {"+92" + staf.mobile_no}
                    </td>
                    <td style={{ textAlign: "center" }}>{staf.joining_date}</td>
                    <td style={{ textAlign: "center" }}>
                      {/* {staf.pay_scale_id == null ? ( */}
                      {PremissionChecker(
                        user,
                        "appointed_staff.define_salary"
                      ) ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => defineSalaryFunc(staf)}
                          className={"svg-icon svg-icon-md svg-icon-success"}
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Money.svg"
                            )}
                          />
                        </span>
                      ) : null}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {PremissionChecker(
                        user,
                        "appointed_staff.edit_salary"
                      ) ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => openEditModal(staf)}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Edit.svg"
                            )}
                          />
                        </span>
                      ) : null}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {PremissionChecker(
                        user,
                        "appointed_staff.view_details"
                      ) ? (
                        staf.pay_scale_id !== null ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => gotoDetails(staf.id, staf)}
                            className={"svg-icon svg-icon-md svg-icon-primary"}
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Code/Info-circle.svg"
                              )}
                            />
                          </span>
                        ) : (
                          <span
                            title="You need to Define Salary First"
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            Not
                          </span>
                        )
                      ) : null}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {PremissionChecker(
                        user,
                        "appointed_staff.generate_salary"
                      ) ? (
                        staf.pay_scale_id !== null ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => opensalarygenerate(staf)}
                            className={"svg-icon svg-icon-md svg-icon-primary"}
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Unlock.svg"
                              )}
                            />
                          </span>
                        ) : (
                          <span
                            title="You need to Define Salary First"
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            No
                          </span>
                        )
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Pagination
              color="primary"
              count={count}
              size="large"
              page={page}
              variant="text"
              shape="round"
              onChange={handleChange}
            />
          </>
        )}

        <Modal show={editModal} size="xl">
          <Modal.Header className="modal_header_style text-center" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Staff details
              <h6>
                {currentObject.full_name} | EmpCode: {currentObject.emp_code}
              </h6>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditAppointedStaff
              staffDetails={currentObject}
              show={() => seteditModal()}
              reload={() => {
                if (campusID) {
                  handleShowstaff();
                }
              }}
              campusID={campusID}
            />
          </Modal.Body>
        </Modal>

        <Modal show={defineSalaryModal} size="lg">
          <Modal.Header className="modal_header_style text-center" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Define And Update Salary
              <h6>
                {currentObject.full_name} | EmpCode: {currentObject.emp_code}
              </h6>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <DefineSallary
              staffDetails={currentObject}
              show={() => setdefineSalaryModal()}
              reload={() => getAllStaff()}
            />
          </Modal.Body>
        </Modal>

        <Modal size="lg" show={generateModal}>
          <Modal.Header className="modal_header_style text-center" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Sallary
              <h6>
                {currentObject.full_name} | EmpCode: {currentObject.emp_code}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GenerateSallary
              reload={() => getAllStaff()}
              show={() => setgenerateModal()}
              empDetails={currentObject}
            />
          </Modal.Body>
        </Modal>

        {/* View Modal  */}
        <Modal onHide={() => setviewModal(false)} show={viewModal} size="xl">
          <Modal.Header className="modal_header_style text-center" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              View Details
              <h6>
                {currentObject.full_name} | EmpCode: {currentObject.emp_code}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewStaffDetails
              EmpDetails={currentObject}
              studentDetails={studentDetails}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setviewModal(false)}
              className="btn btn-danger btn_width"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
};

export default AppointedStaff;
