import React, { useEffect, useState } from "react";
// import { Table, Modal, Button } from 'react-bootstrap'
import { Table, Modal, Spinner, Alert, Form } from "react-bootstrap";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../../services/config";
import Pagination from "../../../components/Pagination/Pagination";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import ViewModal from "./viewModal";
import { arrayOf } from "prop-types";
import PrintVoucher from "../AccountsVocher/PrintVoucher";

export default function DNVoucher() {
  //configration data
  const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const alert = useAlert();
  //store
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const dispatch = useDispatch();
  //array states
  const [allcampuses, setallcampuses] = useState([]);
  const [alldnvoucher, setalldnvoucher] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [selectedvouchers, setselectedvouchers] = useState([]);
  const [allsessions, setallsessions] = useState([]);
  //object states
  const [selectedvoucher, setselectedvoucher] = useState({});

  //strings states
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  const [selectedsession, setselectedsession] = useState("");

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  const currentDate = yyyy + "-" + mm + "-" + dd;

  const [selecteddate, setselecteddate] = useState(currentDate);
  //boolean states
  let [loading, setloading] = useState(false);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [viewmodal, setviewmodal] = useState(false);
  const [show, setshow] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [submitloading, setsubmitloading] = useState(false);
  const [checkdata, setcheckdata] = useState([]);
  const [salaryvoucher, setsalaryvoucher] = useState([]);

  const [alldvvouchers, setalldvvouchers] = useState({});
  const [allsalaryvoucher, setallsalaryvoucher] = useState({});

  useEffect(() => {
    // if (campusidget != "") {

    //     getAllDnVouchers(campusidget);
    // }
    getAllDnVouchers();
    // getAllCampus();
    getAllSessions();
  }, []);

  const [printvouchermodal, setprintvouchermodal] = useState(false);

  const getAllDayEndsalaryVouchers = () => {
    fetch(`${baseUrl}/payed_salaries_list`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      //   body: JSON.stringify({}),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          console.log(data, "all day end vouchers");
          if (data.metadata) {
            if (data.metadata.success) {
              // alert.show("Data get");
              setallsalaryvoucher(data.payload);
              setsalaryvoucher([...data.payload.salaries]);
            } else {
              alert.show("Error Occured");
            }
          } else {
            alert.show("Error Occured");
          }
        })
      )
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setallsessions(data.payload);

            data.payload.map((session, index) => {
              if (session.active_financial_year == "1") {
                setselectedsession(session.id);
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get campus function
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAllDnVouchers = () => {
    // setpreviousselectedcampus(id)
    setcheckallcheckbox(false);
    setalldvvouchers({});
    setalldnvoucher([]);
    // dispatch({
    //     type: 'SELECTED_CAMPUS_ID',
    //     payload: {
    //         setselectedcampusid: id
    //     }
    // })
    // let data = {
    //     campus_id: id
    // }
    setloading(true);
    fetch(`${baseUrl}/submitted_challan_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({}),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              // alert.show("Data get");
              console.log(data.payload);
              setalldvvouchers({ ...data.payload });
              setalldnvoucher([...data.payload.feeChallan]);
              // let selectctedvouchersarray = []
              // data.payload.map((item) => {
              //     selectctedvouchersarray.push(item.id)
              // })

              // setselectedvouchers([...selectctedvouchersarray])
              // setalldnvoucher(

              //     ...data.payload

              // )
              getAllDayEndsalaryVouchers();
            } else {
              alert.show("Error Occured");
            }
          } else {
            alert.show("Error Occured");
          }
        })
      )
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  // const allcheckboxChecked = () => {
  //   let middlearray = [];
  //   let studentarray = [];
  //   if (checkallcheckbox) {
  //     setcheckallcheckbox(false);
  //     alldnvoucher.map((item, i) => {
  //       item.checked = false;
  //       middlearray.push(item);
  //     });
  //   } else {
  //     setcheckallcheckbox(true);
  //     alldnvoucher.map((item, i) => {
  //       item.checked = true;
  //       studentarray.push(item.id);
  //       middlearray.push(item);
  //     });
  //   }
  //   setselectedvouchers([...studentarray]);
  //   console.log(middlearray);
  //   setalldnvoucher([...middlearray]);
  // };
  // const checkBoxClick = (e, index) => {
  //   setcheckallcheckbox(true);
  //   let middlearray = [];
  //   let studentarray = [];
  //   alldnvoucher.map((item, i) => {
  //     if (item.id == index) {
  //       item.checked = !item.checked;
  //     }
  //     if (!item.checked) {
  //       setcheckallcheckbox(false);
  //     }
  //     if (item.checked) {
  //       studentarray.push(item.id);
  //     }
  //     middlearray.push(item);
  //   });
  //   setselectedvouchers([...studentarray]);
  //   console.log(studentarray);
  //   setalldnvoucher([...middlearray]);
  // };

  const gotoviewpage = (item) => {
    setselectedvoucher(item);
    setviewmodal(true);
  };

  const submitForm = () => {
    console.log(selectedvouchers, "selectedvouchers");
    // if (selecteddate == "" || selectedsession == "") {
    //   setisallfields(true);
    //   return;
    // }
    setisallfields(false);
    setsubmitloading(true);
    let salaryids = [];
    salaryvoucher.map((item) => {
      salaryids.push(item.id);
    });

    let data = {
      fee_challan_ids: selectedvouchers,
      salary_ids: salaryids,
      year_id: selectedsession,
      date: selecteddate,
    };
    console.log(data);

    fetch(`${baseUrl}/createchllans_voucher`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setsubmitloading(false);
          setshow(false);
          setisallfields(false);
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.show("Submitted Successfully");
            } else {
              alert.show(data.metadata.message);
            }
          } else {
            alert.show("Error Occured");
          }
        })
      )
      .catch((err) => {
        setshow(false);
        setsubmitloading(false);
        setisallfields(false);
        console.log(err);
        alert.show("Error Occured");
      });
  };

  const previewHandler = () => {
    setisallfields(false);
    let salaryids = [];
    salaryvoucher.map((item) => {
      salaryids.push(item.id);
    });

    let data = {
      fee_challan_ids: selectedvouchers,
      salary_ids: salaryids,
      year_id: selectedsession,
      date: selecteddate,
      preview: 1,
    };
    console.log(data);
    setloading(true);

    fetch(`${baseUrl}/createchllans_voucher`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          setselectedvoucher(data.payload);
          setprintvouchermodal(true);
        })
      )
      .catch((err) => {
        console.log(err);
        alert.show("Error Occured");
      });
  };

  const modelCLose = () => {
    setprintvouchermodal(false);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          {/* <button
              onClick={() => {
                setshow(true);
              }}
              className="btn btn-success button_blue"
            >
              Submit
            </button> */}
          {alldnvoucher.length == "0" && salaryvoucher.length == "0" ? (
            ""
          ) : (
            <button
              disabled={loading}
              onClick={previewHandler}
              className="btn ml-2 btn-success button_blue"
            >
              Preview
            </button>
          )}
        </div>
        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>
        <br />

        {/* <div className="d-flex col-sm-6 align-items-center">
                    <select
                        style={{ marginTop: 30, height: 40, }}
                        className="form-select"
                        name="campus_id"
                        value={allcampuses.length == 0 ? '' : previousselectedcampus}
                        onChange={(e) => getAllDnVouchers(e.target.value)}
                        aria-label="Default select example"
                    >
                        <option selected value=""> Select Campus</option>
                        {
                            allcampuses.map((campus, index) => (
                                user.head_office.id == campus.head_office_id ? <>
                                    <option key={index} value={campus.id}>{campus.name}</option>
                                </> : null

                            ))
                        }
                    </select>
                </div> */}
        <br />

        <Table responsive>
          <tr id="tbl">
            {/* <th>
              <input
                onClick={(e) => {
                  allcheckboxChecked();
                }}
                checked={checkallcheckbox}
                style={{}}
                placeholder="Checkbox"
                type="checkbox"
              />
            </th> */}
            <th>Registration ID</th>
            <th>Addmission ID</th>
            <th>Challan No</th>
            <th>Payable</th>
            <th>Paid</th>
            <th>Due Date</th>
            <th>Received Date</th>
            <th>View</th>
          </tr>

          <tbody>
            {alldnvoucher.map((item, index) => {
              return (
                <tr>
                  {/* <td style={{ textAlign: "start" }}>
                    <input
                      key={item.id}
                      onClick={(e) => {
                        checkBoxClick(e, item.id);
                      }}
                      value={allcheckboxes[index]}
                      checked={item.checked}
                      type="checkbox"
                    />
                  </td> */}
                  <td style={{ textAlign: "start" }}>
                    {item?.student?.registration_id}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {item.student_admission_id}
                  </td>
                  <td style={{ textAlign: "start" }}>{item.challan_no}</td>
                  <td style={{ textAlign: "start" }}>{item.payable}</td>
                  <td style={{ textAlign: "start" }}>{item.paid}</td>
                  <td style={{ textAlign: "start" }}>{item.due_date}</td>
                  <td style={{ textAlign: "start" }}>{item.received_date}</td>

                  <td style={{ textAlign: "start" }}>
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => gotoviewpage(item)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Devices/Display2.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                </tr>

                // alldvvouchers
              );
            })}
            <tr id="tbl">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                Total Challan Amount :- {alldvvouchers.total_challan_amount}
              </th>
            </tr>
            <tr>
              <h2>Salaries</h2>
            </tr>

            <tr>
              <th> Sr#</th>
              <th> ID</th>
              <th>Name</th>
              <th>Father name</th>
              <th>Employ id</th>
              <th>Account no</th>
              <th>Net pay</th>
              <th>Salary Month</th>
            </tr>
            {salaryvoucher.length == 0 ? null : (
              <>
                {salaryvoucher.map((item, index) => {
                  return (
                    <tr>
                      <td style={{ textAlign: "start" }}>{index + 1}</td>
                      <td style={{ textAlign: "start" }}>{item.id}</td>

                      <td style={{ textAlign: "start" }}>
                        {item.employee.full_name}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {item.employee.father_name}
                      </td>

                      <td style={{ textAlign: "start" }}>{item.employee_id}</td>
                      <td style={{ textAlign: "start" }}>{item.account_no}</td>
                      <td style={{ textAlign: "start" }}>{item.net_pay}</td>
                      <td style={{ textAlign: "start" }}>
                        {item.salary_month}
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
            <tr id="tbl">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                Total Gross Salary :- {allsalaryvoucher.total_gross_salary}
              </th>
              <th>Total Net Salary :- {allsalaryvoucher.total_net_salary}</th>
            </tr>
            <tr></tr>
          </tbody>
        </Table>
      </Paper>
      {viewmodal ? (
        <ViewModal
          show={viewmodal}
          onHide={setviewmodal}
          feedetail={selectedvoucher}
        />
      ) : null}
      <Modal size="lg" show={show}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Submit To GL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div class="form-group col-sm-6 flex-column d-flex">
              <label>
                Select Session<span style={{ color: "red" }}>*</span>
              </label>
              <select
                style={{ height: 40 }}
                className="form-select"
                disabled
                name="campus_id"
                onChange={(e) => {
                  setselectedsession(e.target.value);
                }}
                value={selectedsession}
                // onChange={(e) => getAllDnVouchers(e.target.value)}
                aria-label="Default select example"
              >
                <option value=""> Select ..</option>
                {allsessions.map((session, index) => (
                  <option
                    key={index}
                    // selected={
                    //   session.active_financial_year == "1" ? true : false
                    // }
                    value={session.id}
                  >
                    {session.year}
                  </option>
                ))}
              </select>
            </div>

            <div class="form-group  col-sm-6 flex-column d-flex">
              <label>
                Select Date<span style={{ color: "red" }}>*</span>
              </label>
              <input
                disabled
                onChange={(e) => {
                  setselecteddate(e.target.value);
                }}
                value={selecteddate}
                className="form-control"
                type="date"
                max="9999-12-30"
              />
            </div>
          </div>
        </Modal.Body>

        {isallfields ? (
          <>
            <Alert
              variant="danger"
              onClose={() => setisallfields(false)}
              dismissible
            >
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>Please Select All Fields</p>
            </Alert>
          </>
        ) : null}

        {submitloading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "30px",
              marginBottom: "15px",
            }}
          >
            {<span className="spinner-border"></span>}
          </div>
        ) : (
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                onClick={() => {
                  submitForm();
                }}
                style={{ width: "150px", height: "40px", marginRight: "10px" }}
                className="btn btn-success button_blue button_blue"
              >
                Submit
              </button>
              <button
                onClick={() => {
                  setshow(false);
                  setisallfields(false);
                }}
                style={{ width: "150px", height: "40px" }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>

      {printvouchermodal && (
        <PrintVoucher
          show={printvouchermodal}
          selectedsession={selectedsession}
          onHide={setprintvouchermodal}
          selectedVoucher={selectedvoucher}
          selecteddate={selecteddate}
          salaryvoucher={salaryvoucher}
          selectedvouchers={selectedvouchers}
          DNVoucher={getAllDnVouchers}
          modelCLose={modelCLose}
        />
      )}
    </div>
  );
}
