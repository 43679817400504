import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../../services/config";
import { Paper } from "@material-ui/core";
import { Modal, Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import TrialExport from "./TrialExport";
import { upperCase } from "lodash";

const TrialBalance = () => {
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [sessionName, setSessionName] = useState("");
  const [debitSum, setDebitSum] = useState(0);
  const [creditSum, setCreditSum] = useState(0);
  const [lossDebited, setLossDebited] = useState(0);
  const [profitCredited, setProfitCredited] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const pageStyle = `
  @page {
    size: a4 !important;
  }
  `;

  useEffect(() => {
    getSettingData();
    sessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
            setSessionName(session.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetData = () => {
    if (yearId == "") {
      alert.error("Please select the year");
      return;
    }

    if (date == "") {
      alert.error("Please select the date");
      return;
    }
    setLoading(true);
    setIsVisible(false);

    const data = {
      year_id: yearId,
      date: date,
    };
    fetch(`${baseUrl}/trial_balance_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setIsVisible(true);
        if (data.payload) {
          setReportData(data.payload.details);
          setCreditSum(data.payload.conclusion?.grand_total_credit);
          setDebitSum(data.payload.conclusion?.grand_total_debit);
          setLossDebited(data.payload.conclusion?.loss_debited);
          setProfitCredited(data.payload.conclusion?.profit_credited);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const calckNumberDebit = (data) => {
    const totalDebit = data.reduce((a, c) => a + parseInt(c.debit), 0);
    const totalCredit = data.reduce((a, c) => a + parseInt(c.credit), 0);
    if (totalDebit > totalCredit) {
      return Number(totalDebit) - Number(totalCredit);
    } else {
      return 0;
    }
  };
  const calckNumberCredit = (data) => {
    const totalDebit = data.reduce((a, c) => a + parseInt(c.debit), 0);
    const totalCredit = data.reduce((a, c) => a + parseInt(c.credit), 0);
    if (totalCredit > totalDebit) {
      return Number(totalCredit) - Number(totalDebit);
    } else {
      return 0;
    }
  };

  const mainNumberCalc = (data) => {
    let totalDebit = 0;
    let totalCredit = 0;
    data.map((item) => {
      item.sub_account.map((last) => {
        totalDebit += parseInt(last.debit);
      });
      item.sub_account.map((last) => {
        totalCredit += parseInt(last.credit);
      });
    });
    if (totalDebit > totalCredit) {
      return `Total Loss: ${totalDebit - totalCredit}`;
    } else {
      return `Total Profit: ${totalCredit - totalDebit}`;
    }
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => {
                  const data = sessionData.find((ses) => {
                    return ses.id == e.target.value;
                  });
                  setSessionName(data.year);
                  setYearId(e.target.value);
                }}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Date</b>
            </label>
            <input
              className="form-control"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
            />
          </div>
          <div className="col-md-4 mt-9">
            <button className="btn btn-primary mx-2" onClick={handleGetData}>
              Show
            </button>
            {reportData?.length > 0 ? (
              <>
                <ReactToPrint
                  documentTitle={`Trial Balance Report`}
                  pageStyle={pageStyle}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-25 mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <TrialExport
                  reportData={reportData}
                  debitSum={debitSum}
                  creditSum={creditSum}
                  profitCredited={profitCredited}
                  lossDebited={lossDebited}
                />
              </>
            ) : null}
          </div>
        </div>

        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : isVisible ? (
            <div id="tableboot mt-4">
              <div className="d-flex">
                <div>
                  <img
                    style={{ height: 100, width: 110 }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="mx-5" style={{ paddingTop: "10px" }}>
                  <h1>{unitName}</h1>
                  <h3>
                    Trial Balance For The Financial Year {sessionName} Till Date{" "}
                    {date}
                  </h3>
                </div>
              </div>
              <Table className="mt-4" responsive>
                <thead>
                  <tr id="tbl">
                    <th className="text-center">A/C Code</th>
                    <th className="text-center">Account Name</th>
                    <th className="text-center">Debit</th>
                    <th className="text-center">Credit</th>
                  </tr>
                </thead>

                <tbody>
                  {reportData.map((base) =>
                    base.account_group.length > 0 ? (
                      <React.Fragment key={base.base_account}>
                        <tr id="tbl">
                          <th
                            colSpan={2}
                            className="text-center text-primary"
                            style={{ fontSize: "1.1rem" }}
                          >
                            {base?.base_account}
                          </th>
                          <th
                            className="text-center text-primary"
                            style={{ fontSize: "1.1rem" }}
                          >
                            {Intl.NumberFormat("en-Us").format(
                              base?.base_account_debit
                            )}
                          </th>
                          <th
                            className="text-center text-primary"
                            style={{ fontSize: "1.1rem" }}
                          >
                            {Intl.NumberFormat("en-Us").format(
                              base?.base_account_credit
                            )}
                          </th>
                        </tr>
                        {base.account_group.map((item) =>
                          item.account_group.length > 0 ? (
                            <React.Fragment key={item.account_group_code}>
                              <tr id="tbl">
                                <td
                                  style={{
                                    color: "#fd12b3",
                                    fontWeight: "bold",
                                  }}
                                  className="text-start pl-10"
                                >
                                  {item?.account_group_code}
                                </td>
                                <td
                                  style={{
                                    color: "#fd12b3",
                                    fontWeight: "bold",
                                  }}
                                  className="text-start"
                                >
                                  {item?.account_group}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                  className="text-center text-primary"
                                >
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.account_group_debit
                                  )}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                  className="text-center text-primary"
                                >
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.account_group_credit
                                  )}
                                </td>
                              </tr>
                              {item.account_chart.map((subitem) =>
                                subitem.sub_account.length > 0 ? (
                                  <React.Fragment
                                    key={subitem.account_chart_code}
                                  >
                                    <tr id="tbl">
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "30px",
                                          fontWeight: "bold",
                                        }}
                                        className="text-center"
                                      >
                                        {subitem.account_chart_code}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "30px",
                                          fontWeight: "bold",
                                        }}
                                        className="text-start"
                                      >
                                        {subitem.account_chart}
                                      </td>

                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      ></td>
                                    </tr>
                                    {subitem.sub_account.length > 0
                                      ? subitem.sub_account.map((last) => (
                                          <React.Fragment
                                            key={last.sub_account_code}
                                          >
                                            <tr id="tbl">
                                              <td
                                                style={{
                                                  color: "black",
                                                  paddingLeft: "60px",
                                                }}
                                                className="text-end"
                                              >
                                                {last.sub_account_code}
                                              </td>
                                              <td
                                                style={{
                                                  color: "black",
                                                  paddingLeft: "60px",
                                                  cursor: "pointer",
                                                }}
                                                className="text-start"
                                                onClick={() => {
                                                  setSelectedItem(last);
                                                  setViewModal(true);
                                                }}
                                              >
                                                {last.sub_account}
                                              </td>

                                              <td
                                                style={{ color: "black" }}
                                                className="text-center"
                                              >
                                                {Intl.NumberFormat(
                                                  "en-Us"
                                                ).format(last.debit)}
                                              </td>
                                              <td
                                                style={{ color: "black" }}
                                                className="text-center"
                                              >
                                                {Intl.NumberFormat(
                                                  "en-Us"
                                                ).format(last.credit)}
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        ))
                                      : null}
                                    <tr id="tbl">
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "30px",
                                        }}
                                        className="text-start"
                                      ></td>
                                      <td
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                        }}
                                        className="text-end pr-10"
                                      >
                                        Control Accounts Total :-
                                      </td>

                                      <td
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          borderTop: "2px solid black",
                                          borderBottom: "2px solid black",
                                        }}
                                        className="text-center"
                                      >
                                        {Intl.NumberFormat("en-Us").format(
                                          subitem?.account_chart_debit
                                        )}
                                      </td>

                                      <td
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          borderTop: "2px solid black",
                                          borderBottom: "2px solid black",
                                        }}
                                        className="text-center"
                                      >
                                        {Intl.NumberFormat("en-Us").format(
                                          subitem?.account_chart_credit
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ) : null
                              )}
                            </React.Fragment>
                          ) : null
                        )}
                      </React.Fragment>
                    ) : null
                  )}

                  <tr id="tbl">
                    <td
                      style={{ color: "black", paddingLeft: "30px" }}
                      className="text-start"
                    ></td>
                    <td
                      style={{
                        fontSize: "1.3rem",
                        paddingRight: "50px",
                        fontWeight: "bold",
                      }}
                      className="text-end text-primary"
                    >
                      {lossDebited != 0
                        ? "Loss Debited :-"
                        : "Profit Credited :-"}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {lossDebited != 0
                        ? Intl.NumberFormat("en-Us").format(lossDebited)
                        : 0}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {profitCredited != 0
                        ? Intl.NumberFormat("en-Us").format(profitCredited)
                        : 0}
                    </td>
                  </tr>

                  <tr id="tbl">
                    <td
                      style={{ color: "black", paddingLeft: "30px" }}
                      className="text-start"
                    ></td>
                    <td
                      style={{
                        fontSize: "1.3rem",
                        paddingRight: "50px",
                        fontWeight: "bold",
                      }}
                      className="text-end text-primary"
                    >
                      Grand Total :-
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {Intl.NumberFormat("en-Us").format(debitSum)}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {Intl.NumberFormat("en-Us").format(creditSum)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </Paper>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot mt-4">
          <div className="d-flex">
            <div className="">
              <img
                style={{ height: 100, width: 110, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="mx-5" style={{ paddingTop: "10px" }}>
              <h1>{unitName}</h1>
              <h3>
                Trial Balance For The Financial Year {sessionName} Till Date{" "}
                {date}
              </h3>
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th className="text-center" style={{ fontSize: "18px" }}>
                  A/C Code
                </th>
                <th className="text-start" style={{ fontSize: "18px" }}>
                  Account Name
                </th>
                <th className="text-center" style={{ fontSize: "18px" }}>
                  Debit
                </th>
                <th className="text-center" style={{ fontSize: "18px" }}>
                  Credit
                </th>
              </tr>
            </thead>

            <tbody>
              {reportData.map((base) =>
                base.account_group.length > 0 ? (
                  <React.Fragment key={base.base_account}>
                    <tr
                      id="tbl"
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <th
                        className="text-center text-primary"
                        style={{
                          fontSize: "20px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {base?.base_account}
                      </th>
                      <th></th>
                      <th
                        className="text-center text-primary"
                        style={{
                          fontSize: "20px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {Intl.NumberFormat("en-Us").format(
                          base?.base_account_debit
                        )}
                      </th>
                      <th
                        className="text-center text-primary"
                        style={{
                          fontSize: "20px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {Intl.NumberFormat("en-Us").format(
                          base?.base_account_credit
                        )}
                      </th>
                    </tr>
                    {base.account_group.map((item) =>
                      item.account_group.length > 0 ? (
                        <React.Fragment key={item.account_group_code}>
                          <tr
                            id="tbl"
                            style={{
                              fontSize: "18px",
                              whiteSpace: "normal",
                              padding: "1px",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <td
                              style={{
                                color: "#fd12b3",
                                fontWeight: "bold",
                                whiteSpace: "normal",
                                padding: "1px",
                              }}
                              className="text-start pl-10"
                            >
                              {item.account_group_code}
                            </td>
                            <td
                              style={{
                                color: "#fd12b3",
                                fontWeight: "bold",
                                whiteSpace: "normal",
                                padding: "1px",
                              }}
                              className="text-start"
                            >
                              {item.account_group}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                whiteSpace: "normal",
                                padding: "1px",
                              }}
                              className="text-center text-primary"
                            >
                              {Intl.NumberFormat("en-Us").format(
                                item?.account_group_debit
                              )}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                whiteSpace: "normal",
                                padding: "1px",
                              }}
                              className="text-center text-primary"
                            >
                              {Intl.NumberFormat("en-Us").format(
                                item?.account_group_credit
                              )}
                            </td>
                          </tr>
                          {item.account_chart.map((subitem) =>
                            subitem.sub_account.length > 0 ? (
                              <React.Fragment key={subitem.account_chart_code}>
                                <tr
                                  id="tbl"
                                  style={{
                                    fontSize: "18px",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                    borderTop: "1px solid black",
                                    borderBottom: "1px solid black",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "black",
                                      paddingLeft: "30px",
                                      fontWeight: "bold",
                                      whiteSpace: "normal",
                                      paddingTop: "1px",
                                      paddingBottom: "1px",
                                    }}
                                    className="text-center"
                                  >
                                    {subitem.account_chart_code}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      paddingLeft: "30px",
                                      fontWeight: "bold",
                                      whiteSpace: "normal",
                                      paddingTop: "1px",
                                      paddingBottom: "1px",
                                    }}
                                    className="text-start"
                                  >
                                    {subitem.account_chart}
                                  </td>

                                  <td
                                    style={{ color: "black" }}
                                    className="text-end"
                                  ></td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text-end"
                                  ></td>
                                </tr>
                                {subitem.sub_account.length > 0
                                  ? subitem.sub_account.map((last) => (
                                      <React.Fragment
                                        key={last.sub_account_code}
                                      >
                                        <tr
                                          id="tbl"
                                          style={{
                                            fontSize: "16px",
                                            whiteSpace: "normal",
                                            padding: "1px",
                                          }}
                                        >
                                          <td
                                            style={{
                                              color: "black",
                                              paddingLeft: "60px",
                                              whiteSpace: "normal",
                                              paddingTop: "1px",
                                              paddingBottom: "1px",
                                            }}
                                            className="text-end"
                                          >
                                            {last.sub_account_code}
                                          </td>
                                          <td
                                            style={{
                                              color: "black",
                                              paddingLeft: "60px",
                                              whiteSpace: "normal",
                                              paddingTop: "1px",
                                              paddingBottom: "1px",
                                            }}
                                            className="text-start"
                                          >
                                            {last.sub_account}
                                          </td>

                                          <td
                                            style={{
                                              color: "black",
                                              whiteSpace: "normal",
                                              paddingTop: "1px",
                                              paddingBottom: "1px",
                                            }}
                                            className="text-center"
                                          >
                                            {Intl.NumberFormat("en-Us").format(
                                              last.debit
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              color: "black",
                                              whiteSpace: "normal",
                                              paddingTop: "1px",
                                              paddingBottom: "1px",
                                            }}
                                            className="text-center"
                                          >
                                            {Intl.NumberFormat("en-Us").format(
                                              last.credit
                                            )}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))
                                  : null}
                                <tr
                                  id="tbl"
                                  style={{
                                    fontSize: "16px",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                    borderTop: "1px solid black",
                                    borderBottom: "1px solid black",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "black",
                                      paddingLeft: "30px",
                                      whiteSpace: "normal",
                                      paddingTop: "1px",
                                      paddingBottom: "1px",
                                    }}
                                    className="text-start"
                                  ></td>
                                  <td
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      whiteSpace: "normal",
                                      paddingTop: "1px",
                                      paddingBottom: "1px",
                                    }}
                                    className="text-end pr-10"
                                  >
                                    Control Accounts Total :-
                                  </td>

                                  <td
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      borderTop: "2px solid black",
                                      borderBottom: "2px solid black",
                                      whiteSpace: "normal",
                                      paddingTop: "1px",
                                      paddingBottom: "1px",
                                    }}
                                    className="text-center"
                                  >
                                    {Intl.NumberFormat("en-Us").format(
                                      subitem?.account_chart_debit
                                    )}
                                  </td>

                                  <td
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      borderTop: "2px solid black",
                                      borderBottom: "2px solid black",
                                      whiteSpace: "normal",
                                      paddingTop: "1px",
                                      paddingBottom: "1px",
                                    }}
                                    className="text-center"
                                  >
                                    {Intl.NumberFormat("en-Us").format(
                                      subitem?.account_chart_credit
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ) : null
                          )}
                        </React.Fragment>
                      ) : null
                    )}
                  </React.Fragment>
                ) : null
              )}

              <tr
                id="tbl"
                style={{
                  fontSize: "20px",
                  whiteSpace: "normal",
                  padding: "1px",
                }}
              >
                <td
                  style={{ color: "black", paddingLeft: "30px" }}
                  className="text-start"
                ></td>
                <td
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "50px",
                    fontWeight: "bold",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-end text-primary"
                >
                  {lossDebited != 0 ? "Loss Debited :-" : "Profit Credited :-"}
                </td>

                <td
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-center text-primary"
                >
                  {lossDebited != 0
                    ? Intl.NumberFormat("en-Us").format(lossDebited)
                    : 0}
                </td>

                <td
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-center text-primary"
                >
                  {profitCredited != 0
                    ? Intl.NumberFormat("en-Us").format(profitCredited)
                    : 0}
                </td>
              </tr>

              <tr
                id="tbl"
                style={{
                  fontSize: "20px",
                  whiteSpace: "normal",
                  padding: "1px",
                }}
              >
                <td
                  style={{ color: "black", paddingLeft: "30px" }}
                  className="text-start"
                ></td>
                <td
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "50px",
                    fontWeight: "bold",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-end text-primary"
                >
                  Grand Total :-
                </td>

                <td
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-center text-primary"
                >
                  {Intl.NumberFormat("en-Us").format(debitSum)}
                </td>

                <td
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-center text-primary"
                >
                  {Intl.NumberFormat("en-Us").format(creditSum)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <Modal show={viewModal} centered size="lg">
        <Modal.Header>{selectedItem.sub_account}</Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th className="text-start">Date</th>
                <th className="text-start">Description</th>
                <th className="text-end">Debit</th>
                <th className="text-end">Credit</th>
              </tr>
            </thead>

            <tbody>
              {selectedItem?.general_ledgers?.map((item) => (
                <tr id="tbl">
                  <td style={{ color: "black" }} className="text-start">
                    {item.transaction_at}
                  </td>
                  <td style={{ color: "black" }} className="text-start">
                    {item.remarks}
                  </td>

                  <td
                    style={{ color: "black", fontWeight: "bold" }}
                    className="text-end text-primary"
                  >
                    {item.debit}
                  </td>

                  <td
                    style={{ color: "black", fontWeight: "bold" }}
                    className="text-end text-primary"
                  >
                    {item.credit}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => setViewModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TrialBalance;
