/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import {
  MdSpaceDashboard,
  MdMapsHomeWork,
  MdManageAccounts,
  MdSupportAgent,
  MdSchool,
} from "react-icons/md";
import {
  FaUsersRectangle,
  FaUsersGear,
  FaUserTie,
  FaLink,
} from "react-icons/fa6";
import { IoCarSport } from "react-icons/io5";
import { IoIosPaper } from "react-icons/io";
import { PiExam, PiStudentFill } from "react-icons/pi";
import { TbReport } from "react-icons/tb";
import { FaUserGraduate } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi2";
import { SiElectronbuilder } from "react-icons/si";
import { PremissionChecker } from "../../../../../services/PremissionChecker";
import { baseUrl } from "../../../../../services/config";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  // let [user, setuser] = useState();

  // let user;
  // if (localStorage.getItem("userDetails")) {
  //   user = JSON.parse(localStorage.getItem("userDetails"));
  // }

  const [branchUrl, setBranchUrl] = useState("");
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);

  // console.log("ReduxUser", ReduxUser)

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBranchUrl(data.payload.settings.head_office_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <MdSpaceDashboard color="white" />
            </span>
            <span className="menu-text white_color">Dashboard</span>
          </NavLink>
        </li> */}

        {PremissionChecker(user, "side_bar.system_dashboard") && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/systemdashboard",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to="/systemdashboard">
              {/* <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i> */}
              <span className="svg-icon menu-icon">
                <MdSpaceDashboard color="white" />
              </span>
              <span className="menu-text white_color">System Dashboard</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        <>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/settings",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/settings">
              <span className="svg-icon menu-icon ">
                <SVG
                  className="checkmenue"
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text white_color">Settings</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">Settings</span>
                  </span>
                </li>

                {PremissionChecker(user, "side_bar.users") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/users",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/users">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Users</span>
                    </NavLink>
                  </li>
                )}
                {PremissionChecker(user, "side_bar.roles") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/roles",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/roles">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Role and Permissions
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.sessions") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/sessions",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/sessions">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Sessions</span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.unit_settings") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/unit-settings",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/unit-settings">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Unit Settings
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.change_password") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/changepassword",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/changepassword">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Change Password
                      </span>
                    </NavLink>
                  </li>
                )}

                {/*start Manage Defination */}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon">
                      {/* <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Home/Bed.svg")}
                        /> */}
                      <FaLink color="white" />
                    </span>
                    <span className="menu-text white_color">
                      Manage Definition
                    </span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text white_color">
                            Manage Definition
                          </span>
                        </span>
                      </li>

                      {/* Inputs */}
                      {/*begin::2 Level*/}

                      {PremissionChecker(user, "side_bar.define_classes") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/define-classes",
                            false
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/define-classes">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span
                              style={{ color: "white" }}
                              className="menu-text white_color"
                            >
                              Define Classes
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.define_sections") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/define-sections",
                            false
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/define-sections">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span
                              style={{ color: "white" }}
                              className="menu-text white_color"
                            >
                              Define Sections
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.define_subjects") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/define-subjects",
                            false
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/define-subjects">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span
                              style={{ color: "white" }}
                              className="menu-text white_color"
                            >
                              Define Subjects
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.define_grade_system"
                      ) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/define-grade-system",
                            false
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/define-grade-system"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span
                              style={{ color: "white" }}
                              className="menu-text white_color"
                            >
                              Define Grade System
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.define_exam") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/define-exam",
                            false
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/define-exam">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span
                              style={{ color: "white" }}
                              className="menu-text white_color"
                            >
                              Define Exam
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
                {/*end Manage Definations */}
              </ul>
            </div>
          </li>

          {/* <li
            className={`menu-item ${getMenuItemActive("/vehicles", false)}`}
            aria-haspopup="true"
          ></li> */}

          {PremissionChecker(user, "side_bar.vehicles") && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/vehicle/addvehicle",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/vehicle/addvehicle">
                <span className="svg-icon menu-icon">
                  {/* <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                  /> */}
                  <IoCarSport color="white" />
                </span>
                <span
                  style={{ color: "white" }}
                  className="menu-text white_color"
                >
                  Vehicle's
                </span>
              </NavLink>
            </li>
          )}

          {PremissionChecker(user, "side_bar.hostels") && (
            <li
              className={`menu-item ${getMenuItemActive("/hostel", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/hostel">
                <span className="svg-icon menu-icon">
                  {/* <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}
                  /> */}
                  <MdMapsHomeWork color="white" />
                </span>
                <span
                  style={{ color: "white" }}
                  className="menu-text white_color"
                >
                  Hostel's
                </span>
              </NavLink>
            </li>
          )}

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon">
                <MdMapsHomeWork color="white" />
              </span>
              <span className="menu-text white_color">Branches</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">Branches</span>
                  </span>
                </li>

                {/* Inputs */}
                {/*begin::2 Level*/}

                {PremissionChecker(user, "side_bar.branches_url") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/branches-url",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/branches-url">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Branches List
                      </span>
                    </NavLink>
                  </li>
                )}

                {branchUrl &&
                  PremissionChecker(user, "side_bar.branch_notification") && (
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/branch-notification",
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink className="menu-link" to="/branch-notification">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text white_color">
                          Branches Notification
                        </span>
                      </NavLink>
                    </li>
                  )}

                {branchUrl &&
                  PremissionChecker(user, "side_bar.branch_resources") && (
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/branch-resources",
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink className="menu-link" to="/branch-resources">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text white_color">
                          Branches Resources
                        </span>
                      </NavLink>
                    </li>
                  )}
              </ul>
            </div>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                />
              </span>
              <span className="menu-text white_color">Campus Management</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">
                      Campus Management
                    </span>
                  </span>
                </li>

                {/* Inputs */}
                {/*begin::2 Level*/}

                {PremissionChecker(user, "side_bar.campus_list") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/campus",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/campus">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Campuses List
                      </span>
                    </NavLink>
                  </li>
                )}
                {PremissionChecker(user, "side_bar.create_campus") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/addcampus",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/addcampus">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Create Campus
                      </span>
                    </NavLink>
                  </li>
                )}

                {/*start Manage Class Section */}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon">
                      {/* <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Home/Bed.svg")}
                        /> */}
                      <FaUsersRectangle color="white" />
                    </span>
                    <span className="menu-text white_color">
                      Manage Class / Section
                    </span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text white_color">
                            Manage Class / Section
                          </span>
                        </span>
                      </li>

                      {/* Inputs */}
                      {/*begin::2 Level*/}
                      {PremissionChecker(user, "side_bar.add_remove_class") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/campasclasses",
                            false
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/campasclasses">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span
                              style={{ color: "white" }}
                              className="menu-text white_color"
                            >
                              Add / Remove Class
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(
                        user,
                        "side_bar.add_remove_section"
                      ) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/sections",
                            false
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/sections">
                            {/* <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                        />
                      </span> */}
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span
                              style={{ color: "white" }}
                              className="menu-text white_color"
                            >
                              Add / Remove Section
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
                {/*end Manage Class Section */}
              </ul>
            </div>
          </li>

          {/*start Fee Management */}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon">
                <IoIosPaper color="white" />
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} /> */}
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} /> */}
              </span>
              <span className="menu-text white_color">Fee Management</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">
                      Fee Management
                    </span>
                  </span>
                </li>

                {/* Inputs */}
                {/*begin::2 Level*/}
                {/* <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/inputs",
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        className="menu-link"
                        to="/generatecustomchallan"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text white_color">Generate Custom Challan</span>

                      </NavLink>

                    </li> */}
                {PremissionChecker(user, "side_bar.campus_fee_challan") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/campusfeechallan",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/campusfeechallan">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Campus Fee Challan
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.receive_partial_fee") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/receivepartialfee",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/receivepartialfee">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Receive Partial Fee
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.roll_back") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/RollBack",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/RollBack">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Roll Back Fee
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.receive_fee") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/campusfeerecive",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/campusfeerecive">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Receive Fee</span>
                    </NavLink>
                  </li>
                )}
                {PremissionChecker(user, "side_bar.print_fee_challan") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/student-wise-print",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/student-wise-print">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Print Fee Challan
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(
                  user,
                  "side_bar.print_fee_challan_month_wise"
                ) && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/single-student",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/single-student">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Print Fee Challan - Month Wise
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.generate_fee") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/genratesectionwisefee",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/genratesectionwisefee">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Generate Fee
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(
                  user,
                  "side_bar.genrate_student_wise_fee"
                ) && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/genratestudentwisefee",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/genratestudentwisefee">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Generate Fee - With Discount
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.refund_fee") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/feereturn",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    {/* <NavLink className="menu-link" to="/concession">
                        <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text white_color">Concession</span>
                      </NavLink> */}
                    <NavLink className="menu-link" to="/feereturn">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Refund Fee</span>
                    </NavLink>
                  </li>
                )}
                {PremissionChecker(user, "side_bar.add_update_fee") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/definecampusfee",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/definecampusfee">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Add / Update Fee
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.define_fee_types") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/definefeetypes",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/definefeetypes">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Define Fee Types
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.concession") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/concession",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    {/* <NavLink className="menu-link" to="/concession">
                        <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text white_color">Concession</span>
                      </NavLink> */}
                    <NavLink className="menu-link" to="/concession">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Concession</span>
                    </NavLink>
                  </li>
                )}

                {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material/inputs",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/campusclasschallan">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Campus Class Challan
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material/inputs",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/sectionfeechallan">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Section Wise Challan
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material/inputs",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/challansearch">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Search Challan
                      </span>
                    </NavLink>
                  </li> */}
              </ul>
            </div>
          </li>
          {/*end Fee Management */}

          {/* <li
              className={`menu-item ${getMenuItemActive("/students", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/students">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                </span>
                <span className="menu-text">Students</span>
              </NavLink>
            </li> */}

          {/* <li
              className={`menu-item ${getMenuItemActive("/headoffice", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/headoffice">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                  />
                </span>
                <span
                  style={{ color: "white" }}
                  className="menu-text white_color"
                >
                  Head Office Admin
                </span>
              </NavLink>
            </li> */}

          {/* <li
              className={`menu-item ${getMenuItemActive("/vehicles", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/vehicles">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                </span>
                <span className="menu-text">Vehicle's</span>
              </NavLink>
            </li> */}

          {/* <li
              className={`menu-item ${getMenuItemActive("/sessions", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/sessions">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Weather/Cloud-sun.svg")}
                  />
                </span>
                <span
                  style={{ color: "white" }}
                  className="menu-text white_color"
                >
                 Sessions
                </span>
              </NavLink>
            </li> */}

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon">
                {/* <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  /> */}
                <PiStudentFill color="white" />
              </span>
              <span className="menu-text white_color">Student Management</span>
              <i className="menu-arrow" />
            </NavLink>

            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">
                      Student Management
                    </span>
                  </span>
                </li>

                {/* Inputs */}
                {/*begin::2 Level*/}
                {PremissionChecker(user, "side_bar.search_student") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/findstudent",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/findstudent">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Find Student
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.active_students") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/showallstudents",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/showallstudents">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        {" "}
                        Active Students
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.other_students") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/student",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/student">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Other Students
                      </span>
                    </NavLink>
                  </li>
                )}
                {PremissionChecker(user, "side_bar.registered_students") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/onlyregisteredstudents",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/onlyregisteredstudents">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Registered Students
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.admit_student") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/addnewstudent",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/addnewstudent">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Admit Student
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.promote_students") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/studentpromote",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/studentpromote">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Promote Students
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(
                  user,
                  "side_bar.student_subjects_assignment"
                ) && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/assignsubject",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/assignsubject">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Students Subjects Assignment
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(
                  user,
                  "side_bar.add_edit_student_picture"
                ) && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/pictures",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/pictures">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Add/Edit Student Pictures
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.admission_card") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/cardissuestudents",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/cardissuestudents">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Admission Card{" "}
                      </span>
                    </NavLink>
                  </li>
                )}

                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon ">
                      <SVG
                        className="checkmenue"
                        src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}
                      />
                    </span>
                    <span className="menu-text white_color">Hostel</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text white_color">Hostel</span>
                        </span>
                      </li>

                      {PremissionChecker(user, "side_bar.hostel_students") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/hostel-students",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/hostel-students">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Hostel Students
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.hostel_admission") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/hostel-admit",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/hostel-admit">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Hostel Admission
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>

                {/* <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/inputs",
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        className="menu-link"
                        to="/definecampusfee"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Define Fee</span>

                      </NavLink>

                    </li> */}
                {/* <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/inputs",
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        className="menu-link"
                        to="/genratefee"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Genrate Fee</span>

                      </NavLink>

                    </li> */}
                {/* <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/inputs",
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        className="menu-link"
                        to="/findstudent"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text white_color">Find Student</span>

                      </NavLink>

                    </li> */}
              </ul>
            </div>
          </li>

          {/* start staff management */}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon">
                {/* <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Address-card.svg"
                    )}
                  /> */}
                <FaUsersGear color="white" />
              </span>
              <span className="menu-text white_color">Staff Management</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">
                      Staff Management
                    </span>
                  </span>
                </li>

                {PremissionChecker(user, "side_bar.search_staff") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/searchstaff",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/searchstaff">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Find Staff</span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.registered_staff") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/showstaff",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/showstaff">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Registered Staff
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.add_staff") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/registerstaff",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/registerstaff">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Add Staff</span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.pay_scale_handling") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/payscale",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/payscale">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Pay-scale Handling
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.active_staff") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/appointedstaff",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/appointedstaff">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Active Staff
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.generate_salary") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/bulkgeneratesalary",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/bulkgeneratesalary">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Generate Salary
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(
                  user,
                  "side_bar.updated_generated_salary"
                ) && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/filtersalary",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/filtersalary">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Update Generated Salary
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.pay_generated_salary") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/paysalary",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/paysalary">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Pay Generated Salary
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.pay_staff_gp_fund") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/paystaffgpfund",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/paystaffgpfund">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Pay GP Fund</span>
                    </NavLink>
                  </li>
                )}

                {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/allretiredstaff",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/allretiredstaff">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Not in Service</span>
                    </NavLink>
                  </li> */}
              </ul>
            </div>
          </li>
          {/* end staff management */}

          {/*start account management */}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon">
                {/* <SVG
                    className="checkmenue"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Attachment1.svg"
                    )}
                  /> */}
                <MdManageAccounts color="white" />
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} /> */}
              </span>
              <span className="menu-text white_color">Accounts Management</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">Fee Types</span>
                  </span>
                </li>

                {/* Inputs */}
                {/*begin::2 Level*/}
                {PremissionChecker(user, "side_bar.voucher_handling") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/account_vocher",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/account_vocher">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Voucher Handling
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.chart_of_accounts") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/chart_of_account",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/chart_of_account">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Chart of Accounts
                      </span>
                    </NavLink>
                  </li>
                )}
                {PremissionChecker(user, "side_bar.day_end_voucher") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/dnvoucher",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/dnvoucher">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Day-End Voucher
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.verifydn") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/verifydn",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/verifydn">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Verify Day-End
                      </span>
                    </NavLink>
                  </li>
                )}
                {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material/inputs",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/dayendvoucher">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Day End Voucher
                      </span>
                    </NavLink>
                  </li> */}
              </ul>
            </div>
          </li>
          {/*end account management */}

          {/* start bank management */}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon ">
                <SVG
                  className="checkmenue"
                  src={toAbsoluteUrl("/media/svg/icons/Home/bank-building.svg")}
                />
              </span>
              <span className="menu-text white_color">Banks Management</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">
                      Banks Management
                    </span>
                  </span>
                </li>

                {/* Inputs */}
                {/*begin::2 Level*/}
                {PremissionChecker(user, "side_bar.banks_list") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/bank",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/bank">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Banks List</span>
                    </NavLink>
                  </li>
                )}
                {PremissionChecker(user, "side_bar.add_bank") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/addbank",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/addbank">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Add Bank</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
          {/* end bank management */}

          {/* start system report */}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon">
                {/* <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Address-card.svg"
                    )}
                  /> */}
                <TbReport color="white" />
              </span>
              <span className="menu-text white_color">System Reports</span>
              <i className="menu-arrow" />
            </NavLink>

            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">
                      System Reports
                    </span>
                  </span>
                </li>

                {/*Certificates*/}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Address-card.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text white_color">Certificates</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {PremissionChecker(user, "side_bar.hope_certificate") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/hopeCertificate",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/hopeCertificate">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Hope Certificate
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>

                    <ul className="menu-subnav">
                      {PremissionChecker(
                        user,
                        "side_bar.staff_certificate"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/staffCertificate",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/staffCertificate">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff Certificate
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                    <ul className="menu-subnav">
                      {PremissionChecker(
                        user,
                        "side_bar.experience_certificate"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/ExperienceCertificate",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/ExperienceCertificate"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Experience Certificate
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                    <ul className="menu-subnav">
                      {PremissionChecker(
                        user,
                        "side_bar.school_leaving_certificate"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/SchoolLeavingCertificate",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/SchoolLeavingCertificate"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              School Leaving Certificate
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>

                    <ul className="menu-subnav">
                      {PremissionChecker(
                        user,
                        "side_bar.migration_certificate"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/MigrationCertificate",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/MigrationCertificate"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Migration Certificate
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                    <ul className="menu-subnav">
                      {PremissionChecker(
                        user,
                        "side_bar.certificate_report"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/CertificateReport",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/CertificateReport"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Certificate Report
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>

                {/* Link submenu 1*/}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon">
                      {/* <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Address-card.svg"
                          )}
                        /> */}
                      <FaUserGraduate color="white" />
                    </span>
                    <span className="menu-text white_color">
                      Students Reports
                    </span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {/* <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/printfeebill",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/printfeebill">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Print Fee bill
                            </span>
                          </NavLink>
                        </li> */}
                      {PremissionChecker(user, "side_bar.due_fee_report") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/due-fee",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/due-fee">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Due Fee Report
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {/* <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/paid-unpaid",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/paid-unpaid">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Paid or Unpaid Students
                            </span>
                          </NavLink>
                        </li> */}
                      {PremissionChecker(
                        user,
                        "side_bar.student_fee_details"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/fee-details",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/fee-details">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Student Fee Details
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.student_check_list"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/check-list",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/check-list">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Student Check List
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.student_check_list_date_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/new-admission-list",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/new-admission-list"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Student Status List - Date Wise
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.student_check_list_overall"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/not-active-student",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/not-active-student"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              {/* Not Active Students{" "} */}
                              Student Status List – Overall{" "}
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.student_figure_date_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/student-figure-date-wise",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/student-figure-date-wise"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Student Figure Date Wise
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.student_strength") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/student-strength",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/student-strength">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Student Strength
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.student_total_admissions"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/student-total-admission",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/student-total-admission"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Student Total Admissions
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.student_list_11_column"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/columnList",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/columnList">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Student List - 11 Column
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.hostel_student_list"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/hostelstudentreport",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/hostelstudentreport"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Hostel Student List
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.fee_concessions_report"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/student-concession-fee",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/student-concession-fee"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Fee Concessions Report
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.no_generated_fee_month_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/not-generated-fee-month-wise",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/not-generated-fee-month-wise"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Not Generated Fee - Month Wise
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.fee_received_summary"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/fee-summary",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/fee-summary">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Fee Received Summary
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.fee_received_category_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/fee-recieved-month",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/fee-recieved-month"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Fee Received - Category Wise
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.student_missing_info"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/StudentMissingInfo",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/StudentMissingInfo"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Missing Information List
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.student_all_info") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/StudentAllInfo",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/StudentAllInfo">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              All Information List
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.advanced_fee_receive"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/advancedFeeReceive",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/AdvancedFeeReceive"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Advanced Fee Receive Report
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>

                {/* Link submenu end  1*/}

                {/* Link submenu 2 */}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon">
                      {/* <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Address-card.svg"
                          )}
                        /> */}
                      <FaUserTie color="white" />
                    </span>
                    <span className="menu-text white_color">Staff Reports</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/registerstafflistreport",
                          true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                      >
                        {PremissionChecker(
                          user,
                          "side_bar.registered_staff"
                        ) && (
                          <NavLink
                            className="menu-link"
                            to="/registerstafflistreport"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Registered Staff
                            </span>
                          </NavLink>
                        )}
                      </li>
                      {PremissionChecker(user, "side_bar.appointed_staff") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/appointedstafflistreport",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/appointedstafflistreport"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Appointed Staff
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.staff_list_with_bank_details"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/listwithbankdata",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/listwithbankdata">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff List with Bank Details
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.staff_list_category_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/stafflistoverall",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/stafflistoverall">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff List - Category Wise
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.staff_list_status_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/staff-list-status-wise",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/staff-list-status-wise"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff List - Status Wise{" "}
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.demand_pay_sheet") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/demandpaysheet",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/demandpaysheet">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Demand Pay Sheet
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(user, "side_bar.staff_loans") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/staff-loan",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/staff-loan">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff Loans
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(
                        user,
                        "side_bar.staff_pay_details"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/staff-pay",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/staff-pay">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff Pay Details
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(user, "side_bar.staff_figures") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/total-staff",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/total-staff">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff Figures
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.staff_gp_fund") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/gp-fund",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/gp-fund">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff GP Fund Details
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.staff_gp_fund_details"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/gp-fund",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/gp-fund">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff GP Fund Details
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.staff_eobi_details"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/eobi",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/eobi">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Staff EOBI Details
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(
                        user,
                        "side_bar.print_salary_slip"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/salary-slip",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/salary-slip">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Print Salary Slip
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.bank_pay_sheet") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/bank-pay-sheet",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/bank-pay-sheet">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Bank Pay Sheet
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(
                        user,
                        "side_bar.receiving_pay_sheet"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/receiving-pay-sheet",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/receiving-pay-sheet"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Receiving Pay Sheet
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.gross_salary_sheet"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/gross-salary-sheet",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/gross-salary-sheet"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Gross Salary Sheet
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.monthly_pay_sheet"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/monthly-pay-sheet",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/monthly-pay-sheet"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Monthly Pay Sheet
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.monthly_pay_summary"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/monthly-pay-summary",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/monthly-pay-summary"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Monthly Pay Summary
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>

                {/* Link submenu end 2 */}

                {/* Link submenu 3*/}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon">
                      {/* <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Address-card.svg"
                          )}
                        /> */}
                      <HiUserGroup color="white" />
                    </span>
                    <span className="menu-text white_color">
                      Account Reports
                    </span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {PremissionChecker(
                        user,
                        "side_bar.daily_scroll_bank_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/dailyscroll",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/dailyscroll">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Daily Scroll – Bank wise
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.daily_scroll_bank_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/monthlybankscroll",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/monthlybankscroll"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Monthly Bank Scroll
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {/* <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/bankscroll",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/bankscroll">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Bank Scroll
                            </span>
                          </NavLink>
                        </li> */}
                      {/* <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/test",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/test">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              {`Print Voucher Listing Date Wise`}
                            </span>
                          </NavLink>
                        </li> */}
                      {PremissionChecker(user, "side_bar.account_ledger") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/account-ledger",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/account-ledger">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Account Ledger
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {/* <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/scrollFee",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/scrollFee">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Daily Scroll feed
                            </span>
                          </NavLink>
                        </li> */}
                      {PremissionChecker(
                        user,
                        "side_bar.transactions_report"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/daily-transactions",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/daily-transactions"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Transactions Report
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(
                        user,
                        "side_bar.profit_and_loss_report"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/profit-loss",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/profit-loss">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Profit And Loss Report
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(user, "side_bar.trial_balance") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/trial-balance",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/trial-balance">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Trial Balance
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.year_end") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/YearEnd",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/YearEnd">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Year End Voucher
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.firm_wise_report") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/FirmWiseReport",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/FirmWiseReport">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Firm Wise Report
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.income_and_expenditure_report"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/income-expenditure",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/income-expenditure"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Income And Expenditure Report
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.comparative_expense_report"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/comparative-expense",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/comparative-expense"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Comparative Expense Report
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(user, "side_bar.balance_sheet") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/balance-sheet",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/balance-sheet">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Balance Sheet
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.cash_and_bnk_balances"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/cash-bank",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/cash-bank">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Cash And Bank Balances
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.monthly_expense_date_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/date-wise-expense",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/date-wise-expense"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Monthly Expense – Date Wise
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.project_monthly_income"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/project-monthly-income",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/project-monthly-income"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Projected Monthly Income
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {/* <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/google-material/inputs",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/hostelstudentreport">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Hostel Student Report
                            </span>
                          </NavLink>
                        </li> */}
                    </ul>
                  </div>
                </li>

                {/* Link submenu end  3*/}
                {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/balancesheet",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/balancesheet">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Balance Sheet
                      </span>
                    </NavLink>
                  </li> */}
                {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/salaryreport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/salaryreport">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Salary Report
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/feesreport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/feesreport">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Fee Report</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/expensesreport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/expensesreport">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Expenses Report
                      </span>
                    </NavLink>
                  </li> */}
              </ul>
            </div>
          </li>
          {/* end system report */}

          {/* start Academics & Mobile App */}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon">
                {/* <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Address-card.svg"
                    )}
                  /> */}
                <SiElectronbuilder color="white" />
              </span>
              <span className="menu-text white_color">
                Academics & Mobile App
              </span>
              <i className="menu-arrow" />
            </NavLink>

            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">
                      Academics & Mobile App
                    </span>
                  </span>
                </li>

                {/* Student Attendec start */}

                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon ">
                      <SVG
                        className="checkmenue"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Home/bank-building.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text white_color">
                      Student Attendance
                    </span>
                    <i className="menu-arrow" />
                  </NavLink>

                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text white_color">
                            Student Attendance
                          </span>
                        </span>
                      </li>

                      {/* Inputs */}
                      {/*begin::2 Level*/}
                      {PremissionChecker(
                        user,
                        "side_bar.mark_attendance_or_discipline"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/markattendance",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/markattendance">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Mark Attendance / Discipline
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(
                        user,
                        "side_bar.print_attendance_section_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/print-attendance-section-wise",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/print-attendance-section-wise"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Print Attendance List – Section Wise
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.attendance_summary"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/attendance-summary",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/attendance-summary"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Attendance Summary
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
                {/* Student Attendec end */}

                {/*start Exams & Results  */}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon ">
                      <SVG
                        className="checkmenue"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Home/bank-building.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text white_color">
                      Exams & Results
                    </span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text white_color">
                            Exams & Results
                          </span>
                        </span>
                      </li>

                      {/* Inputs */}
                      {/*begin::2 Level*/}

                      {PremissionChecker(user, "side_bar.add_show_exam") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/examlist",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/examlist">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Add / Show Exams
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.update_exam") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/update-exams",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/update-exams">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Update Exam
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.date_sheet_list") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/datesheet",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/datesheet">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Date Sheet List
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(user, "side_bar.enter_results") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/result",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/result">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Enter Results
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.result_list_section_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/studentwiseresult",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/studentwiseresult"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Result List - Section Wise
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {PremissionChecker(
                        user,
                        "side_bar.print_result_admission_wise"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/singlestudentresult",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/singlestudentresult"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Print Result - Admission Wise
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(
                        user,
                        "side_bar.result_card_sequences"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/result-sequence",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/result-sequence">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Result Card Sequence
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
                {/*end Exams & Results  */}

                {/*start Test Management Systen  */}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon ">
                      <PiExam style={{ color: "white" }} />
                    </span>
                    <span className="menu-text white_color">
                      Daily / Phase Test Management
                    </span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text white_color">
                            Daily / Phase Test Management
                          </span>
                        </span>
                      </li>

                      {/* Inputs */}
                      {/*begin::2 Level*/}

                      {PremissionChecker(
                        user,
                        "side_bar.daily_test_system"
                      ) && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/daily-test-system",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/daily-test-system"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Daily Test System
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.enter_test") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/enter-test",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/enter-test">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Enter Test
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
                {/*end Exams & Results  */}

                {/* Start HomeWork */}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material"
                  >
                    <span className="svg-icon menu-icon ">
                      <MdMapsHomeWork color="white" />
                    </span>
                    <span className="menu-text white_color">Home Work</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text white_color">
                            Home Work
                          </span>
                        </span>
                      </li>

                      {/* Inputs */}
                      {/*begin::2 Level*/}

                      {PremissionChecker(user, "side_bar.add_home_work") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/add-home-work",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/add-home-work">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Add Home Work
                            </span>
                          </NavLink>
                        </li>
                      )}

                      {PremissionChecker(user, "side_bar.update_home_work") && (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/update-home-work",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link" to="/update-home-work">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Update Home Work
                            </span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
                {/* End HomeWork */}

                {/* start Student Resources */}
                {PremissionChecker(user, "side_bar.student_resources") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/student-resources",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/student-resources">
                      <span className="svg-icon menu-icon">
                        <MdSchool color="white" />
                      </span>
                      <span
                        style={{ color: "white" }}
                        className="menu-text white_color"
                      >
                        Student Resources
                      </span>
                    </NavLink>
                  </li>
                )}
                {/* end Student Resources */}

                {/* start app support */}
                {PremissionChecker(user, "side_bar.app_support") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/support",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/support">
                      <span className="svg-icon menu-icon">
                        {/* <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                        /> */}
                        <MdSupportAgent color="white" />
                      </span>
                      <span
                        style={{ color: "white" }}
                        className="menu-text white_color"
                      >
                        App Support
                      </span>
                    </NavLink>
                  </li>
                )}
                {/* end app support */}

                {/* start app notification */}
                {PremissionChecker(user, "side_bar.app_notifications") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/notifications`,
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to={`/notifications`}>
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Notifications1.svg"
                          )}
                        />
                      </span>
                      <span
                        style={{ color: "white" }}
                        className="menu-text white_color"
                      >
                        App Notifications
                      </span>
                    </NavLink>
                  </li>
                )}
                {/* end app notification */}
              </ul>
            </div>
          </li>
          {/* end Academics & Mobile App */}

          {/* start SMS Management */}

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Address-card.svg"
                  )}
                />
              </span>
              <span className="menu-text white_color">SMS Management </span>
              <i className="menu-arrow" />
            </NavLink>

            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text white_color">
                      SMS Management
                    </span>
                  </span>
                </li>

                {PremissionChecker(user, "side_bar.sms_log_report") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/expensesreport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/log-report">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        SMS Log Report
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.send_message_student") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/expensesreport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/student-messaging">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Send Message – Student
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.send_message_staff") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/expensesreport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/staff-messaging">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Send Message – Staff
                      </span>
                    </NavLink>
                  </li>
                )}

                {PremissionChecker(user, "side_bar.message_allocation") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/message-allocation",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to={`/message-allocation`}>
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail.svg"
                          )}
                        />
                      </span>
                      <span
                        style={{ color: "white" }}
                        className="menu-text white_color"
                      >
                        Message Allocation
                      </span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>

          {PremissionChecker(user, "side_bar.action_report") && (
            // PremissionChecker(user, 'side_bar.vehicles') &&
            <li
              className={`menu-item ${getMenuItemActive(
                "/action-report",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/action-report">
                <span className="svg-icon menu-icon">
                  {/* <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                  /> */}
                  <FaUserGraduate color="white" />
                </span>
                <span
                  style={{ color: "white" }}
                  className="menu-text white_color"
                >
                  Action Report
                </span>
              </NavLink>
            </li>
          )}
          {/* end SMS Management */}

          {/* FEE receving link */}
          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/google-material">
                <span className="svg-icon menu-icon">
                  <SVG
                    className="checkmenue"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")}
                  />

                </span>
                <span className="menu-text white_color">Fee Receiving</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text white_color">
                        Fee Receiving
                      </span>
                    </span>
                  </li>

                 
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material/inputs",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/feereceive">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Receive Fee</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}

          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/google-material">

                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                  />
                </span>
                <span className="menu-text white_color">Fee Generate</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text white_color">
                        Fee Generate
                      </span>
                    </span>
                  </li> */}

          {/* 
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/google-material"
                    >
                      <span className="svg-icon menu-icon">
                        <SVG
                          className="checkmenue"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Thunder.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text white_color">Single Fee</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li
                          className="menu-item  menu-item-parent"
                          aria-haspopup="true"
                        >
                          <span className="menu-link">
                            <span className="menu-text white_color">
                              Single Student Fee
                            </span>
                          </span>
                        </li>

                      

                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/google-material/inputs",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        ></li>

                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/google-material/inputs",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/genratestudentwisefee"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Student Wise Fee
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li> */}

          {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/google-material"
                    >
                      <span className="svg-icon menu-icon">
                        <SVG
                          className="checkmenue"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Thunder-move.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text white_color">Bulk Fee</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li
                          className="menu-item  menu-item-parent"
                          aria-haspopup="true"
                        >
                          <span className="menu-link">
                            <span className="menu-text white_color">
                              Bulk Fee
                            </span>
                          </span>
                        </li>

                      

                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/google-material/inputs",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/genratecampuswisefee"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Campus Wise
                            </span>
                          </NavLink>
                        </li>
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/google-material/inputs",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/genrateclasswisefee"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Class Wise
                            </span>
                          </NavLink>
                        </li>

                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/google-material/inputs",
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to="/genratesectionwisefee"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text white_color">
                              Section Wise
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li> */}

          {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material/inputs",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link"
                      to="/genratesectionwisefee"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Section Wise
                      </span>
                    </NavLink>
                  </li> */}
          {/* 
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/genratesectionwisefee",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/genratesectionwisefee">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Generate Fee
                      </span>
                    </NavLink>
                  </li> */}

          {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material/inputs",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/findstudent">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Find Student
                      </span>
                    </NavLink>
                  </li> */}
          {/* </ul>
              </div>
            </li> */}

          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/google-material">
                <span className="svg-icon menu-icon">
                  <SVG
                    className="checkmenue"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")}
                  />
                </span>
                <span className="menu-text white_color">Fee Return</span>
                <i className="menu-arrow" />
              </NavLink>

              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text white_color">Fee Return</span>
                    </span>
                  </li>

             
                  <li className={`menu-item `} aria-haspopup="true">
                  
                    <NavLink className="menu-link" to="/feereturn">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Fee Return</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}
          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/google-material">
                <span className="svg-icon menu-icon">
                  <SVG
                    className="checkmenue"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")}
                  />
                </span>
                <span className="menu-text white_color">Concession's</span>
                <i className="menu-arrow" />
              </NavLink>

              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text white_color">Concession</span>
                    </span>
                  </li>

                
                  <li className={`menu-item `} aria-haspopup="true">
                   
                    <NavLink className="menu-link" to="/concession">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">Concession</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}

          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/google-material">
                <span className="svg-icon menu-icon">
                  <SVG
                    className="checkmenue"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Price1.svg")}
                  />
                  
                </span>
                <span className="menu-text white_color">Fee Types</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav"> */}
          {/* <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text white_color">Fee Types</span>
                    </span>
                  </li> */}

          {/* Inputs */}
          {/*begin::2 Level*/}
          {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/definefeetypes",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/definefeetypes">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Define fee types
                      </span>
                    </NavLink>
                  </li> */}

          {/* <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/inputs",
                        true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        className="menu-link"
                        to="/add_vocher_posting_account"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text white_color">Add Vocher Posting Account</span>

                      </NavLink>

                    </li> */}
          {/* </ul>
              </div>
            </li> */}
        </>

        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 style={{ color: "white" }} className="menu-text">
            {user.role[0] == "Campus" ? "Campus Details" : ""}
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}

        {/* Material-UI */}
        {/*begin::1 Level*/}

        {/* Head office conditional Links */}

        <>
          {/* start SMS Management */}
          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/google-material",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/google-material">
                <span className="svg-icon menu-icon">
 
                  <FaCommentSms color="white" />
                </span>
                <span className="menu-text white_color">SMS Management </span>
                <i className="menu-arrow" />
              </NavLink>



              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text white_color">SMS Management</span>
                    </span>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/expensesreport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/log-report">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        SMS Log Report
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/expensesreport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/student-messaging">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Send Message – Student
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/expensesreport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/staff-messaging">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text white_color">
                        Send Message – Staff
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(`/message-allocation`, false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to={`/message-allocation`}>
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
                        />
                      </span>
                      <span
                        style={{ color: "white" }}
                        className="menu-text white_color"
                      >
                        Message Allocation
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}
          {/* end SMS Management */}
        </>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
