import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert, Form } from "react-bootstrap";
import AllClampusStudent from "./showAllsectionStudents";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import Paper from "@material-ui/core/Paper";
import { DatePicker, Space } from "antd";

const { RangePicker } = DatePicker;

function GenrateSectionWiseFee() {
  //store data
  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );

  const alert = useAlert();
  // const [classess, setClassess] = useState([])
  const [allsessions, setallsessions] = useState([]);

  const [currentsession, setcurrentsession] = useState("");

  const [isFineEnable, setisFineEnable] = useState(false);

  const [feeloading, setfeeloading] = useState(false);
  const [allsections, setallsections] = useState([]);
  const [feemodal, setfeemodal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [classess, setClassess] = useState([]);
  const authtoken = localStorage.getItem("userToken");
  const [feetypes, setfeetypes] = useState([]);
  const [campus_id, setcampus_id] = useState("");
  const [fee_type_id, setfee_type_id] = useState("");
  const [student_class_id, setstudent_class_id] = useState("");
  const [due_date, setdue_date] = useState("");
  const [issue_date, setissue_date] = useState("");
  const [selectedcclassampus, setselectedcclassampus] = useState({});

  const [sectionid, setsectionid] = useState("");
  const [studentsForFeeGenerate, setstudentsForFeeGenerate] = useState([]);

  const currentyear = new Date().getFullYear();
  const [isallfields, setisallfields] = useState(false);
  const [datesformat, setdatesformat] = useState([]);
  const [section_id, setsection_id] = useState("");
  const [fine, setfine] = useState("");
  // let [amount, setamount] = useState([]);
  // let [fees_type_id, setfees_type_id] = useState([]);

  let [monthlyFeeInputs, setmonthlyFeeInputs] = useState([1]);
  let [monthlyFeeInputsvalue, setmonthlyFeeInputsvalue] = useState([]);
  let [isMonthlyFee, setisMonthlyFee] = useState(false);

  let [addFeeInputs, setaddFeeInputs] = useState([1]);
  let [isAddFee, setisAddFee] = useState(false);

  let [additiionalfeeinputs, setadditiionalfeeinputs] = useState([1]);
  let [addituionalFeeInputsvalue, setaddituionalFeeInputsvalue] = useState([]);
  let [isAdditionalFee, setisAdditionalFee] = useState(false);

  let [errorcreate, seterrorcreate] = useState("Please Fill All The Fields");
  const [showCampusName, setShowCampusName] = useState({});
  let [selectedcampus, setselectedcampus] = useState(0);
  let [selectedcampusclass, setselectedcampusclass] = useState({});

  const [fees_type_id, setfees_type_id] = useState([]);
  const [amount, setAmount] = useState([]);
  const [monthDate, setMonthDate] = useState([]);

  const addAdditionalInputfield = (e) => {
    e.preventDefault();

    let array = additiionalfeeinputs;
    array.push(1);
    setadditiionalfeeinputs([...array]);
  };

  const getclasssections = (campusid, classid, sectionid) => {
    fetch(`${baseUrl}/class_section/${campusid}/${classid}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("sections", data.payload);
        // setsele(data.payload)

        data.payload.map((item) => {
          if (item.id == sectionid) {
            setselectedcclassampus(item);
          }
        });
      })
      .catch((err) => {
        console.log("campuses", err);
      });
  };

  const addMonthlyInputfield = (e) => {
    e.preventDefault();
    console.log(e.target.id);
    if (e.target.id === "add_Monthly_Fee_button") {
      let array = monthlyFeeInputs;
      array.push(1);
      console.log(array);
      setmonthlyFeeInputs([...array]);
    }
  };

  const getRespectivefee = (id, index) => {
    console.log(id);

    let data = {
      campus_id: campus_id,
      fee_type_id: id,
    };

    console.log(data);
  };

  //fee challans
  const [allstudentschallan, setallstudentschallan] = useState([]);

  useEffect(() => {
    setissue_date(red_recevingdate);
    setdue_date(red_recevingdate);
  }, [red_recevingdate]);

  useEffect(() => {
    // getAllCampus();
    getAllSessions();
    //  getfeetypes()
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            setcurrentsession(item.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("campuses", data.payload);
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("campuses", err);
      });
  };

  const onchangeCampus = (campusid, classid, sectionid) => {
    const id = campusid;
    // setcampus_id(id)

    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setClassess(data.payload);
        console.log("class", data);
        data.payload.map((item) => {
          if (item.id == classid) {
            setselectedcampusclass(item);
          }
        });

        getclasssections(campusid, classid, sectionid);
      })
      .catch((err) => {
        console.log(err);
        console.log("vehicle", err);
      });

    //   getfeetypes(id);
  };

  const getfeetypes = (campusid, classid, sessionid) => {
    console.log(campusid, classid);
    let url = "";
    if (classid != "") {
      url = `${baseUrl}/classfeetypes/${campusid}/${classid}/${sessionid}`;
    } else {
      url = `${baseUrl}/campus/campus-fees/${campusid}/${sessionid}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data, "hahahah");

        if (data.metadata) {
          if (data.metadata.success) {
            let filtered_feetypes = [];
            let array = [];

            if (classid != "") {
              data.payload.map((item) => {
                if (!filtered_feetypes.includes(item.name)) {
                  if (item.name == "MONTHLY FEE") {
                  } else {
                    console.log(item.name);
                    filtered_feetypes.push(item.name);
                    array.push({ name: item.name, id: item.id });
                  }
                }
              });
            } else {
              data.payload.map((item) => {
                if (!filtered_feetypes.includes(item.fee_name)) {
                  if (item.fee_name == "MONTHLY FEE") {
                  } else {
                    console.log(item.fee_name);
                    filtered_feetypes.push(item.fee_name);
                    array.push({ name: item.fee_name, id: item.fee_type_id });
                  }
                }
              });
            }
            console.log("fee types ==>", array);
            setfeetypes([...array]);
          }
        }
      })
      .catch((err) => {
        // setmonthlyFeeInputs([1])
        // setmonthlyFeeInputsvalue([])
        // setisMonthlyFee(false)
        console.log(err);
      });

    // fetch(`${baseUrl}/feestypes`, {
    //     method: 'GET',
    //     headers: {
    //         'Authorization': `Bearer ${authtoken}`
    //     }
    // }).then((res) => res.json())
    //     .then((data) => {
    //         console.log("fee types ==>", data.payload)
    //         setfeetypes(data.payload)
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //     })
  };

  const cencelModal = () => {
    // setisMonthlyFee(false);
    // setisAdditionalFee(false);
    setisallfields(false);
    // setmonthlyFeeInputsvalue([]);
    // setmonthlyFeeInputs([1]);
    // setfees_type_id([]);
    // setamount([]);
    // setadditiionalfeeinputs([]);
    setfeeloading(false);
    seterrorcreate("Please Fill All The Fields");
  };

  //fee genrate

  const generateSectionFee = async () => {
    setfeeloading(true);

    let monthly_fee_status = isMonthlyFee ? 1 : 0;
    let additional_fee_status = isAdditionalFee ? 1 : 0;

    if (!isMonthlyFee) {
      if (!isAdditionalFee) {
        setisallfields(true);
        seterrorcreate("Please Add Monthly Fee or Additional Fee");
        setfeeloading(false);
        return;
      }
    }

    if (monthlyFeeInputsvalue.length == 0) {
      if (isMonthlyFee) {
        setisallfields(true);
        seterrorcreate("Please Add Monthly Fee");
        setfeeloading(false);
        return;
      }
    }

    if (isAdditionalFee) {
      if (
        fees_type_id.length != additiionalfeeinputs.length ||
        additiionalfeeinputs.length != amount.length
      ) {
        setisallfields(true);
        seterrorcreate("Please Add Some Additional Fee");
        setfeeloading(false);
        return;
      }
    }

    if (due_date.length == 0 || issue_date.length == 0) {
      setisallfields(true);
      seterrorcreate("Please Give Due And issue Dates");
      setfeeloading(false);
      return;
    }

    if (due_date.slice(0, 4) < "2020" || issue_date.slice(0, 4) < "2020") {
      setisallfields(true);
      seterrorcreate("Due Or issue Dates must be after 2020");
      setfeeloading(false);
      return;
    }

    setisallfields(false);
    const data = {
      monthly_fee_status,
      additional_fee_status,
      student_id: studentsForFeeGenerate,
      fee_month: monthlyFeeInputsvalue,
      additional_fees: constructAdditionalFeesArray(),
      due_date,
      issue_date,
      add_fine: isFineEnable ? 1 : 0,
    };

    console.log(data);

    await fetch(`${baseUrl}/all_fee_generate_with_std_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          const obj = data.payload;
          const newarr = Object.values(obj);
          console.log(newarr);
          setallstudentschallan(newarr);
          setfeeloading(false);
          if (data?.metadata?.success) {
            setfeemodal(false);
            setfeeloading(false);
            setisallfields(false);
            setisMonthlyFee(false);
            alert.success("Fee Generated Successfully");
          } else {
            setfeemodal(false);
            setfeeloading(false);
            alert.show(
              data?.metadata?.message
                ? data?.metadata?.message
                : "Something went wrong"
            );
          }
          cencelModal();
        })
      )
      .catch((err) => {
        setfeemodal(false);

        // setmonthlyFeeInputs([1])
        // setmonthlyFeeInputsvalue([])
        // setisMonthlyFee(false)
        alert.show(err);
      });
  };

  // console.log(allstudentschallan)

  const getStudentsForFeeGenerate = (
    students,
    campusid,
    classid,
    sectionid
  ) => {
    if (students.length != 0) {
      console.log(campusid, "yaha issue");
      getfeetypes(campusid, classid, currentsession);

      campuses.map((item) => {
        if (item.id == campusid) {
          setselectedcampus(item);
        }
      });

      setcampus_id(campusid);
      onchangeCampus(campusid, classid, sectionid);
      setstudent_class_id(classid);
      setsection_id(sectionid);
    }
    setstudentsForFeeGenerate(students);
  };

  // const changesession(e.target.value,currentsession)

  const changesession = (id) => {
    //  setcurrentsession(id)
  };

  const getCampusname = (name) => {
    console.log("child to perent", name);
    setShowCampusName(name);
  };

  const handleChanger = (day, date) => {
    let startDate = date[0] + "-01";
    let endDate = date[1] + "-01";
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    setmonthlyFeeInputsvalue(dates);
    //  console.log(dates)
  };

  // const additionalfeeChange = (e, index) => {
  //   console.log(e.target.value);
  //   if (e.target.value == "") {
  //     setisallfields(true);
  //     seterrorcreate("Please Add Additional fees");
  //   } else {
  //     setisallfields(false);
  //     let updatingarray = amount;
  //     updatingarray[index] = e.target.value;
  //     setamount([...updatingarray]);
  //   }
  // };

  const additionalfeeChange = (e, index) => {
    console.log(e.target.value);
    if (e.target.value == "") {
      setisallfields(true);
      seterrorcreate("Please Add Additional fees");
    } else {
      let newAmount = [...amount];
      newAmount[index] = e.target.value;
      setAmount(newAmount);
    }
  };

  const constructAdditionalFeesArray = () => {
    return fees_type_id.map((id, index) => ({
      fees_type_id: id,
      amount: amount[index],
      fee_month: monthDate[index] + "-01",
    }));
  };

  const getCurrentMonthYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
    return `${year}-${month}`;
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {studentsForFeeGenerate.length == 0 ? (
          <button
            onClick={() => alert.show("Please Select Some Students")}
            className="btn btn-success button_blue"
          >
            Add Fee
          </button>
        ) : (
          <button
            onClick={() => setfeemodal(true)}
            className="btn btn-success button_blue"
          >
            Add Fee
          </button>
        )}
        <br />
        <br />
        <AllClampusStudent
          getFeeType={getfeetypes}
          getCampusName={(campusname) => getCampusname(campusname)}
          getStudentsForFeeGenerate={getStudentsForFeeGenerate}
          getcurrentsession={setcurrentsession}
        />

        <Modal size="lg" show={feemodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Fee - {showCampusName?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />

            <form class="form-card">
              <div class="row justify-content-between text-left">
                <div class="form-group col-sm-12 flex-column d-flex pt-3">
                  <label class="form-control-label text-center font-size-h5">
                    <b>
                      Select Fee Types <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>

                  <div>
                    <div class="form-group flex-column d-flex">
                      <div class="flex-column d-flex">
                        <div class="switch ">
                          <label className="switchcheckboxlabel flex-row d-flex">
                            <input
                              onChange={() => {
                                setisMonthlyFee(!isMonthlyFee);
                                // setmonthlyFeeInputsvalue = [];
                                setmonthlyFeeInputs([1]);
                              }}
                              defaultChecked={isMonthlyFee}
                              type="checkbox"
                            />
                            <span class="lever"></span>
                            <h3 className="switchcheckboxtext">Monthly Fee</h3>
                          </label>
                        </div>

                        {isMonthlyFee ? (
                          <div>
                            <div className="row mt-5">
                              <div className="col-md-8 ">
                                <RangePicker
                                  getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                  }}
                                  style={{ height: "40px" }}
                                  picker="month"
                                  onChange={(day, date) =>
                                    handleChanger(day, date)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <div>
                        <div class="switch">
                          <label className="switchcheckboxlabel flex-row d-flex">
                            <input
                              onChange={() => {
                                setisAdditionalFee(!isAdditionalFee);
                                setaddituionalFeeInputsvalue([]);
                                setadditiionalfeeinputs([1]);
                                setAmount([]);
                              }}
                              type="checkbox"
                              defaultChecked={isAdditionalFee}
                            />
                            <span class="lever"></span>
                            <h3 className="switchcheckboxtext">
                              Additional Fee
                            </h3>
                          </label>
                        </div>

                        {isAdditionalFee ? (
                          <div>
                            <div className="form-group column">
                              {additiionalfeeinputs.map((item, index) => (
                                <div className="col-lg-12 col-sm-12s flex-column d-flex">
                                  <div>
                                    <label>
                                      Add Additional fee{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <div
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                      className="flex-row  d-flex  col-lg-12 col-sm-12s"
                                    >
                                      <select
                                        defaultValue={fees_type_id[index]}
                                        value={fees_type_id[index]}
                                        onChange={(e) => {
                                          let updatingarray = [...fees_type_id];
                                          updatingarray[index] = e.target.value;
                                          amount[index] = "";
                                          monthDate[
                                            index
                                          ] = getCurrentMonthYear();
                                          setfees_type_id(updatingarray);
                                          getRespectivefee(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        className="form-select"
                                        aria-label="Default select example"
                                      >
                                        <option selected disabled>
                                          Select Fee Type
                                        </option>
                                        {feetypes.map((fee) => (
                                          <option key={fee.id} value={fee.id}>
                                            {fee.name}
                                          </option>
                                        ))}
                                      </select>

                                      <input
                                        className="form-control mx-2"
                                        type="number"
                                        onChange={(e) => {
                                          additionalfeeChange(e, index);
                                        }}
                                        defaultValue={amount[index]}
                                        value={amount[index]}
                                        placeholder="Enter Custom Amount"
                                      />

                                      <input
                                        defaultValue={getCurrentMonthYear()}
                                        value={monthDate[index]}
                                        className="form-control"
                                        onChange={(e) => {
                                          let updatingarray = [...monthDate];
                                          updatingarray[index] = e.target.value;
                                          setMonthDate(updatingarray);
                                        }}
                                        type="month"
                                      />

                                      <button
                                        style={{
                                          marginLeft: "10px",
                                          width: "90px",
                                        }}
                                        id={index}
                                        className="btn btn-danger  "
                                        onClick={(e) => {
                                          e.preventDefault();

                                          setadditiionalfeeinputs(
                                            additiionalfeeinputs.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                          setfees_type_id(
                                            fees_type_id.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                          setAmount(
                                            amount.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              ))}

                              <br />
                              <div className="col-lg-3 col-sm-12">
                                <button
                                  id="add_additional_Fee_button"
                                  onClick={(e) => {
                                    addAdditionalInputfield(e);
                                  }}
                                  className="btn btn-success button_blue"
                                >
                                  Add Fields
                                </button>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div class="form-group row mt-4">
                      <div className="form-group col-md-4 flex-column d-flex mt-5">
                        <div className="switch">
                          <label className="switchcheckboxlabel flex-row d-flex">
                            <input
                              onChange={(e) => {
                                setisFineEnable(e.target.checked);
                              }}
                              type="checkbox"
                              defaultChecked={isFineEnable}
                            />
                            <span class="lever"></span>
                            <h3 className="switchcheckboxtext">Add Fine</h3>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 mt-5">
                        <label class="form-control-label">
                          <b>
                            Due Date <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <input
                          format="DD/MM/YYYY"
                          max="9999-12-30"
                          onChange={(e) => setdue_date(e.target.value)}
                          type="date"
                          value={due_date}
                          class="form-control  mb-2"
                        />
                      </div>

                      <div className="col-md-4 mt-5">
                        <label class="form-control-label">
                          <b>
                            Issue Date <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <input
                          format="DD/MM/YYYY"
                          max="9999-12-30"
                          value={issue_date}
                          onChange={(e) => setissue_date(e.target.value)}
                          type="date"
                          class="form-control  mb-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>{errorcreate}</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>
          {feeloading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "15px",
              }}
            >
              {" "}
              <span className="spinner-border"></span>{" "}
            </div>
          ) : (
            <Modal.Footer>
              <button
                onClick={generateSectionFee}
                className="btn btn-success button_blue"
              >
                Generate
              </button>

              <button
                onClick={() => {
                  cencelModal();
                  setfeemodal(false);
                }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
}

export default GenrateSectionWiseFee;
