import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { Table, Modal, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Barcode from "react-barcode";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ViewStudentPrintWise from "../ViewStudentPrintWise/ViewStudentPrintWise";
import ReactToPrint from "react-to-print";
import "./myprint.css";
import "../../../components/PrintChallanModal/printChallan.style.css";
import { upperCase } from "lodash";
import MultiStudentFeeChallan from "../MultiStudentFeeChallan/MultiStudentFeeChallan";
import { useAlert } from "react-alert";
import { PremissionChecker } from "../../../services/PremissionChecker";
const icon =
  "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";
const StudentWisePrint = () => {
  const componentRef = useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  //geeting token from storage
  const authtoken = localStorage.getItem("userToken");

  const history = useHistory();

  //redux actions

  const dispatch = useDispatch();

  //components states
  const user = useSelector((state) => state.user.user);
  const [printmodal, setprintmodal] = useState(false);
  const [viewModal, setviewModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [classes, setclasses] = useState([]);
  const [allsections, setallsections] = useState([]);
  const [selectedStudentChallan, setselectedStudentChallan] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [selectedcampus, setselectedcampus] = useState("");
  const [selectedclass, setselectedclass] = useState("");
  const [selectedSection, setselectedSection] = useState("");
  const [selectedStudent, setselectedStudent] = useState({});
  const [educationType, setEducationType] = useState(1);

  const [allstudentList, setallstudentsList] = useState([]);

  const PRINTCOUNT = [1, 2, 3];

  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const [allsessions, setallsessions] = useState([]);
  const [currentsession, setcurrentsession] = useState("");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [blankPage, setBlankPage] = useState(false);
  const [duplicateCharges, setDuplicateCharges] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const alert = useAlert();
  const pageStyle = `
  @page {
    size:letter landscape !important;
  }
  `;

  const [selectedChallans, setSelectedChallans] = useState([]);
  const [mainCheckBoxChecked, setmaincheckboxChecked] = useState(false);
  const searchClicked = useRef(false);
  const banksloaded = useRef(false);
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (!banksloaded.current) {
      banksloaded.current = true;
      getSettingData();
      // getAllCampus();
      getAllSessions();
    }

    if (searchClicked.current) {
      searchClicked.current = false;
      setAllCheckboxes(true);
    } else {
      checkAllCheckBoxes();
    }
  }, [[], selectedChallans]);
  // }, []);

  const getMonthName = (date) => {
    if (date == "") {
      return "_";
    }
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}`;
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
        searchInputRef.current.focus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changesession = (id) => {
    setcurrentsession(id);
  };

  const getAllCampus = async () => {
    console.log("campus geeting");
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onCampusChange = async (id) => {
    setselectedcampus(id);
    await fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setclasses(data.payload);
          // getChallans()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = async (id) => {
    setselectedclass(id);
    await fetch(
      `${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setallsections(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedclass ? "/" + selectedclass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setallsections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getChallans = () => {
    if (currentsession == "") {
      alert.error("Please Select Session");
      return;
    }

    if (selectedcampus == "") {
      alert.error("Please Select Campus");
      return;
    }

    if (selectedclass == "") {
      alert.error("Please Select Class");
      return;
    }

    // if (selectedSection == "") {
    //   alert.error("Please Select Section");
    //   return;
    // }
    setloading(true);

    const data = {
      year_id: currentsession,
      campus_id: selectedcampus,
      student_class_id: selectedclass,
      education_type: educationType,
      global_section_id: selectedSection,
    };

    fetch(`${baseUrl}/unpaid_challan_combine`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          if (data.payload.students.length == 0) {
            setSelectedChallans([]);
          }
          searchClicked.current = true;
          setallstudentsList(data.payload.students);
          setloading(false);
          setShowResult(true);
          // setAllCheckboxes(true);
        }
      })
      .catch((err) => {
        setloading(false);

        console.log(err);
      });
  };

  const getFeeAmount = (challans) => {
    let sum = 0;
    challans.forEach((element) => {
      sum += parseInt(element.amount);
    });
    return sum;
  };

  const gotoviewpage = (student) => {
    setselectedStudentChallan(student.fee_challans_detials);
    setviewModal(true);
  };

  const openPrint = (student) => {
    setselectedStudent(student);
    setprintmodal(true);
  };

  const addDuplicateCharges = () => {
    if (selectedChallans.length == 0) {
      return alert.error("Please select fee challan");
    }

    let selected_ids = selectedChallans.map((student) => ({
      id: student.id,
      campus_id: student.campus_id,
      session_id: student.session_id,
      student_class_id: student.student_class_id,
    }));

    let data = {
      selected_students: selected_ids,
    };

    fetch(`${baseUrl}/duplicate_fee_print_charges`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          alert.success(data.metadata.message);
          setallstudentsList([]);
          setSelectedChallans([]);
          setShowResult(false);
          setDuplicateCharges(false);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg[0]);
          });
          setallstudentsList([]);
          setSelectedChallans([]);
          setShowResult(false);
          setDuplicateCharges(false);
        } else {
          alert.error("Something went wrong!!");
          setallstudentsList([]);
          setSelectedChallans([]);
          setShowResult(false);
          setDuplicateCharges(false);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const getIssueDate = (arr) => {
    const result = arr[arr.length - 1];
    return result;
  };

  const PrintAllChallans = () => {
    if (allstudentList.length > 0) {
      dispatch({
        type: "GET_STUDENT_WISE_CHALLAN",
        payload: {
          studentWiseChallans: allstudentList,
        },
      });
      history.push(`/printstudentwisechallans`);
    } else {
      alert("Please select a campus");
    }
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    var element = document.getElementById("hettingheight");

    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const searchbyRegId = () => {
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
      searchInputRef.current.focus();
      setsearchtext("");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/unpaid_challan_search_std_wise`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length == 0) {
              alert.error("Match not found");
              setSelectedChallans([]);
              searchInputRef.current.focus();
              searchInputRef.current.value = "";
              setsearchtext("");
            }
            searchClicked.current = true;
            setallstudentsList(data.payload.students);
            setloading(false);
            setShowResult(true);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
            searchInputRef.current.focus();
            searchInputRef.current.value = "";
            setsearchtext("");
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const handleMainCheckboxClick = () => {
    if (mainCheckBoxChecked) setAllCheckboxes(false);
    else setAllCheckboxes(true);
  };

  const setAllCheckboxes = (value) => {
    setmaincheckboxChecked(value);
    if (value) {
      setSelectedChallans([]);
    }

    allstudentList.map((item, i) => {
      item.checked = value;
      if (!value) {
        RemoveSelectedChallan(item);
      } else {
        AddSelectedChallan(item);
      }
    });
  };

  const checkAllCheckBoxes = () => {
    if (allstudentList.length == selectedChallans.length) {
      setmaincheckboxChecked(true);
    } else if (mainCheckBoxChecked) {
      setmaincheckboxChecked(false);
    }
  };

  const handleChallansCheckBoxClick = (e, index) => {
    allstudentList.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;

        if (item.checked) {
          AddSelectedChallan(item);
        } else {
          RemoveSelectedChallan(item);
        }
      }
    });
  };

  const AddSelectedChallan = (item) => {
    setSelectedChallans((prevSelectedChallans) => [
      ...prevSelectedChallans,
      item,
    ]);
  };

  const RemoveSelectedChallan = (item) => {
    setSelectedChallans((prevSelectedChallans) =>
      prevSelectedChallans.filter((std) => std.id !== item.id)
    );
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row mt-3">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => changesession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option
                  key={index}
                  // selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedclass}
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Class
              </option>
              {classes.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="col-md-3 mt-2">
            <label>
              <b>Select Section</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedSection}
              onChange={(e) => setselectedSection(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Section
              </option>
              {allsections.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>

          <div className=" d-flex col-md-9 mt-11">
            <button
              type={"submit"}
              onClick={getChallans}
              className="btn btn-primary mr-4"
            >
              Get Challan
            </button>

            {showResult && allstudentList.length > 0 ? (
              <>
                <ReactToPrint
                  pageStyle={pageStyle}
                  documentTitle="Challan Copy"
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary"
                    >
                      {printloading ? "Printing..." : "Print Challan"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <div className="col-md-4 mt-4">
                  <label className="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      checked={blankPage}
                      onChange={(e) => setBlankPage(e.target.checked)}
                    />
                    <span />
                    &nbsp;&nbsp;&nbsp;Use Blank Page For Print
                  </label>
                </div>
                {PremissionChecker(
                  user,
                  "receive_fee.can_generate_duplicate_fee"
                ) ? (
                  <div className="col-md-4 mt-4">
                    <label className="checkbox checkbox-single">
                      <input
                        type="checkbox"
                        checked={duplicateCharges}
                        onChange={(e) => setDuplicateCharges(e.target.checked)}
                      />
                      <span />
                      &nbsp;&nbsp;&nbsp;Duplicate Fee Bill Charges
                    </label>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>

          <div className="row mt-5">
            <div style={{ margin: "1px" }} className="col-md-4">
              <input
                ref={searchInputRef}
                onChange={(e) => setsearchtext(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    searchbyRegId();
                  }
                }}
                className="form-control"
                placeholder="Enter Name or Admission ID"
              />
            </div>
            <div className="col-md-3">
              <button onClick={searchbyRegId} className="btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </div>

        <Table responsive>
          <thead>
            <tr id="tbl">
              <th style={{ width: "5px" }}>
                <input
                  onClick={(e) => {
                    handleMainCheckboxClick();
                  }}
                  checked={mainCheckBoxChecked}
                  style={{}}
                  placeholder="Checkbox"
                  type="checkbox"
                />
              </th>
              <th>Admission ID</th>
              <th>Student Name</th>
              <th>Father Name</th>
              <th>Class Section</th>
              <th>Amount</th>
              <th>Fee Month</th>
              <th>View</th>
              {/* <th>Print</th> */}
            </tr>
          </thead>
          <tbody>
            {allstudentList &&
              allstudentList.map((student) => (
                <tr>
                  <td style={{ width: "5px" }}>
                    <input
                      type="checkbox"
                      value={student.id}
                      key={student.id}
                      onClick={(e) => {
                        handleChallansCheckBoxClick(e, student.id);
                      }}
                      checked={student.checked ?? false}
                      style={{ border: "10px" }}
                    ></input>
                  </td>
                  <td className="text_align_start ">{student.admission_id}</td>
                  {student.status == 2 ? (
                    <td className="text_align_start ">{student.name}</td>
                  ) : (
                    <td className="text_align_start" style={{ color: "red" }}>
                      {student.name}
                    </td>
                  )}
                  <td className="text_align_start ">{student.father_name}</td>
                  <td style={{ textAlign: "start" }}>
                    {student.student_class.name}&nbsp;
                    {student.global_section.name}&nbsp; ({student.campus.name})
                  </td>
                  <td className="text_align_start ">
                    {getFeeAmount(student?.fee_challans_detials)}
                  </td>
                  <td className="text_align_start ">
                    {/* {student?.fee_challans_detials.map((student_fee_months) => (
                      <>{getMonthName(student_fee_months?.fee_month)}&nbsp;,</>
                    ))} */}
                    {student?.fee_challans_detials.map(
                      (student_fee_months, index) => (
                        <React.Fragment key={index}>
                          {getMonthName(student_fee_months?.fee_month)}
                          {index < student?.fee_challans_detials.length - 1 &&
                            ","}
                          &nbsp;
                        </React.Fragment>
                      )
                    )}
                  </td>
                  <td className="text_align_start">
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => gotoviewpage(student)}
                    >
                      <span className="svg-icon text_align_start svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Devices/Display2.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                  {/* <td className="text_align_start">
                    <ReactToPrint
                      pageStyle={pageStyle}
                      documentTitle="Challan Copy"
                      onAfterPrint={handleAfterPrint}
                      onBeforeGetContent={handleOnBeforeGetContent}
                      onBeforePrint={handleBeforePrint}
                      removeAfterPrint={true}
                      trigger={() => (
                        <a
                          onClick={() => (printloading ? true : false)}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm "
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Devices/Printer.svg"
                              )}
                            />
                          </span>
                        </a>
                      )}
                      content={() => componentRef2.current}
                    />
                  </td> */}
                </tr>
              ))}
          </tbody>
        </Table>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : null}

        <Modal show={viewModal}>
          <Modal.Header>
            <Modal.Title>Fee Challan Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>Fee Challan Id</th>
                  <th>Fee Month</th>
                  <th>Fee Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {selectedStudentChallan &&
                  selectedStudentChallan.map((feec) => (
                    <tr>
                      <td className="text_align_start ">
                        {feec.fee_challan_id}
                      </td>

                      <td className="text_align_start ">{feec.fee_month}</td>
                      <td className="text_align_start ">{feec.fee_name}</td>
                      <td className="text_align_start ">{feec.amount}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>

          <Modal.Footer>
            <button
              onClick={() => setviewModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Duplicate Fee Bill Modal */}
        <Modal show={duplicateCharges}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Duplicate Fee Bill Charges
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to generate duplicate fee bill charges?
          </Modal.Body>
          <Modal.Footer>
            <button onClick={addDuplicateCharges} className="btn btn-primary">
              Generate
            </button>
            <button
              onClick={() => setDuplicateCharges(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* ///////////////////////////////print/////////////////////////////////////////////////// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <MultiStudentFeeChallan
            allstudentList={
              allstudentList.length == selectedChallans.length
                ? allstudentList
                : selectedChallans
            }
            blankPage={blankPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default StudentWisePrint;
