import { Paper } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import ResultCard from "../StudentWiseResult/ResultCard";
import ReactToPrint from "react-to-print";
import ResulftCardForPrint from "../StudentWiseResult/ResulftCardForPrint";
import { upperCase } from "lodash";
import ResultCardHifz from "../StudentWiseResult/ResultCardHifz";
import ResulftCardHifzPrint from "../StudentWiseResult/ResulftCardHifzPrint";

const SingleStudentResult = () => {
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const alert = useAlert();

  const authtoken = localStorage.getItem("userToken");

  const [icon, setIcon] = useState([]);
  const [unitName, setUnitName] = useState("");

  useEffect(() => {
    getSettingData();
    getAllSessions();
    getAllCampus();
  }, []);

  const [admission_id, setadmission_id] = useState("");
  const [selectedcampus, setselectedcampus] = useState("");
  const [campusDetail, setcampusDetail] = useState([]);
  const [campusDetail2, setcampusDetail2] = useState([]);
  const [loading, setloading] = useState(false);
  const [campuses, setallcampuses] = useState([]);
  const [educationType, setEducationType] = useState(1);
  const [showresult, setshowresult] = useState(false);
  const [allexamslist, setallexamslist] = useState([]);
  const [currentsession, setcurrentsession] = useState("");
  const [allsessions, setallsessions] = useState([]);
  const [selectedExamType, setselectedExamType] = useState("");
  const [includeAnnual, setIncludeAnnual] = useState(false);
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const pageStyle = `@page{
    margin-top: 20px;
}`;
  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
            getAllExamsList(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getAllExamsList = (id) => {

  //   const data = {
  //     year_id: id
  //   }

  //   fetch(`${baseUrl}/exam_for_session`, {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //     body: JSON.stringify(data),
  //   }).then((res) => res.json())
  //     .then((data) => {

  //       console.log("get exam", data);

  //       setallexamslist([...data.payload]);
  //       // getassignedExamsList(data.payload)

  //     }).catch((err) => {

  //       console.log(err);
  //     });

  // }

  const onChangeSession = (id) => {
    setcurrentsession(id);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (selectedExamType == "") {
      alert.error("Please select a exam type");
    } else if (admission_id == "") {
      alert.error("Please enter a admission No");
    } else {
      let data = {
        exam_id: selectedExamType,
        admission_id,
        education_type: educationType,
        campus_id: selectedcampus,
        include_annual_term: includeAnnual ? 1 : 0,
      };

      setloading(true);
      fetch(`${baseUrl}/student-result-adm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data, " Hassan Checking");
          // setloading(false);

          if (data.metadata.success) {
            setcampusDetail2(data.payload);
            setcampusDetail(data.payload.students_full_result);
            setshowresult(true);
            setloading(false);
          } else if (!data.metadata.success) {
            // Object.values(data.metadata.message).map((msg) => {
            alert.error(data.payload);
            setshowresult(false);
            setloading(false);
            // });
          } else {
            alert.error("No Student Found!!");
          }
          console.log("hassan chaptar", data);
        })
        .catch((err) => {
          setloading(false);

          console.log(err);
        });
    }
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const getAllCampus = async () => {
    console.log("campus geeting");
    await fetch(`${baseUrl}/get-all-campuses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onCampusChange = async (id) => {
    getAllExamsList(id);
    setselectedcampus(id);
  };
  const getAllExamsList = (id) => {
    const data = {
      year_id: currentsession,
      campus_id: id,
    };

    fetch(`${baseUrl}/exam_for_session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("get exam", data);

        setallexamslist([...data.payload]);
        // getassignedExamsList(data.payload)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Paper style={{ marginTop: "10px", marginBottom: "10px", padding: "10px" }}>
      <form onSubmit={handleSearch}>
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => onChangeSession(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session) => (
                <option
                  // selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Exam</b>
            </label>
            <select
              onChange={(e) => setselectedExamType(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              // value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Exam</option>
              {allexamslist.map((exam) => (
                <option
                  // selected={exam.active_academic_year == "1" ? true : false}
                  value={exam.id}
                >
                  {exam?.exam_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>

          <div className="col-md-3 mt-4">
            <label>
              <b>Enter Admission No</b>
            </label>
            <input
              placeholder="Enter Admission No"
              onChange={(e) => setadmission_id(e.target.value)}
              className="form-control"
            />
          </div>

          <div style={{ height: 40 }} className="col-md-2 mt-16">
            <input type="checkbox" style={{ display: "none" }} />
            <label className="checkbox checkbox-single">
              <input
                type="checkbox"
                onChange={(e) => setIncludeAnnual(e.target.checked)}
              />
              <span />
              &nbsp;&nbsp;&nbsp;Include FTM
            </label>
          </div>
          <div className="col-md-2 mt-12 d-flex" style={{ height: 40 }}>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <button type="submit" className="btn btn-primary mr-4">
                Search
              </button>
            )}
            {showresult ? (
              <div>
                {
                  <ReactToPrint
                    documentTitle="Student result"
                    pageStyle={pageStyle}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContent}
                    onBeforePrint={handleBeforePrint}
                    onPrintError={(e) => console.log(e)}
                    removeAfterPrint={true}
                    trigger={() => (
                      <button
                        disabled={printloading ? true : false}
                        className="btn btn-primary"
                      >
                        {printloading ? "Printing..." : "Print"}
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                }
              </div>
            ) : null}
          </div>
        </div>
      </form>

      {showresult &&
        campusDetail.length > 0 &&
        educationType == 1 &&
        campusDetail.map((item) => (
          <ResultCard
            student_result_overall={
              Array.isArray(item.student__result_overall)
                ? item.student__result_overall.find(
                    (item) => item.exam == "FIRST TERM"
                  )
                : item.student__result_overall
            }
            studentDetails={item.student}
            campusDetail={campusDetail2}
            icon={icon}
            unitName={unitName}
            studentResultGradeWise={item.student_result_grade_wise}
            studentResultList={item.student_result}
            studentFinalResult={item.student_final_result}
            isInclude={Array.isArray(item.student__result_overall)}
            currentExam={
              Array.isArray(item.student__result_overall)
                ? item.student__result_overall.find(
                    (subitem) =>
                      subitem.exam == campusDetail2?.exam?.exam_type?.name
                  )
                : ""
            }
          />
        ))}

      {showresult &&
        campusDetail.length > 0 &&
        educationType == 2 &&
        campusDetail.map((item) => (
          <ResultCardHifz
            student_result_overall={
              Array.isArray(item.student__result_overall)
                ? item.student__result_overall.find(
                    (item) => item.exam == "FIRST TERM"
                  )
                : item.student__result_overall
            }
            studentDetails={item.student}
            campusDetail={campusDetail2}
            icon={icon}
            unitName={unitName}
            studentResultGradeWise={item.student_result_grade_wise}
            studentResultHifz={item.student_result_hifz}
            studentResultHifzFinal={item.student_result_hifz_final}
            studentResultList={item.student_result}
            studentFinalResult={item.student_final_result}
            isInclude={Array.isArray(item.student__result_overall)}
            currentExam={
              Array.isArray(item.student__result_overall)
                ? item.student__result_overall.find(
                    (subitem) =>
                      subitem.exam == campusDetail2?.exam?.exam_type?.name
                  )
                : ""
            }
          />
        ))}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        {showresult &&
          campusDetail.length > 0 &&
          educationType == 1 &&
          campusDetail.map((item) => (
            <div style={{ height: "100vh" }}>
              <ResulftCardForPrint
                student_result_overall={
                  Array.isArray(item.student__result_overall)
                    ? item.student__result_overall.find(
                        (item) => item.exam == "FIRST TERM"
                      )
                    : item.student__result_overall
                }
                studentDetails={item.student}
                campusDetail={campusDetail2}
                studentResultGradeWise={item.student_result_grade_wise}
                icon={icon}
                unitName={unitName}
                studentResultList={item.student_result}
                studentFinalResult={item.student_final_result}
                isInclude={Array.isArray(item.student__result_overall)}
                currentExam={
                  Array.isArray(item.student__result_overall)
                    ? item.student__result_overall.find(
                        (subitem) =>
                          subitem.exam == campusDetail2?.exam?.exam_type?.name
                      )
                    : ""
                }
              />
            </div>
          ))}

        {showresult &&
          campusDetail.length > 0 &&
          educationType == 2 &&
          campusDetail.map((item) => (
            <div style={{ height: "100vh" }}>
              <ResulftCardHifzPrint
                student_result_overall={
                  Array.isArray(item.student__result_overall)
                    ? item.student__result_overall.find(
                        (item) => item.exam == "FIRST TERM"
                      )
                    : item.student__result_overall
                }
                studentDetails={item.student}
                campusDetail={campusDetail2}
                studentResultGradeWise={item.student_result_grade_wise}
                icon={icon}
                unitName={unitName}
                studentResultHifz={item.student_result_hifz}
                studentResultHifzFinal={item.student_result_hifz_final}
                studentResultList={item.student_result}
                studentFinalResult={item.student_final_result}
                isInclude={Array.isArray(item.student__result_overall)}
                currentExam={
                  Array.isArray(item.student__result_overall)
                    ? item.student__result_overall.find(
                        (subitem) =>
                          subitem.exam == campusDetail2?.exam?.exam_type?.name
                      )
                    : ""
                }
              />
            </div>
          ))}
      </div>
    </Paper>
  );
};

export default SingleStudentResult;
