import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useEffect } from "react";
import "./card.result.css";
import { upperCase } from "lodash";

const pic = "https://www.colorhexa.com/a4dded.png";
// const logo =
//   "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";

function ResulftCardForPrint({
  studentResultList,
  studentFinalResult,
  studentDetails,
  campusDetail,
  student_result_overall,
  isInclude,
  currentExam,
  icon,
  unitName,
  weekList,
  studentResultGradeWise,
}) {
  console.log("currentExam: ", currentExam);
  console.log("logo: ", icon);
  // useEffect(() => {
  //   if(Array.isArray(student_result_overall)){
  //     const index = student_result_overall.findIndex(item => item.exam == campusDetail.exam.exam_type.name);

  //     if (index !== -1) {
  //       const itemToMove = student_result_overall.splice(index, 1)[0];
  //       student_result_overall.unshift(itemToMove);
  //     }
  //   }
  //    }, [student_result_overall])
  const getTotalNumber = (array) => {
    let sum = 0;

    array.forEach((element) => {
      sum += parseInt(element.gain_marks);
    });

    return sum;
  };

  const getPracticalTotalNumber = (array) => {
    console.log("getPracticalTotalNumber", array);
    let sum = 0;

    array.forEach((element) => {
      sum += parseInt(element.practical_marks);
    });

    return sum;
  };

  const getGrade = (fullmarks, gainmarks) => {
    let obtainMarks = gainmarks / fullmarks;
    let middle = obtainMarks * 100;
    let percentage = middle.toFixed(2);

    if (percentage <= 100 && percentage >= 90) {
      return "A+";
    } else if (percentage < 90 && percentage >= 80) {
      return "A";
    } else if (percentage < 80 && percentage >= 70) {
      return "B";
    } else if (percentage < 70 && percentage >= 60) {
      return "C";
    } else if (percentage < 60 && percentage >= 50) {
      return "D";
    } else if (percentage < 50 && percentage >= 40) {
      return "E";
    } else if (percentage < 40 && percentage >= 0) {
      return "F";
    } else {
      return "--";
    }
  };

  const getPercentage = (totalmarks, gainMarks) => {
    const percentage = (gainMarks / totalmarks) * 100;
    return percentage.toFixed(1) + "%";
  };

  const getMonthName = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return upperCase(`${monthNames[d.getMonth()]}, ${d.getFullYear()}`);
  };

  // Function to get the name by week_id
  const getWeekNameById = (week_id) => {
    const week = weekList.find((w) => w.id == week_id);
    return week ? week.name : "Unknown Week"; // Return name or a default value
  };
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <table
              className="result__card__table"
              border="2"
              cellpadding="0"
              cellspacing="0"
              width="100%"
            >
              <tbody>
                <tr>
                  <td style={{ width: "118px", textAlign: "center" }}>
                    <img className="p-1" id="Image1" src={icon} width={100} />
                  </td>
                  <td style={{ width: "457", textAlignLast: "center" }}>
                    <span
                      style={{ fontSize: "1.85rem" }}
                      id="LblOIDName"
                      className="AName"
                    >
                      {unitName}
                    </span>
                    <br />
                    <span
                      style={{ fontSize: "1.65rem" }}
                      id="LblTermName"
                      className="AName"
                    >
                      RESULT CARD
                    </span>
                    <br />
                    <span
                      style={{ fontSize: "1.5rem" }}
                      id="LblTermName"
                      className="AName2"
                    >
                      {campusDetail?.exam?.exam_type?.name}
                      {campusDetail?.exam?.week_id != null
                        ? campusDetail?.exam?.month != null
                          ? " " +
                            "( " +
                            getWeekNameById(campusDetail?.exam?.week_id) +
                            " OF " +
                            getMonthName(campusDetail?.exam?.month) +
                            " )"
                          : " " +
                            "( " +
                            getWeekNameById(campusDetail?.exam?.week_id) + // Use the week name only
                            " )"
                        : campusDetail?.exam?.month != null
                        ? " " +
                          "( " +
                          getMonthName(campusDetail?.exam?.month) +
                          " )"
                        : ""}
                    </span>
                  </td>
                  <td
                    style={{ width: "125px", textAlign: "center" }}
                    rowSpan={2}
                  >
                    <img
                      id="StudentImage"
                      src={studentDetails?.picture || pic}
                      style={{ height: 135, width: 124, marginRight: 15 }}
                    />
                  </td>
                </tr>

                <>
                  <tr>
                    <td colSpan="2">
                      <table
                        border="0"
                        cellpadding="5"
                        cellspacing="0"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="BoldText"
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                width: "145px",
                                textAlign: "right",
                                color: "#000080",
                              }}
                            >
                              Admission ID :-
                            </td>
                            <td
                              style={{
                                fontSize: "1.5rem",

                                width: "330px",
                                textAlign: "start",
                                color: "#000080",
                              }}
                            >
                              <span
                                style={{ fontSize: "1.5rem" }}
                                id="LblAdmissionID"
                                className="BoldText"
                              >
                                {studentDetails?.admission_id}
                              </span>
                            </td>
                            <td
                              className="LabelStyle"
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                width: "90px",
                                textAlign: "right",
                                color: "#000080",
                              }}
                            >
                              Session :-
                            </td>
                            <td
                              align="Center"
                              style={{
                                fontSize: "1.5rem",

                                width: "135px",
                                textAlign: "left",
                                color: "#000080",
                              }}
                            >
                              <span id="LblSession" className="NormalText">
                                {campusDetail?.exam?.session?.year}{" "}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <table
                        border="1"
                        cellPadding="2"
                        cellSpacing="0"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="BoldText"
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                width: "125px",
                                border: "1px solid black",
                                color: "#000080",
                              }}
                            >
                              Name
                            </td>
                            <td
                              style={{
                                fontSize: "1.5rem",
                                width: "575px",
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                                color: "#000080",
                              }}
                              colSpan="3"
                            >
                              <span
                                style={{ fontSize: "1.5rem" }}
                                id="LblName"
                                className="NormalText"
                              >
                                {studentDetails?.name}{" "}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="BoldText"
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                width: "125px",
                                border: "1px solid black",
                                color: "#000080",
                              }}
                            >
                              Father's Name
                            </td>
                            <td
                              colSpan="3"
                              style={{
                                width: "575px",
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                                color: "#000080",
                              }}
                            >
                              <span
                                style={{ fontSize: "1.5rem" }}
                                id="LblFather"
                                className="NormalText"
                              >
                                {studentDetails?.father_name}{" "}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="BoldText"
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                width: "125px",
                                border: "1px solid black",
                                color: "#000080",
                              }}
                            >
                              Class
                            </td>
                            <td
                              style={{
                                width: "240px",
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                                color: "#000080",
                              }}
                            >
                              <span
                                style={{ fontSize: "1.5rem" }}
                                id="LblClass"
                                className="NormalText"
                              >
                                {campusDetail?.student_class?.name}{" "}
                              </span>
                            </td>
                            <td
                              className="LabelStyle"
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                width: "80px",
                                border: "1px solid black",
                                color: "#000080",
                              }}
                            >
                              Section
                            </td>
                            <td
                              align="Center"
                              style={{
                                width: "255px",
                                textAlign: "left",
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                                color: "#000080",
                              }}
                            >
                              <span
                                style={{ fontSize: "1.5rem" }}
                                id="LblSection"
                                className="NormalText"
                              >
                                {campusDetail?.global_section?.name}{" "}
                              </span>
                            </td>
                          </tr>
                          {/* <tr>
                            <td
                              colspan="4"
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                              }}
                            >
                              <span
                                style={{ fontSize: "1.5rem" }}
                                id="LblCourse"
                                className="NormalText"
                              >
                                Detail of Marks Obtained
                              </span>
                            </td>
                          </tr> */}
                          <tr>
                            <td colspan="4">
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <div>
                                        <table
                                          className="GridStyleResultCard"
                                          cellSpacing="0"
                                          rules="all"
                                          border="2"
                                          id="GridView1"
                                          style={{
                                            width: "100%",
                                            borderCollapse: "collapse",
                                          }}
                                        >
                                          <tbody>
                                            <tr
                                              className="GridHeaderStyle"
                                              style={{
                                                border: "1px solid black",
                                              }}
                                            >
                                              <th
                                                style={{
                                                  fontSize: "1.5rem",

                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                SUBJECTS
                                              </th>
                                              <th
                                                style={{
                                                  fontSize: "1.5rem",

                                                  border: "1px solid black",
                                                  width: "110px",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Total Marks
                                              </th>
                                              <th
                                                style={{
                                                  fontSize: "1.5rem",

                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Theory
                                              </th>
                                              <th
                                                style={{
                                                  fontSize: "1.5rem",

                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Presentation
                                              </th>
                                              <th
                                                style={{
                                                  fontSize: "1.5rem",

                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Total Gain
                                              </th>
                                              <th
                                                style={{
                                                  fontSize: "1.5rem",

                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                %
                                              </th>
                                              <th
                                                style={{
                                                  fontSize: "1.5rem",

                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Grade
                                              </th>
                                              <th
                                                style={{
                                                  fontSize: "1.5rem",

                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Remarks
                                              </th>
                                            </tr>
                                            {studentResultList &&
                                              studentResultList.map(
                                                (onePersonResult, index) => (
                                                  <tr className="GridRowStyle">
                                                    <td
                                                      // valign="middle"
                                                      style={{
                                                        fontSize: "1.5rem",

                                                        width: "300px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "start",
                                                        paddingLeft: "10px",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {
                                                        onePersonResult?.subject
                                                          ?.name
                                                      }{" "}
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        fontSize: "1.5rem",

                                                        width: "90px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {
                                                        onePersonResult?.full_marks
                                                      }
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        fontSize: "1.5rem",

                                                        width: "90px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {onePersonResult.status ==
                                                      "Present"
                                                        ? onePersonResult.gain_marks
                                                        : onePersonResult.status}
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: "1.5rem",

                                                        width: "90px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {onePersonResult?.practical_marks ==
                                                      0
                                                        ? "--"
                                                        : onePersonResult?.practical_marks}
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        fontSize: "1.5rem",
                                                        width: "130px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {Number(
                                                        onePersonResult.gain_marks
                                                      ) +
                                                        Number(
                                                          onePersonResult?.practical_marks
                                                        )}
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        fontSize: "1.5rem",

                                                        width: "60px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {
                                                        onePersonResult?.percentage
                                                      }
                                                      %
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        fontSize: "1.5rem",

                                                        width: "60px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {onePersonResult?.grade}
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        fontSize: "1.5rem",

                                                        width: "80px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {onePersonResult?.grade ==
                                                        "F" ||
                                                      onePersonResult?.grade ==
                                                        "F+"
                                                        ? "FAIL"
                                                        : "PASS"}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {isInclude ? (
                    <>
                      {/* Annual Term */}
                      <tr>
                        <td colSpan="3" align="center">
                          <div
                            style={{
                              borderRight: "1px solid black",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              // borderBottom: "1px solid black",
                            }}
                          >
                            <table
                              id="TblFail"
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="BoldText muResult1"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {currentExam?.exam}
                                  </td>

                                  <td
                                    className="muResult2"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {currentExam?.full_marks}
                                  </td>

                                  <td
                                    className="muResult3"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {currentExam?.gain_marks}
                                  </td>
                                  {currentExam?.fail_in.length > 0 &&
                                  currentExam?.full_marks > 0 ? (
                                    <>
                                      <td
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                          fontWeight: "bold",
                                          width: "478px",
                                        }}
                                      >
                                        {currentExam?.fail_in.length > 0
                                          ? "Failed In"
                                          : ""}
                                        &nbsp;&nbsp;
                                        {currentExam?.fail_in.length > 0
                                          ? currentExam?.fail_in.map(
                                              (overvall, i) => (
                                                <span
                                                  style={{
                                                    fontSize: "1.5rem",
                                                    color: "#000080",
                                                    alignSelf: "flex-end",
                                                  }}
                                                  key={i + 1}
                                                  id="LblGazres"
                                                  className="BoldText"
                                                >
                                                  {overvall.substr(0, 3)}
                                                  {","}&nbsp;
                                                </span>
                                              )
                                            )
                                          : null}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td
                                        className="muResult4"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                        }}
                                      >
                                        {currentExam?.practical_marks == 0
                                          ? "--"
                                          : currentExam?.practical_marks}
                                      </td>
                                      <td
                                        className="muResult5"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                        }}
                                      >
                                        {currentExam?.practical_marks +
                                          currentExam?.gain_marks}
                                      </td>
                                      <td
                                        className="muResult6"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                        }}
                                      >
                                        {currentExam?.percentage}
                                      </td>
                                      <td
                                        className="muResult7"
                                        id="forgrade"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                        }}
                                      >
                                        {currentExam?.grade}
                                      </td>
                                      <td
                                        className="muResult8"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                        }}
                                      >
                                        {/* Current Exam Pass/Fail Logic */}
                                        {currentExam?.grade == "F"
                                          ? "FAIL"
                                          : "PASS"}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                      {/* First Term */}
                      <tr>
                        <td colSpan="3" align="center">
                          <div
                            style={{
                              borderRight: "1px solid black",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              // borderBottom: "1px solid black",
                            }}
                          >
                            <table
                              id="TblFail"
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="BoldText muResult1"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {student_result_overall.exam}
                                  </td>

                                  <td
                                    className="muResult2"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {student_result_overall?.full_marks}
                                  </td>

                                  <td
                                    className="muResult3"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {student_result_overall?.gain_marks}
                                  </td>
                                  {student_result_overall?.fail_in.length > 0 &&
                                  student_result_overall?.full_marks > 0 ? (
                                    <>
                                      <td
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                          fontWeight: "bold",
                                          width: "478px",
                                        }}
                                      >
                                        {student_result_overall?.fail_in
                                          .length > 0
                                          ? ""
                                          : ""}
                                        &nbsp;&nbsp;
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td
                                        className="muResult4"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                        }}
                                      >
                                        {student_result_overall.practical_marks ==
                                        0
                                          ? "--"
                                          : student_result_overall.practical_marks}
                                      </td>
                                      <td
                                        className="muResult5"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                        }}
                                      >
                                        {student_result_overall.practical_marks +
                                          Number(
                                            student_result_overall?.gain_marks
                                          )}
                                      </td>
                                      <td
                                        className="muResult6"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                          // paddingLeft:"25px",
                                        }}
                                      >
                                        {student_result_overall?.percentage}
                                      </td>
                                      <td
                                        className="muResult7"
                                        id="forgrade"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                        }}
                                      >
                                        {student_result_overall.grade}
                                      </td>
                                      <td
                                        className="muResult8"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "#000080",
                                        }}
                                      >
                                        {/* First Term Pass/Fail Logic */}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                      {/* Overall Result */}
                      <tr>
                        <td colSpan="3" align="center">
                          <div
                            style={{
                              borderRight: "1px solid black",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <table
                              id="TblFail"
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="BoldText muResult1"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    OVER ALL RESULT
                                  </td>
                                  <td
                                    className="muResult2"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {
                                      // Overall Total Marks
                                      studentFinalResult?.full_marks
                                    }
                                  </td>
                                  <td
                                    className="muResult3"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {
                                      // Overall Gain Marks
                                      studentFinalResult?.gain_marks
                                    }
                                  </td>
                                  <td
                                    className="muResult4"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {// Overall Practical Marks
                                    studentFinalResult?.practical_marks == 0
                                      ? "--"
                                      : studentFinalResult?.practical_marks}
                                  </td>
                                  <td
                                    className="muResult5"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {// Overall Total Gain Marks
                                    studentFinalResult?.gain_marks +
                                      studentFinalResult?.practical_marks}
                                  </td>
                                  <td
                                    className="muResult6"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                      // paddingLeft:"25px",
                                    }}
                                  >
                                    {
                                      // Overall Percentage
                                      studentFinalResult?.percentage
                                    }
                                  </td>
                                  <td
                                    className="muResult7"
                                    id="forgrade"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {
                                      // Overall Grade Showing
                                      studentFinalResult?.grade
                                    }
                                  </td>

                                  {/* // Overall new pass/fail logic */}
                                  <td
                                    className="muResult8"
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#000080",
                                    }}
                                  >
                                    {studentFinalResult?.remarks}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="3" align="center">
                        <div
                          style={{
                            borderRight: "1px solid black",
                            borderTop: "1px solid black",
                            borderLeft: "1px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <table
                            id="TblFail"
                            border="0"
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  className="BoldText muResult1"
                                  style={{
                                    fontSize: "1.5rem",
                                    color: "#000080",
                                    fontWeight: "bold",
                                  }}
                                >
                                  OVER ALL RESULT
                                </td>

                                <td
                                  className="muResult2"
                                  style={{
                                    fontSize: "1.5rem",
                                    color: "#000080",
                                  }}
                                >
                                  {student_result_overall?.full_marks}
                                </td>

                                <td
                                  className="muResult3"
                                  style={{
                                    fontSize: "1.5rem",
                                    color: "#000080",
                                  }}
                                >
                                  {student_result_overall?.gain_marks}
                                </td>
                                {student_result_overall?.fail_in.length > 0 ? (
                                  <>
                                    <td
                                      className="muResult4"
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.practical_marks}
                                    </td>
                                    <td
                                      className="muResult5"
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.gain_marks +
                                        student_result_overall?.practical_marks}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "#000080",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {student_result_overall?.fail_in.length >
                                      0
                                        ? "Failed In"
                                        : ""}
                                      &nbsp;&nbsp;
                                      {student_result_overall?.fail_in.length >
                                      0
                                        ? student_result_overall?.fail_in.map(
                                            (overvall, i) => (
                                              <span
                                                style={{
                                                  fontSize: "1.5rem",
                                                  color: "#000080",
                                                  alignSelf: "flex-end",
                                                }}
                                                key={i + 1}
                                                id="LblGazres"
                                                className="BoldText"
                                              >
                                                {overvall.substr(0, 3)}
                                                {","}&nbsp;
                                              </span>
                                            )
                                          )
                                        : null}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td
                                      className="muResult4"
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.practical_marks ==
                                      0
                                        ? "--"
                                        : student_result_overall?.practical_marks}
                                    </td>
                                    <td
                                      className="muResult5"
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.gain_marks +
                                        student_result_overall?.practical_marks}
                                    </td>
                                    <td
                                      className="muResult6"
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "#000080",
                                        // paddingLeft:"25px",
                                      }}
                                    >
                                      {student_result_overall?.percentage}
                                    </td>
                                    <td
                                      className="muResult7"
                                      id="forgrade"
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.grade}
                                    </td>
                                    <td
                                      className="muResult8"
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.remarks}
                                    </td>
                                  </>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td colSpan="3" style={{ textAlign: "left" }}>
                      {/* <br /> */}
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td colSpan="3" style={{ textAlign: "left" }}>
                              <div className="d-flex justify-content-between">
                                <div style={{ marginTop: "20px" }}>
                                  <ul
                                    style={{
                                      fontSize: "1.25rem",
                                      color: "#000080",
                                      textAlign: "start",
                                      paddingLeft: "10px",
                                      listStyleType: "none",
                                      lineHeight: "1.25",
                                    }}
                                  >
                                    <li>
                                      <b>90% - Above</b> &nbsp; A+ Grade -
                                      OutStanding.
                                    </li>
                                    <li>
                                      <b>80% - 89.99%</b> &nbsp;&nbsp;&nbsp;A
                                      Grade - Excellent.
                                    </li>
                                    <li>
                                      <b>70% - 79.99%</b> &nbsp;&nbsp;&nbsp; B
                                      Grade - Very Good.
                                    </li>
                                    <li>
                                      <b>60% - 69.99%</b> &nbsp;&nbsp;&nbsp;C
                                      Grade - Good.
                                    </li>
                                  </ul>
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                  <ul
                                    style={{
                                      fontSize: "1.25rem",
                                      color: "#000080",
                                      textAlign: "start",
                                      // paddingRight: "25rem",
                                      listStyleType: "none",
                                      lineHeight: "1.25",
                                    }}
                                  >
                                    <li>
                                      <b>50% - 59.99%</b> D Grade - Fair.
                                    </li>
                                    <li>
                                      <b>40% - 49.99%</b> E Grade - Weak.
                                    </li>
                                    <li>
                                      <b>&nbsp;&nbsp; 0% - 39.99%</b> F Grade -
                                      Fail.
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {studentResultGradeWise &&
                                  studentResultGradeWise.length > 0 ? (
                                    <table
                                      className="GridStyleResultCard"
                                      id="DataList1"
                                      // className="NormalText"
                                      cellSpacing="0"
                                      style={{
                                        borderCollapse: "collapse",
                                        marginRight: "2rem",
                                      }}
                                    >
                                      <thead>
                                        <th
                                          style={{
                                            border: "1px solid black",
                                            color: "#000080",
                                            fontSize: "1.3rem",
                                            width: "200px",
                                            textAlign: "center",
                                          }}
                                          scope="col"
                                        >
                                          SUBJECTS
                                        </th>
                                        <th
                                          style={{
                                            border: "1px solid black",
                                            color: "#000080",
                                            fontSize: "1.3rem",
                                            width: "70px",
                                            textAlign: "center",
                                          }}
                                          scope="col"
                                        >
                                          Grade
                                        </th>
                                      </thead>
                                      <tbody>
                                        {studentResultGradeWise &&
                                          studentResultGradeWise?.map(
                                            (item, index) => (
                                              <tr key={index}>
                                                <td
                                                  style={{
                                                    color: "#000080",
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    padding: "0 0 0 10px",
                                                    fontSize: "1.2rem",
                                                    width: "200px",
                                                  }}
                                                >
                                                  {item?.subject?.name}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "#000080",
                                                    border: "1px solid black",
                                                    textAlign: "center",
                                                    padding: "0 0 0 10px",
                                                    fontSize: "1.2rem",
                                                    width: "70px",
                                                  }}
                                                >
                                                  {item?.grade}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                    </table>
                                  ) : null}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} style={{ verticalAlign: "top" }}>
                              <table
                                id="DataList2"
                                className="NormalText"
                                cellSpacing="0"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan={3}
                                      style={{ verticalAlign: "top" }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "1.25rem",
                                          color: "#000080",
                                          textAlign: "start",
                                          marginTop: "-10px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <b>Note.1</b> In Urdu, English, Math and
                                        Science, those students who will fail in
                                        any two subjects will be considered
                                        fail.
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "1.25rem",
                                          color: "#000080",
                                          textAlign: "start",
                                          marginTop: "-20px",
                                          marginLeft: "10px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        <b>Note.2</b> Those students who will
                                        fail in one subject mentioned above and
                                        will fail in other two subjects
                                        i.e.(Islamiat, S. Studies, Arabic,
                                        Drawing/H. Eco.) will be considered
                                        fail.
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "1.25rem",
                                          color: "#000080",
                                          textAlign: "start",
                                          marginTop: "-20px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <b>Note.3</b> In 8th Class fail in any
                                        two subjects should be considered fail.
                                      </p>
                                      <div align="right">
                                        <p
                                          style={{
                                            fontSize: "1.25rem",
                                            color: "#000080",
                                            textAlign: "start",
                                            marginTop: "20px",
                                            marginRight: "20px",
                                            textAlign: "right",
                                            textDecoration: "overline",
                                          }}
                                        >
                                          <b>Controller of Examinations</b>
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="3">
                      <br />
                      <div style={{ border: "1px solid black" }}>
                        <table
                          border="0"
                          cellPadding="5"
                          cellSpacing="0"
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{ fontSize: "1.4rem" }}
                                  id="lblAddress1"
                                  className="BoldText"
                                >
                                  {campusDetail?.campus?.name}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ResulftCardForPrint;
