import { Paper } from "@material-ui/core";
import { Divider, DatePicker } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { baseUrl } from "../../../../services/config";
// import { Pagination } from "../../../../_metronic/_partials/controls";
import * as uiHelpers from "../../../modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from "@material-ui/lab";
import usePagination from "../../../../components/usePagination/usePagination";
import { Table } from "react-bootstrap";
import { useAlert } from "react-alert";

const { RangePicker } = DatePicker;
const LogReport = () => {
  const [reports, setReports] = useState([]);
  const [roleList, setRolesList] = useState([]);
  let authtoken = localStorage.getItem("userToken");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userName, setUserName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [loading, setloading] = useState(false);
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const [overAllLog, setOverAllLog] = useState(true);
  const [userWiseLog, setUserWiseLog] = useState(false);
  const [mobileWiseLog, setMobileWiseLog] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [selected, setSelected] = useState("overAllLog");
  const [messageCount, setMessageCount] = useState(0);
  const alert = useAlert();

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  useEffect(() => {
    getRoles();
  }, []);

  // const getAllSMSLogs = () => {
  //   setloading(true);
  //   fetch(`${baseUrl}/sms-log-report`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //   })
  //     .then((res) => res.json())

  //     .then((data) => {
  //       setloading(false);
  //       let totalCharacterCount = 0;
  //       let totalMessageCount = 0;

  //       let temparr = data.payload;
  //       data.payload.map((item, index) => {
  //         item["SrNo"] = index + 1;
  //         temparr[index] = item;

  //         let currentCharacterCount = item.message.length;
  //         let currentMessageCount = Math.ceil(currentCharacterCount / 160);

  //         // Accumulate the totals
  //         totalCharacterCount += currentCharacterCount;
  //         totalMessageCount += currentMessageCount;
  //       });
  //       setMessageCount(totalMessageCount);
  //       setReports(temparr);
  //     })
  //     .catch((err) => {
  //       setloading(false);
  //       console.log(err);
  //     });
  // };

  const getRoles = () => {
    fetch(`${baseUrl}/roles`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        console.log("log List", data.payload);
        setRolesList(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fdata = new FormData();

    fdata.append("over_all_log", overAllLog ? 1 : 0);
    fdata.append("user_wise_log", userWiseLog ? 1 : 0);
    fdata.append("mobile_wise_log", mobileWiseLog ? 1 : 0);
  };

  const onRadioButtonClick = (e) => {
    const value = e.target.value;
    setSelected(e.target.value);
    if (value == "overAllLog") {
      setOverAllLog(true);
      setUserWiseLog(false);
      setMobileWiseLog(false);
      setUserName("");
      setMobileNo("");
      setReports([]);
    }
    if (value == "userWiseLog") {
      setOverAllLog(false);
      setUserWiseLog(true);
      setMobileWiseLog(false);
      setUserName("");
      setMobileNo("");
      setReports([]);
    }
    if (value == "mobileWiseLog") {
      setOverAllLog(false);
      setUserWiseLog(false);
      setMobileWiseLog(true);
      setUserName("");
      setMobileNo("");
      setReports([]);
    }
  };

  const columns = [
    {
      dataField: "user[0]",
      text: "User",
      style: { textAlign: "center" },
    },
    {
      dataField: "date_time",
      text: "Time",
      width: "20px",
    },
    {
      dataField: "number",
      text: "Number",
    },
    {
      dataField: "",
      text: "",
    },
    {
      headerStyle: { width: "550px" },
      style: { width: "550px", textAlign: "left" },
      dataField: "message",
      text: "Message",
    },
  ];
  const options = {
    custom: true,
    totalSize: reports.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };

  const updatingQueryParams = (page) => {
    console.log(page);
    console.log("this is chus2");
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const handlePickerChnage = (date, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const getSMSLog = () => {
    if (userWiseLog && userName == "") {
      return alert.error("User Type is Required");
    }
    if (mobileWiseLog && mobileNo == "") {
      return alert.error("Mobile No. is Required");
    }
    setloading(true);
    setIsVisible(false);
    const data = {
      start_date: startDate,
      end_date: endDate,
      user_name: userName != "" ? '["' + userName + '"]' : "",
      mobile_no: mobileNo,
    };
    fetch(`${baseUrl}/date-wise-sms-log-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setloading(false);
        if (data.metadata.success) {
          let totalCharacterCount = 0;
          let totalMessageCount = 0;
          let temparr = data.payload;

          data.payload.map((item, index) => {
            item["SrNo"] = index + 1;
            temparr[index] = item;

            let currentCharacterCount = item.message.length;
            let currentMessageCount = Math.ceil(currentCharacterCount / 160);

            // Accumulate the totals
            totalCharacterCount += currentCharacterCount;
            totalMessageCount += currentMessageCount;
          });
          setMessageCount(totalMessageCount);
          setReports(temparr);
          setIsVisible(true);
        } else {
          setloading(false);
          alert.error("No Record found");
        }
      })
      .catch((err) => {
        setloading(false);
        console.log("err is re", err);
      });
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(reports.length / PER_PAGE);
  const _DATA = usePagination(reports, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row mt-6">
            <div className="col-sm-3">
              <input
                // disabled={loginAccount == "Campus" ? true : false}
                checked={selected == "overAllLog"}
                onChange={(e) => onRadioButtonClick(e)}
                defaultChecked
                type="radio"
                id="overAllLog"
                name="radioButton"
                value="overAllLog"
              />
              <label for="overAllLog">Over All Log</label>
            </div>

            <div className="col-sm-3">
              <input
                checked={selected == "userWiseLog"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="userWiseLog"
                name="radioButton"
                value="userWiseLog"
              />
              <label for="userWiseLog">User Wise Log</label>
            </div>

            <div className="col-sm-3">
              <input
                checked={selected == "mobileWiseLog"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="mobileWiseLog"
                name="radioButton"
                value="mobileWiseLog"
              />
              <label for="mobileWiseLog">Mobile No. Wise Log</label>
            </div>
          </div>

          {overAllLog ? (
            <div className="row mt-6">
              <div className="col-md-3">
                <label className="form-control-label">
                  <b>Select Start and End Date</b>{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>
                <RangePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  style={{ height: "40px", width: "100%" }}
                  onChange={handlePickerChnage}
                />
              </div>

              <div className="col-md-2">
                <button
                  disabled={loading}
                  className="btn btn-primary mx-2 mt-7"
                  onClick={getSMSLog}
                >
                  Get SMS Log
                </button>
              </div>
              {reports.length ? (
                <div className="col text-end mt-9 pl-3">
                  <h5>Total Messages: {reports.length}</h5>
                  <h5>Total Messages Character Wise: {messageCount}</h5>
                </div>
              ) : null}
            </div>
          ) : null}

          {userWiseLog ? (
            <div className="row mt-6">
              <div className="col-md-3">
                <label className="form-control-label">
                  <b>Select Start and End Date</b>{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>
                <RangePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  style={{ height: "40px", width: "100%" }}
                  onChange={handlePickerChnage}
                />
              </div>

              <div className="col-md-3">
                <label className="form-control-label">
                  <b>Select User Type</b>{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>
                <div className="d-flex flex-column ">
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    name="year_id"
                    value={userName}
                    aria-label="Default select example"
                    onChange={(e) => setUserName(e.target.value)}
                  >
                    <option value="">Select User Type</option>
                    {roleList &&
                      roleList.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="col-md-2">
                <button
                  disabled={loading}
                  className="btn btn-primary mx-2 mt-7"
                  onClick={getSMSLog}
                >
                  Get SMS Log
                </button>
              </div>
              {reports.length ? (
                <div className="col text-end mt-9 pl-3">
                  <h5>Total Messages: {reports.length}</h5>
                  <h5>Total Messages Character Wise: {messageCount}</h5>
                </div>
              ) : null}
            </div>
          ) : null}

          {mobileWiseLog ? (
            <div className="row mt-6">
              <div className="col-md-3">
                <label className="form-control-label">
                  <b>Select Start and End Date</b>{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>
                <RangePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  style={{ height: "40px", width: "100%" }}
                  onChange={handlePickerChnage}
                />
              </div>

              <div className="col-md-3">
                <label className="form-control-label">
                  <b>Enter Mobile Number</b>{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>

                <input
                  onChange={(e) => setMobileNo(e.target.value)}
                  type="number"
                  placeholder="Enter Mobile Number"
                  className="form-control"
                  value={mobileNo}
                />
              </div>

              <div className="col-md-2">
                <button
                  disabled={loading}
                  className="btn btn-primary mx-2 mt-7"
                  onClick={getSMSLog}
                >
                  Get SMS Log
                </button>
              </div>
              {reports.length ? (
                <div className="col text-end mt-9 pl-3">
                  <h5>Total Messages: {reports.length}</h5>
                  <h5>Total Messages Character Wise: {messageCount}</h5>
                </div>
              ) : null}
            </div>
          ) : null}
        </form>
        <Divider />

        {isVisible && (
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th className="text-center">Sr.No.</th>
                <th className="text-center">User</th>
                <th className="text-center">Time</th>
                <th className="text-center">Mobile No.</th>
                <th className="text-start">Message</th>
              </tr>
            </thead>

            <tbody>
              {_DATA.currentData().map((reportData, index) => {
                return (
                  // key={index}
                  <tr key={index}>
                    <td className="text-center">{reportData?.SrNo}</td>
                    <td className="text-center">{reportData?.user}</td>
                    <td className="text-center">{reportData?.date_time}</td>
                    <td className="text-center">{reportData?.number}</td>
                    <td className="text-start">{reportData?.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Pagination
          color="primary"
          count={count}
          size="large"
          page={page}
          variant="text"
          shape="round"
          onChange={handleChange}
        />

        {/* <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <>
                <Pagination
                  updatingQueryParams={updatingQueryParams}
                  onPaginationSelectChange={onPaginationSelectChange}
                  isLoading={loading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    keyField="id"
                    data={reports}
                    columns={columns}
                    defaultSorted={uiHelpers.defaultSorted}
                    {...paginationTableProps}
                  ></BootstrapTable>
                </Pagination>
              </>
            );
          }}
        </PaginationProvider> */}
      </Paper>
    </>
  );
};

export default LogReport;
