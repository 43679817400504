import { Paper } from "@material-ui/core";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import { DatePicker, Divider } from "antd";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from "react-export-table-to-excel";
const { RangePicker } = DatePicker;
const FeeSummary = () => {
  const [selected, setSelected] = useState("all");
  let authtoken = localStorage.getItem("userToken");
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [reportData, setReportData] = useState([]);
  const [campusId, setCampusId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  const [sessionName, setSessionName] = useState("");
  const [campusName, setCampusName] = useState("");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const pageStyle = `
  @page {
    size: landscape !important ;
}
`;

  useEffect(() => {
    getSettingData();
    sessions();
    // getAllCampus();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
            setSessionName(session.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const datePicker = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    setStartDate(dateString[0]);
    setendDate(dateString[1]);
  };

  const handleGetData = () => {
    if (yearId == "") {
      alert.error("Please select the year");
      return;
    }

    if (startDate == "") {
      alert.error("Please select the start date");
      return;
    }
    if (endDate == "") {
      alert.error("Please select the end date");
      return;
    }

    setLoading(true);
    setIsVisible(false);
    const data = {
      year_id: yearId,
      campus_id: campusId,
      from_date: startDate,
      to_date: endDate,
    };
    fetch(`${baseUrl}/class_wise_fee_summary`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setIsVisible(true);
        if (data.payload) {
          console.log(data);
          setReportData(data.payload.fee_summary);
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsVisible(false);
        console.log("err is re", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => {
                  if (e.target.value != "") {
                    const selectedObject = campusData.find(
                      (option) => option.id == e.target.value
                    );
                    setCampusName(selectedObject.name);
                  } else {
                    setCampusName("");
                  }
                  setCampusId(e.target.value);
                }}
              >
                <option value=""> Select Campus</option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Start & End Date</b>
            </label>
            <RangePicker
              onChange={datePicker}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              style={{ height: "40px", width: "100%" }}
            />
          </div>

          <div className="col-md-3 mt-9 d-flex">
            <div>
              <button className="btn btn-primary mr-4" onClick={handleGetData}>
                Show
              </button>
            </div>
            {reportData.length > 0 && (
              <div>
                <ReactToPrint
                  documentTitle={`Fee Summary`}
                  pageStyle={pageStyle}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-100px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <DownloadTableExcel
                  filename="Fee Summary"
                  sheet="Fee Summary"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-primary mx-2">Export</button>
                </DownloadTableExcel>
              </div>
            )}
          </div>
        </div>

        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <>
              {isVisible ? (
                <div id="tableboot">
                  <div className="p-3" style={{ display: "flex" }}>
                    <img
                      // className='col-md-2'
                      style={{ height: 100, width: 100 }}
                      src={icon}
                    />
                    <div className="col-md-10" style={{ paddingTop: "10px" }}>
                      <h1>{unitName}</h1>
                      <h4>
                        Student Fee Received Summary For Session{" "}
                        {sessionName != "" ? " " + sessionName + " " : null}{" "}
                        {startDate != "" && endDate != ""
                          ? " From " + startDate + " To " + endDate
                          : null}
                        {campusName != "" ? " ( " + campusName + " ) " : null}{" "}
                      </h4>
                    </div>
                  </div>
                  <Table responsive>
                    <thead>
                      <tr id="tbl" className="text-center text-black">
                        <th>CLASS</th>
                        <th>ADMISSION Fee</th>
                        <th>ANNUAL Fund</th>
                        <th>DUPLICATE FEE BILL CHARGES</th>
                        <th>HOSTEL Fee</th>
                        <th>MONTHLY Fee</th>
                        <th>OTHERS Fine</th>
                        <th>PROSPECTUS</th>
                        <th>RE-Admission Fee</th>
                        <th>REGISTRATION</th>
                        <th>SECOND SHIFT STUDY</th>
                        <th>SPORTS CHARGES</th>
                        <th
                          className="text-center"
                          style={{
                            border: "1px solid black",
                          }}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {reportData &&
                        reportData.map(
                          (item, index) =>
                            item?.total !== 0 && ( // Check if item.total is not 0
                              <tr id="tbl" key={index}>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {item?.name}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.admission_fee
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.annual_fund
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.duplicate_fee_bill_charges
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.hostel_fee
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.monthly_fee
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.others_fine
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.prospectus
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.re_admission_fee
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.registration
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.second_shift_study
                                  )}
                                </td>
                                <td style={{ color: "black" }}>
                                  {Intl.NumberFormat("en-Us").format(
                                    item?.sports_charges
                                  )}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    borderLeft: "1px solid black",
                                    borderRight: "1px solid black",
                                    backgroundColor: "beige",
                                  }}
                                >
                                  <b>
                                    {" "}
                                    {Intl.NumberFormat("en-Us").format(
                                      item?.total
                                    )}
                                  </b>
                                </td>
                              </tr>
                            )
                        )}

                      <tr id="tbl">
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                          className="text_align_start"
                        >
                          <b>Total</b>
                        </td>

                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.admission_fee),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.annual_fund),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) =>
                                  a + parseInt(c.duplicate_fee_bill_charges),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.hostel_fee),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.monthly_fee),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.others_fine),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.prospectus),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.re_admission_fee),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.registration),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.second_shift_study),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.sports_charges),
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            color: "black",
                            borderLeft: "1px solid black",
                            borderRight: "1px solid black",
                            backgroundColor: "beige",
                          }}
                        >
                          <b>
                            {Intl.NumberFormat("en-Us").format(
                              reportData.reduce(
                                (a, c) => a + parseInt(c.total),
                                0
                              )
                            )}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : null}
            </>
          )}
        </div>
        {/* For Print */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="p-3" style={{ display: "flex" }}>
            <img
              // className='col-md-2'
              style={{ height: 100, width: 100 }}
              src={icon}
            />
            <div className="col-md-8" style={{ paddingTop: "10px" }}>
              <h1>{unitName}</h1>
              <h4>
                Student Fee Received Summary For Session{" "}
                {sessionName != "" ? " " + sessionName + " " : null}{" "}
                {startDate != "" && endDate != ""
                  ? " From " + startDate + " To " + endDate
                  : null}
                {campusName != "" ? " ( " + campusName + " ) " : null}{" "}
              </h4>
            </div>
          </div>

          <div id="tableboot">
            <Table>
              <thead>
                <tr id="tbl">
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    CLASS
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    ADMISSION Fee
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    ANNUAL Fund
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    DUPLICATE FEE BILL CHARGES
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    HOSTEL Fee
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    MONTHLY Fee
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    OTHERS Fine
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    PROSPECTUS
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    RE-Admission Fee
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    REGISTRATION
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    SECOND SHIFT STUDY
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    SPORTS CHARGES
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    TOTAL
                  </th>
                </tr>
              </thead>

              <tbody>
                {reportData &&
                  reportData.map(
                    (item, index) =>
                      item?.total !== 0 && ( // Check if item.total is not 0
                        <tr id="tbl" style={{ fontSize: "16px" }}>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {item?.name}
                          </td>

                          <td style={{ color: "black" }}>
                            {item?.admission_fee}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.annual_fund
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.duplicate_fee_bill_charges
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {" "}
                            {Intl.NumberFormat("en-Us").format(
                              item?.hostel_fee
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.monthly_fee
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.others_fine
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {" "}
                            {Intl.NumberFormat("en-Us").format(
                              item?.prospectus
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.re_admission_fee
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.registration
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.second_shift_study
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.sports_charges
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            <b>
                              {" "}
                              {Intl.NumberFormat("en-Us").format(item?.total)}
                            </b>
                          </td>
                        </tr>
                      )
                  )}
                <tr
                  id="tbl"
                  style={{
                    fontSize: "18px",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                  }}
                >
                  <td style={{ color: "black" }} className="text_align_start">
                    <b>Total</b>
                  </td>

                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.admission_fee),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.annual_fund),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.duplicate_fee_bill_charges),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.hostel_fee),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.monthly_fee),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.others_fine),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.prospectus),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.re_admission_fee),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.registration),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.second_shift_study),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.sports_charges),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce((a, c) => a + parseInt(c.total), 0)
                      )}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        {/* For Export */}
        <div
          ref={tableRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="p-3" style={{ display: "flex" }}>
            <div className="col-md-8" style={{ paddingTop: "10px" }}>
              <h1>{unitName}</h1>
              <h4>
                Student Fee Received Summary For Session{" "}
                {sessionName != "" ? " " + sessionName + " " : null}{" "}
                {startDate != "" && endDate != ""
                  ? " From " + startDate + " To " + endDate
                  : null}
                {campusName != "" ? " ( " + campusName + " ) " : null}{" "}
              </h4>
            </div>
          </div>

          <div id="tableboot">
            <Table>
              <thead>
                <tr id="tbl">
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    CLASS
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    ADMISSION Fee
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    ANNUAL Fund
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    DUPLICATE FEE BILL CHARGES
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    HOSTEL Fee
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    MONTHLY Fee
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    OTHERS Fine
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    PROSPECTUS
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    RE-Admission Fee
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    REGISTRATION
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    SECOND SHIFT STUDY
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    SPORTS CHARGES
                  </th>
                  <th
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    TOTAL
                  </th>
                </tr>
              </thead>

              <tbody>
                {reportData &&
                  reportData.map(
                    (item, index) =>
                      item?.total !== 0 && ( // Check if item.total is not 0
                        <tr id="tbl" style={{ fontSize: "16px" }}>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {item?.name}
                          </td>

                          <td style={{ color: "black" }}>
                            {item?.admission_fee}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.annual_fund
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.duplicate_fee_bill_charges
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {" "}
                            {Intl.NumberFormat("en-Us").format(
                              item?.hostel_fee
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.monthly_fee
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.others_fine
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {" "}
                            {Intl.NumberFormat("en-Us").format(
                              item?.prospectus
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.re_admission_fee
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.registration
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.second_shift_study
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            {Intl.NumberFormat("en-Us").format(
                              item?.sports_charges
                            )}
                          </td>
                          <td style={{ color: "black" }}>
                            <b>
                              {" "}
                              {Intl.NumberFormat("en-Us").format(item?.total)}
                            </b>
                          </td>
                        </tr>
                      )
                  )}
                <tr
                  id="tbl"
                  style={{
                    fontSize: "18px",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                  }}
                >
                  <td style={{ color: "black" }} className="text_align_start">
                    <b>Total</b>
                  </td>

                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.admission_fee),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.annual_fund),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.duplicate_fee_bill_charges),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.hostel_fee),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.monthly_fee),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.others_fine),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.prospectus),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.re_admission_fee),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.registration),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.second_shift_study),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce(
                          (a, c) => a + parseInt(c.sports_charges),
                          0
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData.reduce((a, c) => a + parseInt(c.total), 0)
                      )}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default FeeSummary;
