import React from "react";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useAlert } from "react-alert";
import { Spinner, Table, Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { baseUrl } from "../../../services/config";
import { DatePicker, Space } from "antd";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from "react-export-table-to-excel";
const { RangePicker } = DatePicker;

const pageStyle = `
  @page {
    size: landscape !important;
}
`;
const AttendanceSummary = () => {
  const user = useSelector((state) => state.user.user);
  const [allsessions, setallsessions] = useState([]);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [viewModal, setViewModal] = useState(false);
  const [classesData, setClassesData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [campusId, setCampusId] = useState("");
  const [campusName, setCampusName] = useState("");
  const [className, setClassName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [classId, setClassId] = useState("");
  const [studentId, setStudentId] = useState("");
  const [monthName, setMonthName] = useState("");
  const [monthDays, setMonthDays] = useState([]);
  const [monthYear, setMonthYear] = useState("");
  const [studentsData, setStudentsData] = useState([]);
  const [studentAttData, setStudentAttData] = useState(0);
  const [studentAttData1, setStudentAttData1] = useState([]);
  const [singleStdData, setSingleStdData] = useState([]);
  const [singleStd, setSingleStd] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setloading] = useState(false);
  const [startMonth, setStartMonth] = useState([]);
  const [endMonth, setEndMonth] = useState([]);
  const [educationType, setEducationType] = useState(1);
  const [currentsession, setcurrentsession] = useState("");
  let authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [subPrintloading, setSubPrintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const componentRef1 = useRef(null);
  const tableRef = useRef(null);
  const tableSubRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    getAllSessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChanger = (day, date) => {
    const tempDate = new Date(date[1]);
    setMonthYear(tempDate.getFullYear());
    const monthDays = new Date(
      tempDate.getFullYear(),
      tempDate.getMonth() + 1,
      0
    ).getDate();
    let startDate = date[0] + "-01";
    let endDate = date[1] + "-" + monthDays;
    setStartMonth(startDate);
    setEndMonth(endDate);
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${campusId}/${id}${
        classId ? "/" + classId : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          setSectionData(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const onChampusChange = (id) => {
    setCampusId(id);
    setStudentId("");
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setClassesData(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    setClassId(id);
    setStudentId("");
    fetch(`${baseUrl}/class_section/${campusId}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSectionData(data.payload);
      })
      .catch((err) => {
        console.log("", err);
      });
  };

  const handleShow = async () => {
    if (startMonth == "" && endMonth == "") {
      return alert.error("Month is required ");
    }

    if (studentId == "" && campusId == "") {
      setCampusId("");
      return alert.error("Campus is required ");
    }
    
    if (studentId == "" && classId == "" && sectionId == "") {
      setClassId("");
      setSectionId("");
      return alert.error("Class is required ");
    }

    if (studentId == "" && sectionId == "") {
      setClassId("");
      setSectionId("");
      return alert.error("Section is required ");
    }
   
    setloading(true);
    let data = {};
    if (studentId == "") {
      data = {
        year_id: currentsession,
        campus_id: campusId,
        student_class_id: classId,
        global_section_id: sectionId,
        start_date: startMonth,
        end_date: endMonth,
        education_type: educationType,
        admission_id: studentId,
      };
    } else {
      data = {
        start_date: startMonth,
        end_date: endMonth,
        admission_id: studentId,
      };
    }

    await fetch(`${baseUrl}/monthly-attendance-details`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setStudentsData(data.payload);
            setloading(false);
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const handleViewDetail = async (value) => {
    let data = {
      year_id: currentsession,
      start_date: startMonth,
      end_date: endMonth,
      admission_id: value,
    };

    await fetch(`${baseUrl}/single-student-attendance-details`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setSingleStdData(data.payload.student_data);
        setAttendanceData(data.payload.attendance_data);
      })
      .catch((err) => {
        console.log(err);
      });
    setViewModal(true);
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);
  
  const handleOnBeforeGetSubContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setSubPrintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setSubPrintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setSubPrintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3 pb-4">
            <label className="form-control-label">
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => setcurrentsession(e.target.value)}
              className="form-control form-select h-40px"
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option key={index} value={session.id}>
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3 pb-4">
            <label className="form-control-label">
              <b>Select Month</b>
            </label>
            <RangePicker
              picker="month"
              onChange={(day, date) => handleChanger(day, date)}
              className="h-40px"
            />
          </div>

          <div className="col-md-3 pb-4">
            <label className="form-control-label">
              <b>Select Campus</b>
            </label>
            <select
              className="form-control form-select"
              name="campus_id"
              aria-label="Default select example"
              onChange={(e) => {
                if (e.target.value != "") {
                  const campus = campusData.find((item) => {
                    return item.id == e.target.value;
                  });
                  setCampusName(campus.name);
                } else {
                  setCampusName("");
                }
                onChampusChange(e.target.value);
              }}
            >
              <option value="">Select Campus</option>
              {campusData &&
                campusData.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>

          <div className="col-md-3 pb-4">
            <label className="form-control-label">
              <b>Select Class</b>
            </label>
            <select
              id="classselect"
              className="form-control form-select h-40px"
              name="class_id"
              aria-label="Default select example"
              onChange={(e) => {
                if (e.target.value != "") {
                  const className = classesData.find((item) => {
                    return item.id == e.target.value;
                  });
                  setClassName(className.name);
                } else {
                  setClassName("");
                }
                onClassChange(e.target.value);
              }}
            >
              <option value="">Select Class</option>
              {classesData &&
                classesData.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>

          <div className="col-md-3 pb-4">
            <label className="form-control-label">
              <b>Select Education Type</b>
            </label>
            <select
              className="form-control form-select h-40px"
              name="education_type_id"
              value={educationType}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>

          <div className="col-md-3 pb-4">
            <label className="form-control-label">
              <b>Select Section</b>
            </label>
            <select
              id="sectionselect"
              className="form-select form-control h-40px"
              name="campus_id"
              aria-label="Default select example"
              onChange={(e) => {
                if (e.target.value != "") {
                  const sectionName = sectionData.find((item) => {
                    return item.id == e.target.value;
                  });
                  setSectionName(sectionName.name);
                } else {
                  setSectionName("");
                }
                setSectionId(e.target.value);
              }}
            >
              <option value="">Select Section</option>

              {sectionData &&
                sectionData.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>

          <div className="col-md-6">
            <label className="form-control-label">
              <b>Student ID</b>
            </label>
            <input
              type="text"
              id="sid"
              onChange={(e) => setStudentId(e.target.value)}
              className="form-control"
              placeholder="Enter Student ID"
            />
          </div>

          <div className="col-md-4 mt-5">
            <button className="btn btn-primary" onClick={handleShow}>
              Search
            </button>

            {studentsData?.length > 0 ? (
              <>
              <ReactToPrint
                documentTitle={`Attendance Summary Detail Report`}
                onAfterPrint={handleAfterPrint}
                pageStyle={pageStyle}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-primary w-105px mx-2"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />

              <DownloadTableExcel
                  filename="Attendance Summary Detail Report"
                  sheet="Attendance Summary Detail Report"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-primary mx-2"> Export </button>
                </DownloadTableExcel>
                </>
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div className="mt-5">
            {studentsData.length > 0 ? (
              <>
                <div className="row p-3">
                  <img style={{ width: 100, height: 100 }} src={icon} />

                  <div className="col-md-10" style={{ lineHeight: 1.5 }}>
                    <h1>{unitName}</h1>
                    <h4>
                      {studentId
                        ? `Attendance Summary Details of ${
                            studentsData[0]?.campus
                          } ${studentsData[0]?.class} (
                      ${studentsData[0]?.section}) From ${getMonthName(
                            startMonth
                          )} To ${getMonthName(endMonth)}`
                        : `Attendance Summary Details of ${campusName} ${className} (
                        ${sectionName}) From ${getMonthName(
                            startMonth
                          )} To ${getMonthName(endMonth)}`}
                    </h4>
                  </div>
                </div>
                <Table responsive>
                  <thead>
                    <tr>
                      <td>Sr</td>
                      <td>Adm-Id</td>
                      <td>Student Name</td>
                      <td>Absent</td>
                      <td>Sick</td>
                      <td>Leave</td>
                      <td>Late Coming</td>
                      <td>Home Work Not Done</td>
                      <td>Improper uniform</td>
                      <td>Test not prepared</td>
                      <td>View</td>
                    </tr>
                  </thead>

                  <tbody>
                    {studentsData.map((item, index) => (
                      <tr className="text-center" key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.admission_id}</td>
                        <td className="text-start">{item?.name}</td>
                        <td>{item?.attendance_counts?.absent ?? 0}</td>
                        <td>{item?.attendance_counts?.sick ?? 0}</td>
                        <td>{item?.attendance_counts?.leave ?? 0}</td>
                        <td>{item?.attendance_counts?.late_coming ?? 0}</td>
                        <td>
                          {item?.attendance_counts?.home_work_not_done ?? 0}
                        </td>
                        <td>
                          {item?.attendance_counts?.improper_uniform ?? 0}
                        </td>
                        <td>
                          {item?.attendance_counts?.test_not_prepared ?? 0}
                        </td>
                        <td>
                          <a
                            style={{}}
                            className="btn btn-icon btn-light btn-hover-primary btn-sm "
                            onClick={(e) => {
                              handleViewDetail(item?.admission_id);
                              setSingleStd(item);
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Devices/Display2.svg"
                                )}
                              />
                            </span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : null}
          </div>
        )}

        <Modal show={viewModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Student Attendance Details ({getMonthName(startMonth)} To{" "}
              {getMonthName(endMonth)})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card mt-5 mb-5">
              <div className="flex-column d-flex pt-3">
                <h5 className="text-primary text-center mb-6">
                  Student Details:
                </h5>
                <div
                  className="mb-4 pb-4"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <span className="row text-black" style={{ fontSize: "14px" }}>
                    <span className="col-md-6">
                      <b>Admission ID:</b>&nbsp; &nbsp;
                      {singleStdData?.admission_id}
                    </span>

                    <span className="col-md-6">
                      <b style={{ width: "115px" }}>Student Name:</b>&nbsp;
                      &nbsp;{singleStdData?.name}
                    </span>
                  </span>
                  <span className="row text-black" style={{ fontSize: "14px" }}>
                    <span className="col-md-6">
                      <b style={{ width: "115px" }}>Campus:</b>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {singleStdData?.campus?.name}
                    </span>

                    <span className="col-md-6">
                      {" "}
                      <b style={{ width: "115px" }}>Class:</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {singleStdData?.student_class?.name}-
                      {singleStdData?.global_section?.name}
                    </span>
                  </span>
                </div>

                <h5 className="text-primary text-center mt-4 mb-6">
                  Attendance Totals:
                </h5>
                <div
                  className="pb-4"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <p className="row text-black" style={{ fontSize: "14px" }}>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Absents:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.absent ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Sicks:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.sick ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Leaves:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.leave ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Late Coming:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.late_coming ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Home Work Not Done:</b>
                      &nbsp;&nbsp;
                      {singleStd?.attendance_counts?.home_work_not_done ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Improper Uniform:</b>
                      &nbsp;&nbsp;
                      {singleStd?.attendance_counts?.improper_uniform ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Test Not Prepared:</b>
                      &nbsp;&nbsp;
                      {singleStd?.attendance_counts?.test_not_prepared ?? 0}
                    </span>
                  </p>
                </div>

                <h5 className="text-primary text-center mt-6">
                  Attendance Details:
                </h5>
                <table responsive>
                  <thead>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          width: "35px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Date</b>
                      </td>
                      <td
                        style={{
                          color: "black",
                          width: "35px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Type</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceData.map((item, index) => (
                      <tr>
                        <td
                          style={{
                            color: "black",
                            width: "35px",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {item.date}
                        </td>
                        <td
                          style={{
                            color: "black",
                            width: "35px",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {item.status_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex mt-11 justify-content-end">
                  <ReactToPrint
                    documentTitle={`Student Attendance Summary Detail Report`}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetSubContent}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint={true}
                    pageStyle={"auto"}
                    trigger={() => (
                      <button
                        disabled={subPrintloading ? true : false}
                        className="btn btn-primary w-105px mx-2"
                      >
                        {subPrintloading ? <span>Printing..</span> : "Print"}
                      </button>
                    )}
                    content={() => componentRef1.current}
                  />
                  <DownloadTableExcel
                  filename="Student Attendance Summary Detail Report"
                  sheet="Student Attendance Summary Detail Report"
                  currentTableRef={tableSubRef.current}
                >
                  <button className="btn btn-primary mx-2"> Export </button>
                </DownloadTableExcel>
                  <button
                    className="btn btn-danger mx-2"
                    onClick={() => {
                      setViewModal(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ///for print */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
            <div className="p-3" style={{ display: "flex" }}>
              <img style={{ height: 110, width: 120 }} src={icon} />

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h4>
                  {studentId
                    ? `Attendance Summary Details of ${
                        studentsData[0]?.campus
                      } ${studentsData[0]?.class} (
                      ${studentsData[0]?.section}) From ${getMonthName(
                        startMonth
                      )} To ${getMonthName(endMonth)}`
                    : `Attendance Summary Details of ${campusName} ${className} (
                        ${sectionName}) From ${getMonthName(
                        startMonth
                      )} To ${getMonthName(endMonth)}`}
                </h4>
              </div>
            </div>
            <div id="tableboot">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Sr
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Adm-Id
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Student Name
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Absent
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Sick
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Leave
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Late
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      H/W
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      I/uniform
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Test
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {studentsData.map((item, index) => (
                    <tr id="tbl">
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.admission_id}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "start",
                          paddingLeft: "10px",
                        }}
                      >
                        {item?.name}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.absent ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.sick ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.leave ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.late_coming ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.home_work_not_done ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.improper_uniform ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.test_not_prepared ?? 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        {/*sub print*/}
        <div
          ref={componentRef1}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <div>
            
          <div className="p-3" style={{ display: "flex" }}>
              <img style={{ height: 110, width: 120 }} src={icon} />

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h3>
                  {studentId
                    ? `Attendance Summary Details of ${
                        studentsData[0]?.campus
                      } ${studentsData[0]?.class} (
                      ${studentsData[0]?.section}) From ${getMonthName(
                        startMonth
                      )} To ${getMonthName(endMonth)}`
                    : `Attendance Summary Details of ${campusName} ${className} (
                        ${sectionName}) From ${getMonthName(
                        startMonth
                      )} To ${getMonthName(endMonth)}`}
                </h3>
              </div>
            </div>
                {/* <div style={{ marginLeft: "40px" }}> */}
                <div className="form-group flex-column d-flex pt-3">
                <span className="text-primary text-center mb-6" style={{fontSize: "20px"}}>
                  <b>Student Details:</b>
                </span>
                <div
                  className="mb-4 pb-4"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <span className="d-flex text-black" style={{ fontSize: "18px" }}>
                    <span className="col-md-6">
                      <b>Admission ID:</b>&nbsp; &nbsp;
                      {singleStdData?.admission_id}
                    </span>

                    <span className="col-md-6">
                      <b style={{ width: "115px" }}>Student Name:</b>&nbsp;
                      &nbsp;{singleStdData?.name}
                    </span>
                  </span>
                  <span className="d-flex text-black" style={{ fontSize: "18px" }}>
                    <span className="col-md-6">
                      <b style={{ width: "115px" }}>Campus:</b>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {singleStdData?.campus?.name}
                    </span>

                    <span className="col-md-6">
                      {" "}
                      <b style={{ width: "115px" }}>Class:</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {singleStdData?.student_class?.name}-
                      {singleStdData?.global_section?.name}
                    </span>
                  </span>
                </div>

                <span className="text-primary text-center mt-4 mb-6" style={{fontSize: "20px"}}>
                <b> Attendance Totals:</b>
                </span>
                <div
                  className="pb-4"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <span className="d-flex text-black" style={{ fontSize: "18px" }}>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Absents:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.absent ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Sicks:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.sick ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Leaves:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.leave ?? 0}
                    </span>
                  </span>

                  <span className="d-flex text-black" style={{ fontSize: "18px" }}>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Late Coming:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.late_coming ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Home Work Not Done:</b>
                      &nbsp;&nbsp;
                      {singleStd?.attendance_counts?.home_work_not_done ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Improper Uniform:</b>
                      &nbsp;&nbsp;
                      {singleStd?.attendance_counts?.improper_uniform ?? 0}
                    </span>
                  </span>

                  <span className="d-flex text-black" style={{ fontSize: "18px" }}>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Test Not Prepared:</b>
                      &nbsp;&nbsp;
                      {singleStd?.attendance_counts?.test_not_prepared ?? 0}
                    </span>
                  </span>
                </div>

                <span className="text-primary text-center mt-6" style={{fontSize: "20px"}}>
                <b>Attendance Details:</b>
                </span>
                <table responsive style={{marginLeft: "100px", marginRight: "100px",}}>
                  <thead>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        <b>Date</b>
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        <b>Type</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceData.map((item, index) => (
                      <tr>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          {item.date}
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          {item.status_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
                {/* </div> */}
          </div>
        </div>

         {/* ///for export */}
         <div
          ref={tableRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
            <div className="p-3" style={{ display: "flex" }}>

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h4>
                  {studentId
                    ? `Attendance Summary Details of ${
                        studentsData[0]?.campus
                      } ${studentsData[0]?.class} (
                      ${studentsData[0]?.section}) From ${getMonthName(
                        startMonth
                      )} To ${getMonthName(endMonth)}`
                    : `Attendance Summary Details of ${campusName} ${className} (
                        ${sectionName}) From ${getMonthName(
                        startMonth
                      )} To ${getMonthName(endMonth)}`}
                </h4>
              </div>
            </div>
            <div id="tableboot">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Sr
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Adm-Id
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Student Name
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Absent
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Sick
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Leave
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Late
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      H/W
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      I/uniform
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Test
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {studentsData.map((item, index) => (
                    <tr id="tbl">
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.admission_id}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "start",
                          paddingLeft: "10px",
                        }}
                      >
                        {item?.name}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.absent ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.sick ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.leave ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.late_coming ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.home_work_not_done ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.improper_uniform ?? 0}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item?.attendance_counts?.test_not_prepared ?? 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        {/*sub export*/}
        <div
          ref={tableSubRef}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <div>
            
          <div className="p-3" style={{ display: "flex" }}>
              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h3>
                  {studentId
                    ? `Attendance Summary Details of ${
                        studentsData[0]?.campus
                      } ${studentsData[0]?.class} (
                      ${studentsData[0]?.section}) From ${getMonthName(
                        startMonth
                      )} To ${getMonthName(endMonth)}`
                    : `Attendance Summary Details of ${campusName} ${className} (
                        ${sectionName}) From ${getMonthName(
                        startMonth
                      )} To ${getMonthName(endMonth)}`}
                </h3>
              </div>
            </div>
                {/* <div style={{ marginLeft: "40px" }}> */}
                <div className="form-group flex-column d-flex pt-3">
                <span className="text-primary text-center mb-6" style={{fontSize: "20px"}}>
                  <b>Student Details:</b>
                </span>
                <div
                  className="mb-4 pb-4"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <span className="text-black" style={{ fontSize: "18px" }}>
                    <span className="col-md-6">
                      <b>Admission ID:</b>&nbsp; &nbsp;
                      {singleStdData?.admission_id}
                    </span>

                    <span className="col-md-6">
                      <b style={{ width: "115px" }}>Student Name:</b>&nbsp;
                      &nbsp;{singleStdData?.name}
                    </span>

                    <span className="col-md-6">
                      <b style={{ width: "115px" }}>Campus:</b>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {singleStdData?.campus?.name}
                    </span>

                    <span className="col-md-6">
                      {" "}
                      <b style={{ width: "115px" }}>Class:</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {singleStdData?.student_class?.name}-
                      {singleStdData?.global_section?.name}
                    </span>
                  </span>
                </div>

                <span className="text-primary text-center mt-4 mb-6" style={{fontSize: "20px"}}>
                <b> Attendance Totals:</b>
                </span>
                <div
                  className="pb-4"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <span className="text-black" style={{ fontSize: "18px" }}>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Absents:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.absent ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Sicks:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.sick ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Leaves:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.leave ?? 0}
                    </span>

                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Late Coming:</b>&nbsp;&nbsp;
                      {singleStd?.attendance_counts?.late_coming ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Home Work Not Done:</b>
                      &nbsp;&nbsp;
                      {singleStd?.attendance_counts?.home_work_not_done ?? 0}
                    </span>
                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Improper Uniform:</b>
                      &nbsp;&nbsp;
                      {singleStd?.attendance_counts?.improper_uniform ?? 0}
                    </span>

                    <span className="col-md-4">
                      <b style={{ width: "100px" }}>Test Not Prepared:</b>
                      &nbsp;&nbsp;
                      {singleStd?.attendance_counts?.test_not_prepared ?? 0}
                    </span>
                  </span>
                </div>

                <span className="text-primary text-center mt-6" style={{fontSize: "20px"}}>
                <b>Attendance Details:</b>
                </span>
                <table responsive>
                  <thead>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        <b>Date</b>
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        <b>Type</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceData.map((item, index) => (
                      <tr>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          {item.date}
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          {item.status_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
                {/* </div> */}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default AttendanceSummary;
