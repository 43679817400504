import React from "react";
import "./DailyTransaction.css";
import { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Table,
  Modal,
  Spinner,
  Form,
  ModalFooter,
  Badge,
} from "react-bootstrap";
import "./DailyTransaction.css";
// import data from "./mokeData";
import { baseUrl } from "../../../services/config";
import mokeData from "./mokeData.json";
import ReactToPrint from "react-to-print";
import { useAlert } from "react-alert";
import { upperCase } from "lodash";
import { DownloadTableExcel } from "react-export-table-to-excel";

const DailyTransaction = () => {
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const [selected, setSelected] = useState("annual");
  const [debitSum, setDebitSum] = useState(0);
  const [creditSum, setCreditSum] = useState(0);
  const [selectedItem, setSelectedItem] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [reportData, setReportData] = useState([]);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const alert = useAlert();
  let authtoken = localStorage.getItem("userToken");

  const [sessionName, setSessionName] = useState("");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    sessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
            setSessionName(session.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onshowClick = () => {
    if (month == "" && selected == "monthly") {
      return alert.error("Please Select the Month");
    }

    if (date == "" && selected == "daily") {
      return alert.error("Please Select the date");
    }
    const data = {
      year_id: yearId,
      date: date != "" ? date : month != "" ? month + "-01" : currentDate,
      annually: selected == "annual" ? 1 : 0,
      monthly: selected == "monthly" ? 1 : 0,
      daily: selected == "daily" ? 1 : 0,
    };
    setIsVisible(false);
    setLoading(true);

    fetch(`${baseUrl}/transaction_reports`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    }).then((resp) => {
      resp
        .json()
        .then((data) => {
          setLoading(false);
          setIsVisible(true);
          if (data.metadata) {
            if (data.metadata.success) {
              if (data.payload) {
                setDebitSum(data?.payload?.total?.all_debits);
                setCreditSum(data?.payload?.total?.all_credits);
                setReportData(data?.payload?.all_data);
                setShowTable(true);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          alert.error("Something wents wronge");
          setLoading(false);
        });
    });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const getMonthName = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}, ${d.getFullYear()}`;
  };

  const getMonthDate = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}, ${d.getDate()} ${d.getFullYear()}`;
  };

  const onRadioButtonClick = (e) => {
    setDate("");
    setMonth("");
    setReportData([]);
    setIsVisible(false);
    const value = e.target.value;
    setSelected(value);
  };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row p-3">
          <div className="col-sm-3">
            <input
              checked={selected == "annual"}
              onChange={(e) => onRadioButtonClick(e)}
              defaultChecked
              type="radio"
              id="annual"
              value="annual"
            />
            <label for="annual">Annually</label>
          </div>

          <div className="col-sm-3">
            <input
              checked={selected == "monthly"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="monthly"
              value="monthly"
            />
            <label for="monthly">Monthly</label>
          </div>

          <div className="col-sm-3">
            <input
              checked={selected == "daily"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="daily"
              value="daily"
            />
            <label for="daily">Daily</label>
          </div>
        </div>

        {selected == "annual" && (
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Select Session</b>
              </label>
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>

            <div className="col-md-4 mt-9">
              <button
                type="button"
                className="btn btn-primary"
                onClick={onshowClick}
              >
                Show
              </button>
              {showTable ? (
                <>
                  <ReactToPrint
                    documentTitle={`Daily Transaction`}
                    pageStyle="auto"
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContent}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint={true}
                    trigger={() => (
                      <button
                        disabled={printloading ? true : false}
                        className="btn btn-primary w-105px mx-2"
                      >
                        {printloading ? <span>Printing..</span> : "Print"}
                      </button>
                    )}
                    content={() => componentRef.current}
                  />

                  <DownloadTableExcel
                    filename="Daily Transaction Report"
                    sheet="Daily Transaction Report"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-primary mx-2"> Export </button>
                  </DownloadTableExcel>
                </>
              ) : null}
            </div>
          </div>
        )}
        {selected == "monthly" && (
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Select Session</b>
              </label>
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>

            <div className="col-md-3">
              <label>
                <b>Select Month</b>
              </label>
              <input
                className="form-control"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                type="month"
              />
            </div>

            <div className="col-md-4 mt-9">
              <button
                type="button"
                className="btn btn-primary"
                onClick={onshowClick}
              >
                Show
              </button>
              {showTable ? (
                <>
                  <ReactToPrint
                    documentTitle={`Daily Transaction`}
                    pageStyle="auto"
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContent}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint={true}
                    trigger={() => (
                      <button
                        disabled={printloading ? true : false}
                        className="btn btn-primary w-105px mx-2"
                      >
                        {printloading ? <span>Printing..</span> : "Print"}
                      </button>
                    )}
                    content={() => componentRef.current}
                  />

                  <DownloadTableExcel
                    filename="Daily Transaction Report"
                    sheet="Daily Transaction Report"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-primary mx-2"> Export </button>
                  </DownloadTableExcel>
                </>
              ) : null}
            </div>
          </div>
        )}
        {selected == "daily" && (
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Select Session</b>
              </label>
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>

            <div className="col-md-3">
              <label>
                <b>Select Date</b>
              </label>
              <input
                className="form-control"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                type="date"
              />
            </div>

            <div className="col-md-4 mt-9">
              <button
                type="button"
                className="btn btn-primary"
                onClick={onshowClick}
              >
                Show
              </button>
              {showTable ? (
                <>
                  <ReactToPrint
                    documentTitle={`Daily Transaction Report`}
                    pageStyle="auto"
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContent}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint={true}
                    trigger={() => (
                      <button
                        disabled={printloading ? true : false}
                        className="btn btn-primary w-105px mx-2"
                      >
                        {printloading ? <span>Printing..</span> : "Print"}
                      </button>
                    )}
                    content={() => componentRef.current}
                  />

                  <DownloadTableExcel
                    filename="Daily Transaction Report"
                    sheet="Daily Transaction Report"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-primary mx-2"> Export </button>
                  </DownloadTableExcel>
                </>
              ) : null}
            </div>
          </div>
        )}

        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : isVisible ? (
            <div className="mt-9">
              <div className="row">
                <div className="col-md-1 mr-8">
                  <img
                    style={{ height: 100, width: 110 }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h3>
                    {date != ""
                      ? "Daily"
                      : month != ""
                      ? "Monthly"
                      : "Annually"}{" "}
                    Transaction Report For Session {sessionName}
                    {date != ""
                      ? " ( " + getMonthDate(date) + " )"
                      : month != ""
                      ? " ( " + getMonthName(month) + " )"
                      : null}
                  </h3>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th>A/C Code</th>
                    <th>Account Name</th>
                    <th className="text-center">Debit</th>
                    <th className="text-center">Credit</th>
                  </tr>
                </thead>

                <tbody>
                  {reportData.map((accountGroups, accountGroups_index) =>
                    accountGroups?.account_groups.length > 0 ? (
                      <React.Fragment key={accountGroups_index}>
                        <tr id="tbl">
                          <th
                            colSpan={2}
                            className="text-center text-primary"
                            style={{ fontSize: "1.1rem" }}
                          >
                            {accountGroups?.account_name}
                          </th>
                          <th
                            className="text-center text-primary"
                            style={{ fontSize: "1.1rem" }}
                          >
                            {Intl.NumberFormat("en-Us").format(
                              accountGroups?.debit
                            )}
                          </th>
                          <th
                            className="text-center text-primary"
                            style={{ fontSize: "1.1rem" }}
                          >
                            {Intl.NumberFormat("en-Us").format(
                              accountGroups?.credit
                            )}
                          </th>
                        </tr>
                        {accountGroups?.account_groups.map(
                          (accountName, accountName_index) =>
                            accountName?.account_charts.length > 0 ? (
                              <React.Fragment key={accountName_index}>
                                <tr id="tbl">
                                  <td
                                    style={{
                                      color: "#fd12b3",
                                      fontWeight: "bold",
                                    }}
                                    className="text-start pl-10"
                                  >
                                    {accountName?.acode}
                                  </td>
                                  <td
                                    style={{
                                      color: "#fd12b3",
                                      fontWeight: "bold",
                                    }}
                                    className="text-start"
                                  >
                                    {accountName?.account_name}
                                  </td>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                    className="text-center text-primary"
                                  >
                                    {Intl.NumberFormat("en-Us").format(
                                      accountName?.debit
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                    className="text-center text-primary"
                                  >
                                    {Intl.NumberFormat("en-Us").format(
                                      accountName?.credit
                                    )}
                                  </td>
                                </tr>
                                {accountName.account_charts.map(
                                  (subitem, accountChart_index) =>
                                    subitem.sub_accounts.length > 0 ? (
                                      <React.Fragment key={accountChart_index}>
                                        <tr id="tbl">
                                          <td
                                            style={{
                                              color: "black",
                                              paddingLeft: "30px",
                                              fontWeight: "bold",
                                            }}
                                            className="text-center"
                                          >
                                            {subitem.acode}
                                          </td>
                                          <td
                                            style={{
                                              color: "black",
                                              paddingLeft: "30px",
                                              fontWeight: "bold",
                                            }}
                                            className="text-start"
                                          >
                                            {subitem.account_name}
                                          </td>
                                          <td
                                            style={{ color: "black" }}
                                            className="text-center"
                                          ></td>
                                        </tr>
                                        {subitem.sub_accounts.length > 0
                                          ? subitem.sub_accounts.map(
                                              (last, subAccoutIndex) => (
                                                <React.Fragment
                                                  key={subAccoutIndex}
                                                >
                                                  <tr id="tbl">
                                                    <td
                                                      style={{
                                                        color: "black",
                                                        paddingLeft: "60px",
                                                      }}
                                                      className="text-end"
                                                    >
                                                      {last.acode}
                                                    </td>
                                                    <td
                                                      style={{
                                                        color: "black",
                                                        paddingLeft: "60px",
                                                        cursor: "pointer",
                                                      }}
                                                      className="text-start"
                                                      onClick={() => {
                                                        setSelectedItem(last);
                                                        setViewModal(true);
                                                      }}
                                                    >
                                                      {last.account_name}
                                                    </td>

                                                    <td
                                                      style={{ color: "black" }}
                                                      className="text-center"
                                                    >
                                                      {Intl.NumberFormat(
                                                        "en-Us"
                                                      ).format(last.debit)}
                                                    </td>
                                                    <td
                                                      style={{ color: "black" }}
                                                      className="text-center"
                                                    >
                                                      {Intl.NumberFormat(
                                                        "en-Us"
                                                      ).format(last.credit)}
                                                    </td>
                                                  </tr>
                                                </React.Fragment>
                                              )
                                            )
                                          : null}
                                        <tr id="tbl">
                                          <td
                                            style={{
                                              color: "black",
                                              paddingLeft: "30px",
                                            }}
                                            className="text-start"
                                          ></td>
                                          <td
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            className="text-end pr-10"
                                          >
                                            Control Accounts Total :-
                                          </td>

                                          <td
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                              borderTop: "2px solid black",
                                              borderBottom: "2px solid black",
                                            }}
                                            className="text-center"
                                          >
                                            {Intl.NumberFormat("en-Us").format(
                                              subitem?.total_debit
                                            )}
                                          </td>

                                          <td
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                              borderTop: "2px solid black",
                                              borderBottom: "2px solid black",
                                            }}
                                            className="text-center"
                                          >
                                            {Intl.NumberFormat("en-Us").format(
                                              subitem?.total_credit
                                            )}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ) : null
                                )}
                              </React.Fragment>
                            ) : null
                        )}
                      </React.Fragment>
                    ) : null
                  )}

                  <tr id="tbl">
                    <td
                      style={{ color: "black", paddingLeft: "30px" }}
                      className="text-start"
                    ></td>
                    <td
                      style={{
                        fontSize: "1.3rem",
                        paddingRight: "50px",
                        fontWeight: "bold",
                      }}
                      className="text-end text-primary"
                    >
                      Grand Total :-
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {Intl.NumberFormat("en-Us").format(debitSum)}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {Intl.NumberFormat("en-Us").format(creditSum)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </Paper>

      {/* /////for print  */}
      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot mt-4">
          <div className="d-flex">
            <div className="">
              <img
                style={{ height: 100, width: 110, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="mx-5" style={{ paddingTop: "10px" }}>
              <h1>{unitName}</h1>
              <h3>
                {date != "" ? "Daily" : month != "" ? "Monthly" : "Annually"}{" "}
                Transaction Report For Session {sessionName}
                {date != ""
                  ? " ( " + getMonthDate(date) + " )"
                  : month != ""
                  ? " ( " + getMonthName(month) + " )"
                  : null}
              </h3>
            </div>
          </div>

          <Table responsive>
            <thead>
              <tr id="tbl">
                <th className="text-center" style={{ fontSize: "18px" }}>
                  A/C Code
                </th>
                <th className="text-start" style={{ fontSize: "18px" }}>
                  Account Name
                </th>
                <th className="text-center" style={{ fontSize: "18px" }}>
                  Debit
                </th>
                <th className="text-center" style={{ fontSize: "18px" }}>
                  Credit
                </th>
              </tr>
            </thead>

            <tbody>
              {reportData.map((accountGroups, accountGroups_index) =>
                accountGroups?.account_groups.length > 0 ? (
                  <React.Fragment key={accountGroups_index}>
                    <tr
                      id="tbl"
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <th
                        className="text-center text-primary"
                        style={{
                          fontSize: "20px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {accountGroups?.account_name}
                      </th>
                      <th></th>
                      <th
                        className="text-center text-primary"
                        style={{
                          fontSize: "20px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {Intl.NumberFormat("en-Us").format(
                          accountGroups?.debit
                        )}
                      </th>
                      <th
                        className="text-center text-primary"
                        style={{
                          fontSize: "20px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {Intl.NumberFormat("en-Us").format(
                          accountGroups?.credit
                        )}
                      </th>
                    </tr>
                    {accountGroups?.account_groups.map(
                      (accountName, accountName_index) =>
                        accountName?.account_charts.length > 0 ? (
                          <React.Fragment key={accountName_index}>
                            <tr
                              id="tbl"
                              style={{
                                fontSize: "18px",
                                whiteSpace: "normal",
                                padding: "1px",
                                borderTop: "1px solid black",
                                borderBottom: "1px solid black",
                              }}
                            >
                              <td
                                style={{
                                  color: "#fd12b3",
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                                className="text-start pl-10"
                              >
                                {accountName?.acode}
                              </td>
                              <td
                                style={{
                                  color: "#fd12b3",
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                                className="text-start"
                              >
                                {accountName?.account_name}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                                className="text-center text-primary"
                              >
                                {Intl.NumberFormat("en-Us").format(
                                  accountName?.debit
                                )}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                                className="text-center text-primary"
                              >
                                {Intl.NumberFormat("en-Us").format(
                                  accountName?.credit
                                )}
                              </td>
                            </tr>
                            {accountName.account_charts.map(
                              (subitem, accountChart_index) =>
                                subitem.sub_accounts.length > 0 ? (
                                  <React.Fragment key={accountChart_index}>
                                    <tr
                                      id="tbl"
                                      style={{
                                        fontSize: "18px",
                                        whiteSpace: "normal",
                                        padding: "1px",
                                        borderTop: "1px solid black",
                                        borderBottom: "1px solid black",
                                      }}
                                    >
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "30px",
                                          fontWeight: "bold",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-center"
                                      >
                                        {subitem.acode}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "30px",
                                          fontWeight: "bold",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-start"
                                      >
                                        {subitem.account_name}
                                      </td>

                                      <td
                                        style={{ color: "black" }}
                                        className="text-end"
                                      ></td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-end"
                                      ></td>
                                    </tr>
                                    {subitem.sub_accounts.length > 0
                                      ? subitem.sub_accounts.map(
                                          (last, subAccoutIndex) => (
                                            <React.Fragment
                                              key={subAccoutIndex}
                                            >
                                              <tr
                                                id="tbl"
                                                style={{
                                                  fontSize: "16px",
                                                  whiteSpace: "normal",
                                                  padding: "1px",
                                                }}
                                              >
                                                <td
                                                  style={{
                                                    color: "black",
                                                    paddingLeft: "60px",
                                                    whiteSpace: "normal",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                  }}
                                                  className="text-end"
                                                >
                                                  {last.acode}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "black",
                                                    paddingLeft: "60px",
                                                    whiteSpace: "normal",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                  }}
                                                  className="text-start"
                                                >
                                                  {last.account_name}
                                                </td>

                                                <td
                                                  style={{
                                                    color: "black",
                                                    whiteSpace: "normal",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                  }}
                                                  className="text-center"
                                                >
                                                  {Intl.NumberFormat(
                                                    "en-Us"
                                                  ).format(last.debit)}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "black",
                                                    whiteSpace: "normal",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                  }}
                                                  className="text-center"
                                                >
                                                  {Intl.NumberFormat(
                                                    "en-Us"
                                                  ).format(last.credit)}
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          )
                                        )
                                      : null}
                                    <tr
                                      id="tbl"
                                      style={{
                                        fontSize: "16px",
                                        whiteSpace: "normal",
                                        padding: "1px",
                                        borderTop: "1px solid black",
                                        borderBottom: "1px solid black",
                                      }}
                                    >
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "30px",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-start"
                                      ></td>
                                      <td
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-end pr-10"
                                      >
                                        Control Accounts Total :-
                                      </td>

                                      <td
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          borderTop: "2px solid black",
                                          borderBottom: "2px solid black",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-center"
                                      >
                                        {Intl.NumberFormat("en-Us").format(
                                          subitem?.total_debit
                                        )}
                                      </td>

                                      <td
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          borderTop: "2px solid black",
                                          borderBottom: "2px solid black",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-center"
                                      >
                                        {Intl.NumberFormat("en-Us").format(
                                          subitem?.total_debit
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ) : null
                            )}
                          </React.Fragment>
                        ) : null
                    )}
                  </React.Fragment>
                ) : null
              )}
              <tr
                id="tbl"
                style={{
                  fontSize: "20px",
                  whiteSpace: "normal",
                  padding: "1px",
                }}
              >
                <td
                  style={{ color: "black", paddingLeft: "30px" }}
                  className="text-start"
                ></td>
                <td
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "50px",
                    fontWeight: "bold",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-end text-primary"
                >
                  Grand Total :-
                </td>

                <td
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-center text-primary"
                >
                  {Intl.NumberFormat("en-Us").format(debitSum)}
                </td>

                <td
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-center text-primary"
                >
                  {Intl.NumberFormat("en-Us").format(creditSum)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      {/* /////for export  */}
      <div
        ref={tableRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot mt-4">
          <div className="d-flex">
            <div className="mx-5" style={{ paddingTop: "10px" }}>
              <h1>{unitName}</h1>
              <h3>
                {date != "" ? "Daily" : month != "" ? "Monthly" : "Annually"}{" "}
                Transaction Report For Session {sessionName}
                {date != ""
                  ? " ( " + getMonthDate(date) + " )"
                  : month != ""
                  ? " ( " + getMonthName(month) + " )"
                  : null}
              </h3>
            </div>
          </div>

          <Table responsive>
            <thead>
              <tr id="tbl">
                <th className="text-center" style={{ fontSize: "18px" }}>
                  A/C Code
                </th>
                <th className="text-start" style={{ fontSize: "18px" }}>
                  Account Name
                </th>
                <th className="text-center" style={{ fontSize: "18px" }}>
                  Debit
                </th>
                <th className="text-center" style={{ fontSize: "18px" }}>
                  Credit
                </th>
              </tr>
            </thead>

            <tbody>
              {reportData.map((accountGroups, accountGroups_index) =>
                accountGroups?.account_groups.length > 0 ? (
                  <React.Fragment key={accountGroups_index}>
                    <tr
                      id="tbl"
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <th
                        className="text-center text-primary"
                        style={{
                          fontSize: "20px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {accountGroups?.account_name}
                      </th>
                      <th></th>
                      <th
                        className="text-center text-primary"
                        style={{
                          fontSize: "20px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {Intl.NumberFormat("en-Us").format(
                          accountGroups?.debit
                        )}
                      </th>
                      <th
                        className="text-center text-primary"
                        style={{
                          fontSize: "20px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {Intl.NumberFormat("en-Us").format(
                          accountGroups?.credit
                        )}
                      </th>
                    </tr>
                    {accountGroups?.account_groups.map(
                      (accountName, accountName_index) =>
                        accountName?.account_charts.length > 0 ? (
                          <React.Fragment key={accountName_index}>
                            <tr
                              id="tbl"
                              style={{
                                fontSize: "18px",
                                whiteSpace: "normal",
                                padding: "1px",
                                borderTop: "1px solid black",
                                borderBottom: "1px solid black",
                              }}
                            >
                              <td
                                style={{
                                  color: "#fd12b3",
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                                className="text-start pl-10"
                              >
                                {accountName?.acode}
                              </td>
                              <td
                                style={{
                                  color: "#fd12b3",
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                                className="text-start"
                              >
                                {accountName?.account_name}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                                className="text-center text-primary"
                              >
                                {Intl.NumberFormat("en-Us").format(
                                  accountName?.debit
                                )}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                                className="text-center text-primary"
                              >
                                {Intl.NumberFormat("en-Us").format(
                                  accountName?.credit
                                )}
                              </td>
                            </tr>
                            {accountName.account_charts.map(
                              (subitem, accountChart_index) =>
                                subitem.sub_accounts.length > 0 ? (
                                  <React.Fragment key={accountChart_index}>
                                    <tr
                                      id="tbl"
                                      style={{
                                        fontSize: "18px",
                                        whiteSpace: "normal",
                                        padding: "1px",
                                        borderTop: "1px solid black",
                                        borderBottom: "1px solid black",
                                      }}
                                    >
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "30px",
                                          fontWeight: "bold",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-center"
                                      >
                                        {subitem.acode}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "30px",
                                          fontWeight: "bold",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-start"
                                      >
                                        {subitem.account_name}
                                      </td>

                                      <td
                                        style={{ color: "black" }}
                                        className="text-end"
                                      ></td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-end"
                                      ></td>
                                    </tr>
                                    {subitem.sub_accounts.length > 0
                                      ? subitem.sub_accounts.map(
                                          (last, subAccoutIndex) => (
                                            <React.Fragment
                                              key={subAccoutIndex}
                                            >
                                              <tr
                                                id="tbl"
                                                style={{
                                                  fontSize: "16px",
                                                  whiteSpace: "normal",
                                                  padding: "1px",
                                                }}
                                              >
                                                <td
                                                  style={{
                                                    color: "black",
                                                    paddingLeft: "60px",
                                                    whiteSpace: "normal",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                  }}
                                                  className="text-end"
                                                >
                                                  {last.acode}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "black",
                                                    paddingLeft: "60px",
                                                    whiteSpace: "normal",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                  }}
                                                  className="text-start"
                                                >
                                                  {last.account_name}
                                                </td>

                                                <td
                                                  style={{
                                                    color: "black",
                                                    whiteSpace: "normal",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                  }}
                                                  className="text-center"
                                                >
                                                  {Intl.NumberFormat(
                                                    "en-Us"
                                                  ).format(last.debit)}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "black",
                                                    whiteSpace: "normal",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                  }}
                                                  className="text-center"
                                                >
                                                  {Intl.NumberFormat(
                                                    "en-Us"
                                                  ).format(last.credit)}
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          )
                                        )
                                      : null}
                                    <tr
                                      id="tbl"
                                      style={{
                                        fontSize: "16px",
                                        whiteSpace: "normal",
                                        padding: "1px",
                                        borderTop: "1px solid black",
                                        borderBottom: "1px solid black",
                                      }}
                                    >
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "30px",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-start"
                                      ></td>
                                      <td
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-end pr-10"
                                      >
                                        Control Accounts Total :-
                                      </td>

                                      <td
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          borderTop: "2px solid black",
                                          borderBottom: "2px solid black",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-center"
                                      >
                                        {Intl.NumberFormat("en-Us").format(
                                          subitem?.total_debit
                                        )}
                                      </td>

                                      <td
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          borderTop: "2px solid black",
                                          borderBottom: "2px solid black",
                                          whiteSpace: "normal",
                                          paddingTop: "1px",
                                          paddingBottom: "1px",
                                        }}
                                        className="text-center"
                                      >
                                        {Intl.NumberFormat("en-Us").format(
                                          subitem?.total_debit
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ) : null
                            )}
                          </React.Fragment>
                        ) : null
                    )}
                  </React.Fragment>
                ) : null
              )}
              <tr
                id="tbl"
                style={{
                  fontSize: "20px",
                  whiteSpace: "normal",
                  padding: "1px",
                }}
              >
                <td
                  style={{ color: "black", paddingLeft: "30px" }}
                  className="text-start"
                ></td>
                <td
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "50px",
                    fontWeight: "bold",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-end text-primary"
                >
                  Grand Total :-
                </td>

                <td
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-center text-primary"
                >
                  {Intl.NumberFormat("en-Us").format(debitSum)}
                </td>

                <td
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    whiteSpace: "normal",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  className="text-center text-primary"
                >
                  {Intl.NumberFormat("en-Us").format(creditSum)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <Modal show={viewModal} centered size="lg">
        <Modal.Header>{selectedItem.sub_account}</Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th className="text-start">Date</th>
                <th className="text-start">Description</th>
                <th className="text-end">Debit</th>
                <th className="text-end">Credit</th>
              </tr>
            </thead>

            <tbody>
              {selectedItem?.general_ledgers?.map((item) => (
                <tr id="tbl">
                  <td style={{ color: "black" }} className="text-start">
                    {item.transaction_at}
                  </td>
                  <td style={{ color: "black" }} className="text-start">
                    {item.remarks}
                  </td>

                  <td
                    style={{ color: "black", fontWeight: "bold" }}
                    className="text-end text-primary"
                  >
                    {item.debit}
                  </td>

                  <td
                    style={{ color: "black", fontWeight: "bold" }}
                    className="text-end text-primary"
                  >
                    {item.credit}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => setViewModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DailyTransaction;
