import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";

const ExportIt = ({ reportData }) => {
  const tableRef = useRef(null);
  const user = useSelector((state) => state.user.user);

  let num2 = 0;
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Student Check List",
    sheet: "StudentCheckList",
  });
  return (
    <>
      <button className="btn btn-primary mx-2 mt-4" onClick={onDownload}>
        {" "}
        Export{" "}
      </button>

      <table ref={tableRef} style={{ display: "none" }}>
        <thead>
          <tr id="tbl">
            <td style={{ color: "black", textAlign: "start" }}>
              <b style={{ borderBottom: "1px solid black" }}>Sr.</b>
            </td>
            <td style={{ color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Adm-Id</b>
            </td>
            <td style={{ textAlign: "start", color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Student Name</b>
            </td>
            <td style={{ textAlign: "start", color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Father Name</b>
            </td>
            <td style={{ color: "black", textAlign: "start" }}>
              <b style={{ borderBottom: "1px solid black" }}>Mobile No</b>
            </td>
            <td style={{ color: "black", textAlign: "start" }}>
              <b style={{ borderBottom: "1px solid black" }}>Phone No</b>
            </td>
            <td style={{ color: "black", textAlign: "start" }}>
              <b style={{ borderBottom: "1px solid black" }}>Father's CNIC</b>
            </td>
            <td style={{ color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>DOB</b>
            </td>
            <td style={{ color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Gender</b>
            </td>
            {PremissionChecker(user, "system_reports.show_monthly_fee") ?
            <td style={{ color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Monthly Fee</b>
            </td>
            : null}
            <td style={{ color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Address</b>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr style={{ height: "20px", position: "relative" }}>
            <td
              colSpan={2}
              style={{
                color: "black",
                fontWeight: "bold",
              }}
              className="text_align_start"
            >
              <h3>{reportData.campus}</h3>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            {PremissionChecker(user, "system_reports.show_monthly_fee") ? <td></td> : null}
            <td></td>
            <td></td>
          </tr>
          {reportData.campus_data.map((item) =>
            item.sections.map(
              (subitem) =>
                subitem?.students.length > 0 && (
                  <>
                    <tr
                      style={{
                        height: "20px",
                        display: "flex",
                        position: "relative",
                      }}
                    >
                      <td
                        colSpan={3}
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        className="text-center"
                      >
                        {" "}
                        Class: {item.Class.name}
                      </td>
                      <td
                        colSpan={3}
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        className="text_align_start"
                      >
                        Section: {subitem.section}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {PremissionChecker(user, "system_reports.show_monthly_fee") ?<td></td> : null}
                      <td></td>
                    </tr>
                    {subitem.students.map((student, i) => (
                      <tr
                        id="tbl managingtrHeight"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <td
                          style={{ color: "black", minWidth: "50px" }}
                          className="text_align_start checklistlinehighter"
                        >
                          {(num2 += 1)}
                        </td>
                        <td
                          style={{ color: "black", width: "70px" }}
                          className="text_align_start checklistlinehighter"
                        >
                          {student.admission_id}
                        </td>
                        <td
                          style={{ color: "black", width: "250px" }}
                          className="text_align_start checklistlinehighter"
                        >
                          {student.name.length > 25
                            ? student.name.slice(0, 25)
                            : student.name}
                        </td>
                        <td
                          style={{ color: "black", width: "250px" }}
                          className="text_align_start checklistlinehighter"
                        >
                          {student.father_name.length > 25
                            ? student.father_name.slice(0, 25)
                            : student.father_name}
                        </td>
                        <td
                          style={{ color: "black", paddingRight: "10px" }}
                          className="text_align_start checklistlinehighter"
                        >
                          {student.mobile_no}
                        </td>
                        <td
                                 style={{ color: "black", paddingRight: "10px" }}
                                 className="text_align_start checklistlinehighter"
                                >
                                  {student.phone}
                                </td>
                        <td
                          style={{ color: "black", width: "150px" }}
                          className="text_align_start checklistlinehighter"
                        >
                          {student.father_cnic}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start checklistlinehighter checkDobheight"
                        >
                          {student.dob}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start checklistlinehighter"
                        >
                          {student.gender}
                        </td>
                        {PremissionChecker(user, "system_reports.show_monthly_fee") ?
                        <td
                          style={{ color: "black" }}
                          className="text_align_start checklistlinehighter"
                        >
                          {student.monthly_fees}
                        </td>
                        : null}
                        <td
                          style={{ color: "black" }}
                          className="text_align_start checkAddress"
                        >
                          {student.address}
                        </td>
                      </tr>
                    ))}
                  </>
                )
            )
          )}
        </tbody>
      </table>

      {/* {grandTotal > 0 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  columnGap: "20px",
                  paddingRight: "200px",
                  paddingTop: "20px",
                }}
              >
                <p
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Grand Total of Monthly Fee:-
                </p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  {grandTotal}
                </p>
              </div>
            )} */}
    </>
  );
};

export default ExportIt;
