import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";

const NewAdmissionExport = ({ reportData }) => {
  const tableRef = useRef(null);
  let num2 = 0;

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Students List",
    sheet: "StudentsList",
  });

  return (
    <div>
      <button className="btn btn-primary mx-2" onClick={onDownload}>
        {" "}
        Export{" "}
      </button>

      <Table ref={tableRef} style={{ display: "none" }}>
        <thead>
          <tr id="tbl">
            <td style={{ color: "black", textAlign: "start" }}>
              <b style={{ borderBottom: "1px solid black" }}>Sr.</b>
            </td>
            <td style={{ color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Adm-Id</b>
            </td>
            <td style={{ textAlign: "start", color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Student Name</b>
            </td>
            <td style={{ textAlign: "start", color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Father Name</b>
            </td>
            <td style={{ color: "black", textAlign: "start" }}>
              <b style={{ borderBottom: "1px solid black" }}>Mobile No</b>
            </td>
            <td style={{ color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>DOB</b>
            </td>
            <td style={{ color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Gender</b>
            </td>
            <td style={{ color: "black" }}>
              <b style={{ borderBottom: "1px solid black" }}>Address</b>
            </td>
          </tr>
        </thead>

        <tbody>
          {reportData?.map((mainitem) => (
            <>
              <tr style={{ height: "20px", position: "relative" }}>
                <td
                  style={{
                    color: "black",
                    width: "200px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  {mainitem.Campus}
                </td>
              </tr>
              {mainitem.classes.map((item) =>
                item.sections.map((subitem) => (
                  <>
                    {subitem.students.length > 0 && (
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          Class: {item.Class.name}
                        </td>

                        <td
                          colSpan={2}
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          <b>Section: {subitem.Section.name}</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                    {subitem.students.length > 0 &&
                      subitem.students.map((student, i) => (
                        <tr
                          id="tbl managingtrHeight"
                          style={{ borderBottom: "1px solid black" }}
                        >
                          <td
                            style={{ color: "black", minWidth: "50px" }}
                            className="text_align_start checklistlinehighter"
                          >
                            {(num2 += 1)}
                          </td>
                          <td
                            style={{ color: "black", width: "70px" }}
                            className="text_align_start checklistlinehighter"
                          >
                            {student.admission_id}
                          </td>
                          <td
                            style={{ color: "black", width: "250px" }}
                            className="text_align_start checklistlinehighter"
                          >
                            {student.name.length > 25
                              ? student.name.slice(0, 25)
                              : student.name}
                          </td>
                          <td
                            style={{ color: "black", width: "250px" }}
                            className="text_align_start checklistlinehighter"
                          >
                            {student.father_name.length > 25
                              ? student.father_name.slice(0, 25)
                              : student.father_name}
                          </td>
                          <td
                            style={{ color: "black", paddingRight: "10px" }}
                            className="text_align_start checklistlinehighter"
                          >
                            {student.mobile_no}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start checklistlinehighter checkDobheight"
                          >
                            {student.dob}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start checklistlinehighter"
                          >
                            {student.gender}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start checkAddress"
                          >
                            {student.address}
                          </td>
                        </tr>
                      ))}
                  </>
                ))
              )}
            </>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default NewAdmissionExport;
