import { message } from "antd";
import React from "react";
import { useState } from "react";
import { useAlert } from "react-alert";
import "./viewResult.css";
import { Modal, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { baseUrl } from "../../../../../services/config";
import { useEffect } from "react";
import { sendExamResultNotifications } from "../../../../../components/SendNotifications/SendNotifications";
import toast, { Toaster } from "react-hot-toast";
import { upperCase } from "lodash";
import { DownloadTableExcel } from "react-export-table-to-excel";

const ViewResultExport = ({ resultDetails }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  let authtoken = localStorage.getItem("userToken");
  const tableRef = React.useRef(null);
  const [sortedData, setSortedData] = useState();
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    if (resultDetails) {
      if (resultDetails.students_full_result) {
        let res = [];
        if (
          Array.isArray(
            resultDetails.students_full_result[0]?.student_result_overall
          ) &&
          resultDetails.students_full_result[0]?.student_result_overall.length >
            1
        ) {
          SetPositionsAnnual(resultDetails);
        } else {
          SetPositionsSingleTerm(resultDetails);
        }
      }
    }
  }, [resultDetails]);

  const SetPositionsAnnual = (resultDetails) => {
    const res = resultDetails.students_full_result.sort(
      (a, b) =>
        b.student_result_overall[0]?.gain_marks +
        b.student_result_overall[1]?.gain_marks -
        (a.student_result_overall[0]?.gain_marks +
          a.student_result_overall[1]?.gain_marks)
    );

    let position = 1;

    for (let i = 0; i < res.length; i++) {
      if (res[i].student_result_overall[1]?.full_marks == 0) {
        res[i].student_result_overall[1].position = "-";
      } else {
        if (
          i > 0 &&
          res[i].student_result_overall[0].gain_marks +
            res[i].student_result_overall[1].gain_marks ===
            res[i - 1].student_result_overall[0]?.gain_marks +
              res[i - 1].student_result_overall[1]?.gain_marks
        ) {
          res[i].student_result_overall.position =
            res[i - 1].student_result_overall.position;
        } else {
          // Assign a new position
          res[i].student_result_overall.position = convertOrdinal(position);
        }

        if (
          i < res.length - 1 &&
          res[i].student_result_overall[0].gain_marks +
            res[i].student_result_overall[1].gain_marks !==
            res[i + 1].student_result_overall[0]?.gain_marks +
              res[i + 1].student_result_overall[1]?.gain_marks
        ) {
          convertOrdinal(position++);
        }
      }
    }
    console.log(res, "here we go");
    const finnal = res?.sort(
      (a, b) => a.student.admission_id - b.student.admission_id
    );
    setSortedData(finnal);
  };

  const SetPositionsSingleTerm = (resultDetails) => {
    // Sort students based on total marks (gain_marks and practical_marks)
    const res = resultDetails.students_full_result.sort((a, b) => {
      // If practical_marks is 0, use only gain_marks, otherwise use the sum of both
      const totalMarksA =
        a.student_result_overall?.practical_marks == 0
          ? a.student_result_overall?.gain_marks
          : a.student_result_overall?.gain_marks +
            a.student_result_overall?.practical_marks;

      const totalMarksB =
        b.student_result_overall?.practical_marks == 0
          ? b.student_result_overall?.gain_marks
          : b.student_result_overall?.gain_marks +
            b.student_result_overall?.practical_marks;

      return totalMarksB - totalMarksA;
    });

    console.log(res, "Hassan Testing");

    let position = 1; // Start position at 1

    for (let i = 0; i < res.length; i++) {
      // If the student's full marks are 0, assign position as "-"
      if (res[i].student_result_overall?.full_marks == 0) {
        res[i].student_result_overall.position = "-";
      } else {
        // Calculate total marks for the current student
        const currentTotalMarks =
          res[i].student_result_overall?.practical_marks == 0
            ? res[i]?.student_result_overall?.gain_marks
            : res[i]?.student_result_overall?.gain_marks +
              res[i]?.student_result_overall?.practical_marks;

        // Calculate total marks for the previous student
        const previousTotalMarks =
          i > 0
            ? res[i - 1]?.student_result_overall?.practical_marks == 0
              ? res[i - 1]?.student_result_overall?.gain_marks
              : res[i - 1]?.student_result_overall?.gain_marks +
                res[i - 1]?.student_result_overall?.practical_marks
            : null;

        // If the current student has the same total marks as the previous student
        if (i > 0 && currentTotalMarks == previousTotalMarks) {
          res[i].student_result_overall.position =
            res[i - 1].student_result_overall.position;
        } else {
          // Assign a new position
          res[i].student_result_overall.position = convertOrdinal(position);
        }

        // If the next student has different total marks, increment the position
        if (i < res.length - 1) {
          const nextTotalMarks =
            res[i + 1]?.student_result_overall?.practical_marks == 0
              ? res[i + 1]?.student_result_overall?.gain_marks
              : res[i + 1]?.student_result_overall?.gain_marks +
                res[i + 1]?.student_result_overall?.practical_marks;

          // Only increment the position if the next student has different total marks
          if (currentTotalMarks !== nextTotalMarks) {
            position++;
          }
        }
      }
    }

    // Final sorting by admission ID after assigning positions
    const final = res.sort(
      (a, b) => a.student.admission_id - b.student.admission_id
    );
    setSortedData(final);
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function convertOrdinal(number) {
    const numberString = number.toString();
    const lastDigit = parseInt(numberString.slice(-1));
    const lastTwoDigits = parseInt(numberString.slice(-2));

    if (lastTwoDigits >= 11 && lastTwoDigits <= 20) {
      return numberString + "th";
    }

    switch (lastDigit) {
      case 1:
        return numberString + "st";
      case 2:
        return numberString + "nd";
      case 3:
        return numberString + "rd";
      default:
        return numberString + "th";
    }
  }
  const pageStyle = `
    @page {
  margin-left: 1.25in;
      size: legal landscape !important;
  }
  `;
  const handlePositionSort = () => {
    let sorter = [];
    console.log(sortedData, "Hassan Test");

    if (sortedData && sortedData[0]?.student_final_result) {
      sorter = sortedData.sort((a, b) => {
        // Check if b's practical_marks is not 0
        if (b.student_final_result?.practical_marks == 0) {
          return (
            b.student_final_result?.gain_marks -
            a.student_final_result?.gain_marks
          );
        } else {
          // If practical_marks is 0, use the sum of gain_marks and practical_marks
          return (
            b.student_final_result?.gain_marks +
            b.student_final_result?.practical_marks -
            (a.student_final_result?.gain_marks +
              a.student_final_result?.practical_marks)
          );
        }
      });
    } else {
      sorter = sortedData.sort((a, b) => {
        // Check if b's practical_marks is not 0
        if (b.student_result_overall?.practical_marks == 0) {
          return (
            b.student_result_overall?.gain_marks -
            a.student_result_overall?.gain_marks
          );
        } else {
          // If practical_marks is 0, use the sum of gain_marks and practical_marks
          return (
            b.student_result_overall?.gain_marks +
            b.student_result_overall?.practical_marks -
            (a.student_result_overall?.gain_marks +
              a.student_result_overall?.practical_marks)
          );
        }
      });
    }
    console.log("ya challa???");
    console.log(sorter);
    setSortedData([...sorter]);
  };

  const printDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  return (
    <>
      <DownloadTableExcel
        filename="Student Wise Result Export"
        sheet="Student Wise Result Export"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2">Export</button>
      </DownloadTableExcel>

      {/* Student Wise Result Export */}
      <div style={{ visibility: "hidden" }}>
        <div ref={tableRef} id="printSection">
          <div className="d-flex p-3 mt-3">
            <div className="col-md-8">
              <h1>{unitName}</h1>
              <div className="d-flex justify-content-between">
                <div>
                  <h3>
                    <b>Session: {resultDetails?.exam?.session?.year}</b>
                  </h3>

                  <h3>
                    <b>Campus: {resultDetails?.campus?.name}</b>
                  </h3>

                  <h5>
                    <b>Printing Date: {printDate()}</b>
                  </h5>
                </div>
                <div>
                  <h3>
                    <b>Class: {resultDetails?.student_class?.name}</b>
                  </h3>
                  <h3>
                    <b>Section: {resultDetails?.global_section?.name}</b>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            {resultDetails?.students_full_result &&
            resultDetails.students_full_result.length > 0 ? (
              <div>
                {Array.isArray(
                  resultDetails.students_full_result[0]?.student_result_overall
                ) ? (
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#01069F",
                    }}
                  >
                    <b>{resultDetails?.exam?.exam_type?.name} TOTAL MARKS: </b>{" "}
                    {Number(
                      resultDetails?.students_full_result[0]
                        ?.student_result_overall[0]?.full_marks
                    )}
                  </h6>
                ) : (
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#01069F",
                    }}
                  >
                    <b>{resultDetails?.exam?.exam_type?.name} TOTAL MARKS: </b>{" "}
                    {Number(
                      resultDetails?.students_full_result[0]
                        ?.student_result_overall?.full_marks
                    )}
                  </h6>
                )}
              </div>
            ) : null}
          </div>
          <table style={{ border: "1px solid #B8860B", width: "100%" }}>
            {resultDetails?.students_full_result &&
            resultDetails.students_full_result.length > 0 &&
            Array.isArray(
              resultDetails.students_full_result[0]?.student_result_overall
            ) ? (
              <>
                <thead>
                  <tr style={{ border: "1px solid #B8860B" }}>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Sr#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Adm#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        width: "120px",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Result Detail
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      FTM
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      ATM
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        // width: "15%",
                      }}
                    >
                      Result
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      %
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Pos
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        width: "10%",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((result, index) => (
                    <tr style={{ border: "1px solid #B8860B" }}>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          borderLeft: "1px solid #B8860B",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student?.admission_id}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "start",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "300px",
                          width: "220px",
                        }}
                      >
                        {result?.student?.name}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          // display: "flex",
                          textAlign: "start",
                          fontSize: "10px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result?.map((singleres) => (
                          <span>
                            {singleres?.subject?.slug}={singleres?.gain_marks}
                            {","}&nbsp;
                          </span>
                        ))}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall[0]?.exam == "FIRST TERM"
                          ? Number(
                              result?.student_result_overall[0]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[0]?.practical_marks
                            )
                          : Number(
                              result?.student_result_overall[1]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[1]?.practical_marks
                            )}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall[1]?.exam == "FIRST TERM"
                          ? Number(
                              result?.student_result_overall[0]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[0]?.practical_marks
                            )
                          : Number(
                              result?.student_result_overall[1]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[1]?.practical_marks
                            )}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "10px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "15%",
                        }}
                      >
                        {result?.student_final_result?.demand_result}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "50px",
                        }}
                      >
                        {result?.student_final_result?.percentage}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall?.position}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          width: "10%",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <>
                <thead>
                  <tr style={{ border: "1px solid #B8860B" }}>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Sr#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Adm#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        width: "120px",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Result Detail
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#0e :	father1501069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Obt. Marks
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Result
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      %
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Pos
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        width: "10%",
                      }}
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((result, index) => (
                    <tr style={{ border: "1px solid #B8860B" }}>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          borderLeft: "1px solid #B8860B",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student?.admission_id}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "start",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          width: "220px",
                        }}
                      >
                        {result?.student?.name}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          // display: "flex",
                          textAlign: "center",
                          fontSize: "10px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result?.map((singleres) => (
                          <span>
                            {singleres?.subject?.slug}={singleres?.gain_marks}
                            {","}&nbsp;
                          </span>
                        ))}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall.gain_marks}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall?.demand_result}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "50px",
                        }}
                      >
                        {result?.student_result_overall?.percentage}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {/* {result?.student_result_overall?.full_marks == 0
                        ? ""
                        : convertOrdinal(index + 1)} */}

                        {result?.student_result_overall?.position}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          width: "10%",
                        }}
                      >
                        {""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewResultExport;
