import { Paper } from "@material-ui/core";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import { DatePicker, Divider } from "antd";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from "react-export-table-to-excel";
const { RangePicker } = DatePicker;

const MonthlyExpense = () => {
  let authtoken = localStorage.getItem("userToken");
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [reportData, setReportData] = useState([]);
  const [campusId, setCampusId] = useState("");
  const [campusName, setCampusName] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [date, setDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const alert = useAlert();
  const onBeforeGetContentResolve = React.useRef(null);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    sessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
            setSessionName(session.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetData = () => {
    if (date == "") {
      alert.error("Date is required");
    }

    setLoading(true);
    setIsVisible(false);
    const data = {
      year_id: yearId,
      campus_id: campusId,
      date: date + "-01",
    };
    fetch(`${baseUrl}/date_wise_monthly_expense_summary_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setIsVisible(true);

        if (data.payload) {
          if (data.metadata.success) {
            setReportData(data.payload);
          } else {
            alert.error("Something wents wronge");
          }
        }
        let arr = [];
        data.payload.filter((item) => {
          item.account_chart.filter((subitem) => {
            if (subitem.sub_account.length > 0) {
              subitem.sub_account.filter((subs) => {
                if (subs.date_wise_data.length > 0) {
                  subs.date_wise_data.map((gg) => {
                    arr.push({
                      date: gg.date,
                      debit: parseInt(gg.debit),
                    });
                  });
                }
              });
            }
          });
        });
        console.log("this is data", data);
        console.log(arr, "resultant");
        if (arr.length > 0) {
          let modifiedArr = [];
          let debitMap = new Map();

          arr.forEach((item) => {
            if (debitMap.has(item.date)) {
              debitMap.set(item.date, debitMap.get(item.date) + item.debit);
            } else {
              debitMap.set(item.date, item.debit);
            }
          });

          debitMap.forEach((debit, date) => {
            modifiedArr.push({ date, debit });
          });

          modifiedArr.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });

          console.log(modifiedArr);
          setReportData(modifiedArr);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const getMonthName = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}, ${d.getFullYear()}`;
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="d-flex">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => {
                  if (e.target.value != "") {
                    const selectedObject = campusData.find(
                      (option) => option.id == e.target.value
                    );
                    setCampusName(selectedObject.name);
                  } else {
                    setCampusName("");
                  }
                  setCampusId(e.target.value);
                }}
              >
                <option value=""> Select Campus</option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Date</b>
            </label>
            <input
              onChange={(e) => setDate(e.target.value)}
              className="form-control"
              type="month"
            />
          </div>
          <div className="mt-9 d-flex">
            <div className="ml-4">
              <button className="btn btn-primary" onClick={handleGetData}>
                Show
              </button>
              {reportData.length > 0 ? (
                <>
                  <ReactToPrint
                    pageStyle={"auto"}
                    documentTitle={`Date Wise Monthly Expense Report`}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContent}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint={true}
                    trigger={() => (
                      <button
                        disabled={printloading ? true : false}
                        className="btn btn-primary w-105px mx-2"
                      >
                        {printloading ? <span>Printing..</span> : "Print"}
                      </button>
                    )}
                    content={() => componentRef.current}
                  />

                  <DownloadTableExcel
                    filename="Date Wise Monthly Expense Report"
                    sheet="Date Wise Monthly Expense Report"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-primary mx-2"> Export </button>
                  </DownloadTableExcel>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <br />
        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : isVisible ? (
            <>
              <div className="row mt-9">
                <div className="col-md-1 mr-8">
                  <img
                    style={{ height: 100, width: 110, paddingLeft: "20px" }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="col-md-10">
                  <h1>{unitName}</h1>
                  <h3>
                    Date Wise Monthly Expense Summary Report For Session{" "}
                    {sessionName}
                  </h3>
                  <div className="d-flex justify-content-between">
                    <h5>Campus Name : {campusName}</h5>
                    <h5>Month: {getMonthName(date)}</h5>
                  </div>
                </div>
              </div>
              <div id="tableboot">
                {reportData.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr id="tbl">
                        <th className="text-center">Date of Expenses</th>
                        <th className="text-center">Expense Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {reportData &&
                        reportData.map((item) => (
                          <tr id="tbl">
                            <td className="text-primary">{item.date}</td>

                            <td className="text-black">
                              {Intl.NumberFormat("en-Us").format(item.debit)}
                            </td>
                          </tr>
                        ))}
                      <tr id="tbl">
                        <td
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                          }}
                        >
                          Grand Total :-
                        </td>

                        <td
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                          }}
                        >
                          {Intl.NumberFormat("en-Us").format(
                            reportData.reduce(
                              (a, c) => a + parseInt(c.debit),
                              0
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : null}
              </div>
            </>
          ) : null}
        </div>

        {/* for Print */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mt-9 d-flex">
            <div>
              <img
                style={{ height: 100, width: 110, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="ml-4">
              <h1>{unitName}</h1>
              <h3>
                Date Wise Monthly Expense Summary Report For Session{" "}
                {sessionName}{" "}
              </h3>
              <div className="d-flex justify-content-between">
                <h5>Campus Name : {campusName}</h5>
                <h5>Month: {getMonthName(date)}</h5>
              </div>
            </div>
          </div>
          <br />
          <div className="mt-5">
            <>
              <div id="tableboot">
                {reportData.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr id="tbl">
                        <th
                          className="text-center"
                          style={{
                            fontSize: "18px",
                            color: "black",
                            padding: "1px",
                          }}
                        >
                          Date of Expenses
                        </th>
                        <th
                          className="text-center"
                          style={{
                            fontSize: "18px",
                            color: "black",
                            padding: "1px",
                          }}
                        >
                          Expense Amount
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {reportData &&
                        reportData.map((item) => (
                          <tr id="tbl">
                            <td
                              className="text-center text-primary"
                              style={{ fontSize: "16px", padding: "1px" }}
                            >
                              {item.date}
                            </td>

                            <td
                              className="text-center text-black"
                              style={{ fontSize: "16px", padding: "1px" }}
                            >
                              {Intl.NumberFormat("en-Us").format(item.debit)}
                            </td>
                          </tr>
                        ))}
                      <tr id="tbl">
                        <td
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                          }}
                        >
                          Grand Total :-
                        </td>

                        <td
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                          }}
                        >
                          {Intl.NumberFormat("en-Us").format(
                            reportData.reduce(
                              (a, c) => a + parseInt(c.debit),
                              0
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  ""
                )}
              </div>
            </>
          </div>
        </div>

        {/* for Export */}
        <div
          ref={tableRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mt-9 d-flex">
            <div className="ml-4">
              <h1>{unitName}</h1>
              <h3>
                Date Wise Monthly Expense Summary Report For Session{" "}
                {sessionName}{" "}
              </h3>
              <div className="d-flex justify-content-between">
                <h5>Campus Name : {campusName}</h5>
                <h5>Month: {getMonthName(date)}</h5>
              </div>
            </div>
          </div>
          <br />
          <div className="mt-5">
            <>
              <div id="tableboot">
                {reportData.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr id="tbl">
                        <th
                          className="text-center"
                          style={{
                            fontSize: "18px",
                            color: "black",
                            padding: "1px",
                          }}
                        >
                          Date of Expenses
                        </th>
                        <th
                          className="text-center"
                          style={{
                            fontSize: "18px",
                            color: "black",
                            padding: "1px",
                          }}
                        >
                          Expense Amount
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {reportData &&
                        reportData.map((item) => (
                          <tr id="tbl">
                            <td
                              className="text-center text-primary"
                              style={{ fontSize: "16px", padding: "1px" }}
                            >
                              {item.date}
                            </td>

                            <td
                              className="text-center text-black"
                              style={{ fontSize: "16px", padding: "1px" }}
                            >
                              {Intl.NumberFormat("en-Us").format(item.debit)}
                            </td>
                          </tr>
                        ))}
                      <tr id="tbl">
                        <td
                          style={{
                            color: "black",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Grand Total :-
                        </td>

                        <td
                          style={{
                            color: "black",
                            fontSize: "20px",
                            fontWeight: "bold",
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                          }}
                        >
                          {Intl.NumberFormat("en-Us").format(
                            reportData.reduce(
                              (a, c) => a + parseInt(c.debit),
                              0
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  ""
                )}
              </div>
            </>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default MonthlyExpense;
