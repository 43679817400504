import React, { useState, useRef } from "react";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../../services/PremissionChecker";

const UploadPicture = () => {
  const fileRef = useRef(null);

  let authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const [classesData, setClassesData] = useState([]);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [sectionData, setSectionData] = useState([]);
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [campusId, setCampusId] = useState("");
  const [students, setstudents] = useState([]);
  const [loading, setloading] = useState(false);
  const [images, setImages] = useState([]);
  const [studentIds, setStudentIDs] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [serchButtonLoad, setserchButtonLoad] = useState(false);
  const [showButton, setshowButton] = useState(false);
  const [uploadButtonLoad, setuploadButtonLoad] = useState(false);
  const [educationType, setEducationType] = useState(1);

  const alert = useAlert();
  const [allsessions, setallsessions] = useState([]);
  const [currentsession, setcurrentsession] = useState("");

  useEffect(() => {
    getAllSessions();
    // getAllCampus();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changesession = (id) => {
    setcurrentsession(id);
  };

  ///getting campus
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("eeeeee>>>", err);
      });
  };

  ///getting class
  const onChampusChange = (id) => {
    setCampusId(id);
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setClassesData(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///geting sessions
  const onClassChange = (id) => {
    setClassId(id);
    fetch(`${baseUrl}/class_section/${campusId}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSectionData(data.payload);
      })
      .catch((err) => {
        console.log("", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${campusId}/${id}${
        classId ? "/" + classId : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setSectionData(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///getting students
  const onSectionChange = () => {
    if (campusId == "") {
      alert.error("Please Select Campus");
      return;
    }

    let data = {
      year_id: currentsession,
      campus_id: campusId,
      student_class_id: classId,
      global_section_id: sectionId,
      education_type: educationType,
      status: 2,
    };
    setloading(true);

    fetch(`${baseUrl}/student_filter_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          let checkboxes = data.payload;
          data.payload.map((item, index) => {
            item["imageUpload"] = null;
            checkboxes[index] = item;
          });
          // let temparr = data.payload;
          setstudents(checkboxes);
          setloading(false);
          console.log(checkboxes);
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const searchbyadmId = (e) => {
    setserchButtonLoad(true);
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/search_by_name_or_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setserchButtonLoad(false);

          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("Match not found");
            }
            let checkboxes = data.payload;
            data.payload.map((item, index) => {
              item["imageUpload"] = null;
              checkboxes[index] = item;
            });
            setstudents(checkboxes);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          setserchButtonLoad(false);

          console.log("err is re", err);
        });
    }
  };

  const handleChange = (e, student, index) => {
    let arr = [...students];
    let img = e.target.files[0];

    const maxSize = 25 * 1024;

    if (img) {
      if (img.size >= maxSize) {
        alert.error("File size must be less than or equal to 25KB");
        e.target.value = "";
        return;
      }
    }

    arr[index].imageUpload = img;

    setstudents(arr);
  };

  const removeImage = (index) => {
    let arr = [...students];

    arr[index].imageUpload = null;

    setstudents(arr);
  };

  const uploadPicture = () => {
    setuploadButtonLoad(true);

    let filterd = students.filter((student) => student.imageUpload !== null);

    // console.log(filterd)

    let ids = filterd.map((student) => student.id);
    let imagesList = filterd.map((student) => student.imageUpload);

    // console.log(ids)
    // console.log(imagesList)

    const data = new FormData();
    for (let i = 0; i < imagesList.length; i++) {
      data.append("student_img[]", imagesList[i]);
    }
    for (let i = 0; i < ids.length; i++) {
      data.append("student_id[]", ids[i]);
    }

    fetch(`${baseUrl}/upload_std_picture`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },

      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setuploadButtonLoad(false);

        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
          }
        }
      })
      .catch((err) => {
        setuploadButtonLoad(false);
        console.log(err);
      });
  };

  console.log("quick view", students);

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => changesession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option
                  key={index}
                  // selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="year_id"
              aria-label="Default select example"
              onChange={(e) => onChampusChange(e.target.value)}
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campusData &&
                campusData.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              aria-label="Default select example"
              onChange={(e) => onClassChange(e.target.value)}
            >
              <option selected value="">
                {" "}
                Select Class
              </option>
              {classesData &&
                classesData.map((item) => (
                  <>
                    <option value={item.id}>{item.name}</option>
                  </>
                ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option value=""> Select Education Type</option>
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Section</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              aria-label="Default select example"
              onChange={(e) => setSectionId(e.target.value)}
            >
              <option selected value="">
                {" "}
                Section
              </option>

              {sectionData &&
                sectionData.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>

          <div className="col-md-3 mt-9">
            <button
              disabled={showButton}
              className="btn btn-primary"
              onClick={onSectionChange}
            >
              Show
            </button>
          </div>
        </div>

        <form onSubmit={searchbyadmId}>
          <div className="row mt-5">
            <div className="col-md-4">
              <input
                onChange={(e) => setsearchtext(e.target.value)}
                className="form-control"
                placeholder="Enter Name or Admission ID"
              />
            </div>
            <div className="col-md-4">
              <button
                disabled={serchButtonLoad}
                type="submit"
                className="btn btn-primary"
              >
                Search
              </button>
            </div>

            {PremissionChecker(user, "student.picture") ? (
              <div
                className="col-md-4"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <button
                  disabled={uploadButtonLoad}
                  type="button"
                  className="btn btn-primary"
                  onClick={uploadPicture}
                >
                  Upload
                </button>
              </div>
            ) : null}
          </div>
        </form>
        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ textAlign: "start" }}>Student Name</th>
                  <th style={{ textAlign: "start" }}>Father Name</th>
                  <th style={{ textAlign: "center" }}>Admission Id</th>
                  {PremissionChecker(user, "student.picture") ? (
                    <th style={{ textAlign: "center" }}>Upload Picture</th>
                  ) : null}
                  <th style={{ textAlign: "center" }}>Picture</th>
                </tr>
              </thead>
              <tbody>
                {students &&
                  students.map((student, index) => (
                    <tr key={student.id}>
                      <td
                        title={student.name.length > 15 ? student.name : null}
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {student.name}
                      </td>
                      <td
                        title={
                          student.father_name.length > 15
                            ? student.father_name
                            : null
                        }
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {student.father_name.length > 15
                          ? student.father_name.substring(0, 12) + "..."
                          : student.father_name}
                      </td>

                      {/* <td style={{ textAlign: "start" }}>{feechallan.father_name}</td> */}
                      <td style={{ textAlign: "center" }}>
                        {student.admission_id}
                      </td>
                      {PremissionChecker(user, "student.picture") ? (
                        <td style={{ textAlign: "center" }}>
                          {student.imageUpload ? (
                            <>
                              <img
                                src={URL.createObjectURL(student.imageUpload)}
                                height="60px"
                                width="60px"
                              />

                              <span
                                onClick={() => removeImage(index)}
                                style={{ cursor: "pointer" }}
                                className="svg-icon svg-icon-md svg-icon-danger p-3"
                              >
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Files/Deleted-file.svg"
                                  )}
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              <input
                                style={{ display: "none" }}
                                type="file"
                                id={`file_${index}`}
                                ref={fileRef}
                                accept="image/png, image/jpeg"
                                onChange={(e) =>
                                  handleChange(e, student, index)
                                }
                              />
                              <label
                                className="btn btn-icon btn-light btn-sm"
                                htmlFor={`file_${index}`}
                              >
                                {
                                  // student.picture ?
                                  //   <img
                                  //     src={student?.picture}
                                  //     height="60px"
                                  //     width="60px"
                                  //   />
                                  //   :
                                  <span className="svg-icon svg-icon-md svg-icon-info">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Files/Upload.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                }
                              </label>
                            </>
                          )}
                        </td>
                      ) : null}

                      <td style={{ textAlign: "center" }}>
                        <img src={student.picture} height="40px" width="40px" />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </div>
      </Paper>
    </>
  );
};

export default UploadPicture;
