import React, { useEffect, useState, useRef } from "react";
import { Divider } from "antd";
import { Hidden, LinearProgress, Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { DatePicker, Space } from "antd";
import { Spinner, Table, Button, Modal } from "react-bootstrap";
import { upperCase } from "lodash";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../components/usePagination/usePagination";
const { RangePicker } = DatePicker;
const pageStyle = `
  @page {
    size: landscape !important;
}
`;
const StudentMissingInfo = () => {
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  const [sessionName, setSessionName] = useState("");

  let authtoken = localStorage.getItem("userToken");

  var dd = "0";
  var ee = "0";
  const [missingInfoTypes, setMissingInfoTypes] = useState(1);
  const [sessioning, setallsession] = useState([]);
  const [yearId, setYearId] = useState("");

  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getallsessions();
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getallsessions = async () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsession(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            //  setcurrentsession(item.id.toString());
            setYearId(item.id);
            setSessionName(item.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShow = () => {
    setLoading(true);

    const data = {
      year_id: yearId,
      missingInfoType: missingInfoTypes,
    };

    fetch(`${baseUrl}/student-missing-information-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.student_Details.length > 0) {
          let temparr = [];
          data.student_Details.map((item, index) => {
            item["SrNo"] = index + 1;
            temparr[index] = item;
          });
          setreportData(temparr);
        } else {
          alert.error("No Record found");
          setreportData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  const printingDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  let [page, setPage] = useState(1);
  const PER_PAGE = 100;

  const count = Math.ceil(reportData.length / PER_PAGE);
  const _DATA = usePagination(reportData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row mt-3">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              className="form-select"
              value={yearId}
              onChange={(e) => setYearId(e.target.value)}
              style={{ height: 40 }}
            >
              <option value="">Select Session</option>
              {sessioning.map((sessionData) => (
                <option value={sessionData.id} key={sessionData.id}>
                  {sessionData.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Missing Info.</b>
            </label>
            <select
              id="cic"
              className="form-select"
              style={{ height: "40px" }}
              onChange={(e) => setMissingInfoTypes(e.target.value)}
            >
              <option value={1}>Missing B-Form</option>
              <option value={2}>Missing Father CNIC</option>
              <option value={3}>Missing Father Mobile No</option>
            </select>
          </div>
          <div className="col-md-4 mt-9 d-flex">
            <button onClick={handleShow} className="btn btn-primary mx-2">
              Show
            </button>
            {reportData.length > 0 ? (
              <>
                <ReactToPrint
                  documentTitle={`Student Missing Information Report`}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  pageStyle={pageStyle}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-105px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <DownloadTableExcel
                  filename="Student Missing Information Report"
                  sheet="Student Missing Information Report"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-primary mx-2"> Export </button>
                </DownloadTableExcel>
              </>
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            padding: "15px",
          }}
        >
          <div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Admin ID</th>
                  <th>Student Name</th>
                  <th>Father Name</th>
                  <th>Mobile No</th>
                  <th>B-Form</th>
                  <th>Class (Section)</th>
                  <th>Father CNIC</th>
                </tr>
              </thead>

              {_DATA.currentData().map((campus, index) => {
                return (
                  <tbody>
                    <tr>
                      <td>{campus.SrNo}</td>
                      <td>{campus.admission_id}</td>
                      <td>{campus.name}</td>
                      <td>{campus.father_name}</td>
                      <td>{campus.mobile_no}</td>

                      <td>{campus.cnic_no}</td>
                      <td>
                        {campus.student_class.name} (
                        {campus.global_section.name})
                      </td>
                      <td>{campus.father_cnic}</td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <Pagination
              color="primary"
              count={count}
              size="large"
              page={page}
              variant="text"
              shape="round"
              onChange={handleChange}
            />
          </div>
        </div>

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mt-9 d-flex">
            <div>
              <img
                style={{ height: 100, width: 110, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="ml-4">
              <h1>{unitName}</h1>
              <h5>
                Student Missing Information Report For Session {sessionName}{" "}
              </h5>
              <h5>Printing Date:- &nbsp; {printingDate()}</h5>
            </div>
          </div>
          <Table
            style={{
              whiteSpace: "normal",
              padding: "1px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Sr No
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Admin ID
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Student Name
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Father Name
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Class (Section)
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Mobile No
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  B-Form
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Father CNIC
                </th>
              </tr>
            </thead>
            {reportData.map((campus, index) => {
              return (
                <tbody>
                  <tr
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      color: "black",
                    }}
                  >
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.admission_id}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.name.length > 20
                        ? campus.name.substring(0, 20) + "..."
                        : campus.name}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.father_name.length > 20
                        ? campus.father_name.substring(0, 20) + "..."
                        : campus.father_name}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.student_class.name} ({campus.global_section.name})
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {campus.mobile_no}
                    </td>

                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.cnic_no}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.father_cnic}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </div>

        {/* //for export///// */}
        <div
          ref={tableRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mt-9 d-flex">
            <div className="ml-4">
              <h1>{unitName}</h1>
              <h5>
                Student Missing Information Report For Session {sessionName}{" "}
              </h5>
              <h5>Printing Date:- &nbsp; {printingDate()}</h5>
            </div>
          </div>
          <Table
            style={{
              whiteSpace: "normal",
              padding: "1px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Sr No
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Admin ID
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Student Name
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Father Name
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Class (Section)
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Mobile No
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  B-Form
                </th>
                <th
                  style={{
                    fontSize: "14px",
                    whiteSpace: "normal",
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  Father CNIC
                </th>
              </tr>
            </thead>
            {reportData.map((campus, index) => {
              return (
                <tbody>
                  <tr
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      color: "black",
                    }}
                  >
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.admission_id}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.name.length > 20
                        ? campus.name.substring(0, 20) + "..."
                        : campus.name}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.father_name.length > 20
                        ? campus.father_name.substring(0, 20) + "..."
                        : campus.father_name}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.student_class.name} ({campus.global_section.name})
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {campus.mobile_no}
                    </td>

                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.cnic_no}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {campus.father_cnic}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </div>
      </Paper>
    </>
  );
};

export default StudentMissingInfo;
