import React, { useEffect, useRef, useState } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { Divider } from "antd";
import "./comparative.css";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { upperCase } from "lodash";
const ComparativeExpense = () => {
  let authtoken = localStorage.getItem("userToken");
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [allMonths, setAllMonths] = useState([]);
  const [allSubAccounts, setAllSubAccounts] = useState([]);
  const [indexMonth, setIndexMonth] = useState([]);
  const [grandSum, setGrandSum] = useState(0);
  const [campusName, setCampusName] = useState("");
  const [currentindex, setcurrentindex] = useState(0);
  const [compared, setCompared] = useState([]);
  const monthTotals = {};
  const [sessionName, setSessionName] = useState("");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const pageStyle = `
  @page {
    size: landscape !important;
}
`;
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  useEffect(() => {
    getSettingData();
    sessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
            setSessionName(session.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showResults = () => {
    setGrandSum(0);
    setAllSubAccounts([]);
    setAllMonths([]);
    setIndexMonth([]);

    setLoading(true);
    const data = {
      year_id: yearId,
      campus_id: campusId,
    };
    fetch(`${baseUrl}/comparative_expense_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setLoading(false);
          setReportData(data.payload);
          dataChange(data.payload);
          dateGetter(data.payload);
          console.log(data, "data Checking");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataChange = (data) => {
    if (data.length > 0) {
      let subAccounts = [];

      data.forEach((accountGroup) => {
        accountGroup.account_chart.forEach((account) => {
          account.month_data.forEach((month) => {
            month.sub_account.forEach((subAccount) => {
              subAccounts.push({
                // account_chart: account.account_chart,
                account_group: accountGroup.account_group,
                // month_year: month.month_year,
                sub_account: subAccount.sub_account,
                // debit: subAccount.debit,
                sub_account_months: getAccountMonth(
                  account.month_data,
                  subAccount.sub_account
                ),
              });
            });
          });
        });
      });

      const uniqueArray = subAccounts.filter((item, index) => {
        return (
          index ===
          subAccounts.findIndex((obj) => {
            return JSON.stringify(obj) === JSON.stringify(item);
          })
        );
      });

      let sum2 = 0;
      uniqueArray.forEach((element) => {
        element.sub_account_months.map((suitem) => {
          sum2 += parseInt(suitem.debit);
        });
      });
      setGrandSum(sum2);
      setAllSubAccounts(uniqueArray);
      console.log(uniqueArray, "changed data");
    }
  };

  const dateGetter = (data) => {
    if (data.length > 0) {
      let months = [];

      data.forEach((accountGroup) => {
        accountGroup.account_chart.forEach((account) => {
          account.month_data.forEach((month) => {
            months.push({
              month_year: month.month_year,
            });
          });
        });
      });

      months.sort((a, b) => {
        // Convert month_year strings to Date objects for comparison
        const dateA = new Date(a.month_year);
        const dateB = new Date(b.month_year);

        // Compare the Date objects
        return dateA - dateB;
      });

      const uniqueArray = months.filter((item, index) => {
        return (
          index ===
          months.findIndex((obj) => {
            return JSON.stringify(obj) === JSON.stringify(item);
          })
        );
      });

      setAllMonths(uniqueArray);
      const indexArray = uniqueArray.map((item) => item.month_year);
      setIndexMonth(indexArray);
      console.log(indexArray, "index");
      console.log(uniqueArray, "this is months");
    }
  };

  const array = [
    {
      account_group: "School Expense",
      sub_account: "Basic Pay",
      sub_account_months: [
        {
          debit: "30458.00",
          month: "March-2023",
          name: "Basic Pay",
        },
        {
          debit: "30458.00",
          month: "April-2023",
          name: "Basic Pay",
        },
      ],
    },
    {
      account_group: "School Expense",
      sub_account: "Basic Pay",
      sub_account_months: [
        {
          debit: "30458.00",
          month: "March-2023",
          name: "Basic Pay",
        },
        {
          debit: "30458.00",
          month: "April-2023",
          name: "Basic Pay",
        },
      ],
    },
    {
      account_group: "School Expense",
      sub_account: "Hostel Allowance",
      sub_account_months: [
        {
          debit: "30458.00",
          month: "March-2023",
          name: "Hostel Allowance",
        },
        {
          debit: "30458.00",
          month: "April-2023",
          name: "Hostel Allowance",
        },
      ],
    },
    {
      account_group: "Utilities",
      sub_account: "Basic Pay",
      sub_account_months: [
        {
          debit: "30458.00",
          month: "March-2023",
          name: "Basic Pay",
        },
        {
          debit: "30458.00",
          month: "April-2023",
          name: "Basic Pay",
        },
      ],
    },
  ];

  const getAccountMonth = (here, compare) => {
    let arr = [];
    let empty = [];
    here.forEach((item) => {
      item.sub_account.forEach((subItem) => {
        if (subItem.sub_account == compare) {
          empty.push({
            month: item.month_year,
            name: subItem.sub_account,
            debit: subItem.debit,
          });
        }
      });
    });
    arr.push({
      name: compare,
    });
    console.log(arr);
    return empty;
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const printingDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>
                Select Session{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>
                Select Campus{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => {
                  if (e.target.value != "") {
                    const selectedObject = campusData.find(
                      (option) => option.id == e.target.value
                    );
                    setCampusName(selectedObject.name);
                  } else {
                    setCampusName("");
                  }
                  setCampusId(e.target.value);
                }}
              >
                <option value="">Select Campus</option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6 mt-9">
            <button
              disabled={loading}
              className="btn btn-primary "
              onClick={showResults}
            >
              Show
            </button>
            {reportData.length > 0 ? (
              <>
                <ReactToPrint
                  pageStyle={pageStyle}
                  documentTitle={`Comparative Expense Report`}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-105px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <DownloadTableExcel
                  filename="Comparative Expense Report"
                  sheet="Comparative Expense Report"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-primary mx-2"> Export </button>
                </DownloadTableExcel>
              </>
            ) : null}
          </div>
        </div>
        <Divider />
        <div id="tableboot">
          <Table responsive>
            <thead>
              <tr id="tbl">
                {allMonths && allMonths.length > 0 ? (
                  <th className="text-start">Account Heads</th>
                ) : null}
                {allMonths &&
                  allMonths.map((month) => (
                    <>
                      <th className="text-center">{month.month_year}</th>
                    </>
                  ))}
                {allMonths && allMonths.length > 0 ? (
                  <th
                    className="text-center"
                    style={{
                      borderTop: "1px solid black",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    Total
                  </th>
                ) : null}
              </tr>
            </thead>

            <tbody>
              {allSubAccounts.map((data) => (
                <tr>
                  <td className="text-start" style={{ color: "black" }}>
                    {data.sub_account}
                  </td>
                  {indexMonth.map((header) => {
                    const matchingMonth = data.sub_account_months.find(
                      (monthData) => monthData.month === header
                    );
                    if (matchingMonth) {
                      const debit = parseFloat(matchingMonth.debit);
                      if (monthTotals[header]) {
                        monthTotals[header] += debit;
                      } else {
                        monthTotals[header] = debit;
                      }
                    }
                    return (
                      <td
                        key={header}
                        className="text-center"
                        style={{ color: "black" }}
                      >
                        {matchingMonth
                          ? Intl.NumberFormat("en-Us").format(
                              matchingMonth.debit
                            )
                          : "---"}
                      </td>
                    );
                  })}
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      backgroundColor: "beige",
                    }}
                  >
                    {" "}
                    {Intl.NumberFormat("en-Us").format(
                      data.sub_account_months.reduce(
                        (a, c) => a + parseInt(c.debit),
                        0
                      )
                    )}
                  </td>
                </tr>
              ))}
              <tr
                style={{
                  borderTop: "2px solid black",
                  borderBottom: "2px solid black",
                }}
              >
                {indexMonth && indexMonth.length > 0 ? (
                  <td
                    className="text-start"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Total
                  </td>
                ) : null}
                {indexMonth.map((header) => (
                  <td
                    className="text-center"
                    style={{ color: "black", fontWeight: "bold" }}
                    key={header}
                  >
                    {monthTotals[header]
                      ? Intl.NumberFormat("en-Us").format(monthTotals[header])
                      : "---"}
                  </td>
                ))}
                {grandSum != 0 ? (
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                      backgroundColor: "beige",
                    }}
                  >
                    {Intl.NumberFormat("en-Us").format(grandSum)}
                  </td>
                ) : null}
              </tr>
            </tbody>
          </Table>
        </div>

        {/* for print */}
        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mainContainer">
            <div className="logocontainer" style={{ height: "100px" }}>
              <div className="d-flex">
                <div>
                  <img
                    style={{ height: 120, width: 110 }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h4>
                    {" "}
                    Comparative Expense Report For Session
                    {sessionName != "" ? " " + sessionName + " " : null}{" "}
                    {campusName != "" ? " ( " + campusName + " ) " : null}{" "}
                  </h4>
                  <div className="d-flex justify-content-between">
                    <h5>Session : {sessionName}</h5>
                    <h5>Printing Date: {printingDate()}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    {allMonths && allMonths.length > 0 ? (
                      <th className="text-start" style={{ fontSize: "14px" }}>
                        Account Heads
                      </th>
                    ) : null}
                    {allMonths &&
                      allMonths.map((month) => (
                        <>
                          <th
                            className="text-center"
                            style={{ fontSize: "14px" }}
                          >
                            {month.month_year}
                          </th>
                        </>
                      ))}
                    {allMonths && allMonths.length > 0 ? (
                      <th
                        className="text-center"
                        style={{
                          fontSize: "14px",
                          borderTop: "1px solid black",
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                        }}
                      >
                        Total
                      </th>
                    ) : null}
                  </tr>
                </thead>

                <tbody>
                  {allSubAccounts.map((data) => (
                    <tr>
                      <td
                        className="text-start"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          padding: "1px",
                        }}
                      >
                        {data.sub_account}
                      </td>
                      {indexMonth.map((header) => {
                        const matchingMonth = data.sub_account_months.find(
                          (monthData) => monthData.month === header
                        );
                        if (matchingMonth) {
                          const debit = parseFloat(matchingMonth.debit);
                          if (monthTotals[header]) {
                            monthTotals[header] += debit;
                          } else {
                            monthTotals[header] = debit;
                          }
                        }
                        return (
                          <td
                            key={header}
                            className="text-center"
                            style={{
                              color: "black",
                              fontSize: "12px",
                              padding: "1px",
                            }}
                          >
                            {matchingMonth
                              ? Intl.NumberFormat("en-Us").format(
                                  matchingMonth.debit
                                )
                              : "---"}
                          </td>
                        );
                      })}
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          backgroundColor: "beige",
                          fontSize: "12px",
                          padding: "1px",
                        }}
                      >
                        {" "}
                        {Intl.NumberFormat("en-Us").format(
                          data.sub_account_months.reduce(
                            (a, c) => a + parseInt(c.debit),
                            0
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr
                    style={{
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    {indexMonth && indexMonth.length > 0 ? (
                      <td
                        className="text-start"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "14px",
                          padding: "1px",
                        }}
                      >
                        Total
                      </td>
                    ) : null}
                    {indexMonth.map((header) => (
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "14px",
                          padding: "1px",
                        }}
                        key={header}
                      >
                        {monthTotals[header]
                          ? Intl.NumberFormat("en-Us").format(
                              monthTotals[header]
                            )
                          : "---"}
                      </td>
                    ))}
                    {grandSum != 0 ? (
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          borderBottom: "1px solid black",
                          backgroundColor: "beige",
                          fontSize: "14px",
                          padding: "1px",
                        }}
                      >
                        {Intl.NumberFormat("en-Us").format(grandSum)}
                      </td>
                    ) : null}
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default ComparativeExpense;
