import React from "react";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useAlert } from "react-alert";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { baseUrl } from "../../../services/config";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from "react-export-table-to-excel";

const pageStyle = `
  @page {
    margin-left: 1.5in;
    size: legal landscape !important;
}
`;
const PrintAttendanceSectionWise = () => {
  const user = useSelector((state) => state.user.user);
  const [allsessions, setallsessions] = useState([]);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [classesData, setClassesData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [campusId, setCampusId] = useState("");
  const [campusName, setCampusName] = useState("");
  const [className, setClassName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [classId, setClassId] = useState("");
  const [monthName, setMonthName] = useState("");
  const [monthDays, setMonthDays] = useState([]);
  const [monthYear, setMonthYear] = useState("");
  const [studentsData, setStudentsData] = useState(0);
  const [loading, setloading] = useState(false);
  const [educationType, setEducationType] = useState(1);
  const [currentsession, setcurrentsession] = useState("");

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  let authtoken = localStorage.getItem("userToken");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    // getAllCampus();
    getAllSessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onChampusChange = (id) => {
    const campus = campusData.find((item) => {
      return item.id == id;
    });

    setCampusName(campus.name);
    document.getElementById("classselect").selectedIndex = 0;
    document.getElementById("sectionselect").selectedIndex = 0;
    setCampusId(id);
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setClassesData(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onClassChange = (id) => {
    document.getElementById("sectionselect").selectedIndex = 0;
    const className = classesData.find((item) => {
      return item.id == id;
    });

    setClassName(className.name);
    setClassId(id);
    fetch(`${baseUrl}/class_section/${campusId}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSectionData(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("", err);
      });
  };
  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${campusId}/${id}${
        classId ? "/" + classId : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setSectionData(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllStudents = (stucampus, stuclass, stusection) => {
    let data = {
      campus_id: stucampus,
      student_class_id: stuclass,
      global_section_id: stusection,
      education_type: educationType,
      status: 2,
      year_id: currentsession,
    };
    setloading(true);

    fetch(`${baseUrl}/student_filter_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          let res = data.payload.sort(
            (a, b) => a.admission_id - b.admission_id
          );
          console.log(res);
          setStudentsData(res);
          setloading(false);
          console.log(data.payload);
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const onSectionChange = (id) => {
    const sectionName = sectionData.find((item) => {
      return item.id == id;
    });

    setSectionName(sectionName.name);
    setSectionId(id);
  };
  const handleShow = () => {
    if (monthName == "") {
      alert.error("Month name is required ");
      return;
    }
    if (campusId == "") {
      alert.error("Campus is required ");
      return;
    }
    getAllStudents(campusId, classId, sectionId);
  };
  const handleMonthChnage = (value) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(value);
    setMonthYear(date.getFullYear());
    const monthDays = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    const monthDaysArray = Array.from(Array(monthDays).keys(), (n) => n + 1);
    setMonthName(monthNames[date.getMonth()]);
    setMonthDays(monthDaysArray);
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => setcurrentsession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option
                  key={index}
                  //   selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Month</b>
            </label>
            <input
              style={{ width: "100%" }}
              type="month"
              onChange={(e) => handleMonthChnage(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => onChampusChange(e.target.value)}
              >
                <option selected value="">
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>
            <div className="d-flex flex-column">
              <select
                id="classselect"
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                aria-label="Default select example"
                onChange={(e) => onClassChange(e.target.value)}
              >
                <option selected value="">
                  Select Class
                </option>
                {classesData &&
                  classesData.map((item) => (
                    <>
                      <option value={item.id}>{item.name}</option>
                    </>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option value=""> Select Education Type</option>
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="col-md-3 mt-3">
            <label>
              <b>Select Section</b>
            </label>
            <div className="d-flex flex-column">
              <select
                id="sectionselect"
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                aria-label="Default select example"
                onChange={(e) => onSectionChange(e.target.value)}
              >
                <option selected value="">
                  Section
                </option>

                {sectionData &&
                  sectionData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 mt-12">
            <button className="btn btn-primary" onClick={handleShow}>
              Search
            </button>

            {studentsData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Attendance Report`}
                onAfterPrint={handleAfterPrint}
                pageStyle={pageStyle}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-primary w-100px mx-2"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
            <DownloadTableExcel
              filename="Attendance Export"
              sheet="Attendance Export"
              currentTableRef={tableRef.current}
            >
              {studentsData.length > 0 && (
                <button className="btn btn-primary mx-2">Export</button>
              )}
            </DownloadTableExcel>
          </div>
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div className="mt-9">
            {studentsData.length > 0 ? (
              <>
                <div className="row p-3">
                  <img
                    // className='col-md-2'
                    style={{ height: 100, width: 100 }}
                    src={icon}
                  />

                  <div className="col-md-8">
                    <h1>{unitName}</h1>
                    <h4>
                      Attendance Sheet of {campusName} {className}({sectionName}
                      ) for the Month of {monthName},{monthYear}{" "}
                    </h4>
                  </div>
                </div>
                <div id="tableboot" style={{ overflowX: "scroll" }}>
                  <table style={{ width: "1300px" }}>
                    <thead>
                      <tr style={{ fontSize: "16px important" }}>
                        <th
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Sr
                        </th>
                        <th
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Adm-No.
                        </th>
                        <th
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Student Name
                        </th>
                        {monthDays.map((day) => (
                          <th
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {day}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {studentsData.map((item, index) => (
                        <tr id="tbl">
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.admission_id}
                          </td>
                          <td
                            style={{
                              width: "235px",
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {item.name.length > 27
                              ? item.name.slice(0, 27)
                              : item.name}
                          </td>
                          {monthDays.map((days) => (
                            <td
                              style={{
                                color: "black",
                                border: "1px solid black",
                                width: "35px",
                              }}
                              className="text_align_start"
                            ></td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}
          </div>
        )}

        {/* ///for print */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div>
            {studentsData.length > 0 ? (
              <>
                <div className="row p-3">
                  <img
                    // className='col-md-2'
                    style={{ height: 100, width: 100 }}
                    src={icon}
                  />

                  <div className="col-md-8">
                    <h1>{unitName}</h1>
                    <h4>
                      Attendance Sheet of {campusName}{" "}
                      <u>
                        {className}({sectionName}
                      </u>
                      ) from the Month of {monthName},{monthYear}{" "}
                    </h4>
                  </div>
                  <p className="text-end">
                    <b
                      style={{
                        color: "black",
                        fontSize: "18px",
                        paddingRight: "30px",
                        paddingLeft: "30px",
                        borderTop: "1px solid black",
                      }}
                    >
                      Class Incharge
                    </b>
                  </p>
                </div>

                <div id="tableboot">
                  <table style={{ width: "1300px" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          Sr
                        </th>
                        <th
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            fontSize: "16px",
                          }}
                        >
                          Adm-No.
                        </th>
                        <th
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          Student Name
                        </th>
                        {monthDays.map((day) => (
                          <th
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                          >
                            {day}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {studentsData.map((item, index) => (
                        <tr id="tbl">
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.admission_id}
                          </td>
                          <td
                            style={{
                              color: "black",
                              width: "235px",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {item.name.length > 27
                              ? item.name.slice(0, 27)
                              : item.name}
                          </td>
                          {monthDays.map((days) => (
                            <td
                              style={{
                                color: "black",
                                border: "1px solid black",
                                width: "35px",
                              }}
                              className="text_align_start"
                            ></td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}
          </div>
        </div>

        {/* ///for export */}
        <div
          ref={tableRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div>
            {studentsData.length > 0 ? (
              <>
                <div className="row p-3">
                  <img
                    // className='col-md-2'
                    style={{ height: 100, width: 100 }}
                    src={icon}
                  />

                  <div className="col-md-8">
                    <h1>{unitName}</h1>
                    <h4>
                      Attendance Sheet of {campusName} {className}({sectionName}
                      ) from the Month of {monthName},{monthYear}{" "}
                    </h4>
                  </div>
                </div>
                <div id="tableboot">
                  <table style={{ width: "1300px" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          Sr
                        </th>
                        <th
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            fontSize: "16px",
                          }}
                        >
                          Adm-No.
                        </th>
                        <th
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          Student Name
                        </th>
                        {monthDays.map((day) => (
                          <th
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                          >
                            {day}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {studentsData.map((item, index) => (
                        <tr id="tbl">
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.admission_id}
                          </td>
                          <td
                            style={{
                              color: "black",
                              width: "235px",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {item.name.length > 27
                              ? item.name.slice(0, 27)
                              : item.name}
                          </td>
                          {monthDays.map((days) => (
                            <td
                              style={{
                                color: "black",
                                border: "1px solid black",
                                width: "35px",
                              }}
                              className="text_align_start"
                            ></td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default PrintAttendanceSectionWise;
