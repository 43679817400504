import React, { useEffect, useState } from "react";
import { PeopleAltOutlined, Payment, Apartment } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
// import AddchartIcon from '@mui/icons-material/Addchart';
import Paper from "@material-ui/core/Paper";
import {
  Table,
  Modal,
  Spinner,
  Alert,
  Form,
  ModalFooter,
  Badge,
} from "react-bootstrap";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import MySelect from "./../../CustomChallan/MySelect";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import "./DateSheetStyle.css";
const DateSheetModal = ({
  show,
  onHide,
  selectedclassfordatesheet,
  examid,
  showdetailmodal,
  campusId,
}) => {
  useEffect(() => {
    getPreviousDatasheet();
  }, []);

  const [datesheetlist, setdatesheetlist] = useState([]);
  const [classes, setClasses] = useState();
  const [deleteModal, setdeletemodal] = useState(false);
  const [selectedObject, setselectedObject] = useState({});
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [note, setNote] = useState("");
  const [wholeData, setWholeData] = useState();

  const getPreviousDatasheet = () => {
    let data = {
      campus_id: campusId,
      exam_id: examid,
      student_class_id: selectedclassfordatesheet,
    };

    fetch(`${baseUrl}/exam_class_datesheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Data", data);
        if (data.metadata) {
          if (data.metadata.success) {
            setClasses(data.payload?.student_class);
            setdatesheetlist(data.payload?.subjects);
            setWholeData(data.payload);
            setNote(data.payload.note);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const history = useHistory();
  const alert = useAlert();
  // configgration
  const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  //arrays
  const [values, setValues] = useState([]);

  // const [dates, setDates] = useState([
  //     new Date(),
  //     new DateObject({ year: 2020, month: 9, day: 8 }),
  //     "December 09 2020",
  //     1597994736000 //unix time in milliseconds (August 21 2020)
  // ])
  const [dates, setDates] = useState([]);

  const [allexamslist, setallexamslist] = useState([]);
  const [examslist, setexamslist] = useState([]);
  const [allsessions, setallsessions] = useState([]);

  const [allclasses, setallclasses] = useState([]);
  const [optionSelected, setoptionSelected] = useState([]);
  const [selectedclasses, setselectedclasses] = useState([]);
  const [allsubjects, setallsubjects] = useState([]);
  const [selectedsubjects, setselectedsubjects] = useState([]);

  //objects

  //booleans
  const [isallfields, setisallfields] = useState(false);
  const [loading, setloading] = useState(false);
  const [loader, setloader] = useState(false);
  const [datasheetmodal, setdatasheetmodal] = useState(false);
  //strings
  const [currentdate, setcurrentdate] = useState("");
  const [selectedsubject, setselectedsubject] = useState([]);

  const [currentsession, setcurrentsession] = useState("");

  const [selectedexam, setselectedexam] = useState("");

  //functions

  useEffect(() => {
    getAllSubjects();
  }, []);

  const getAllSubjects = () => {
    fetch(`${baseUrl}/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsubjects([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createDateSheetHandler = () => {
    let alldates = [];
    // dates.map((date, index) => {
    //     alldates.push(date.format())
    // })
    let data = {
      dates: dates,
      subject_ids: selectedsubjects,
      exam_id: examid,
      student_class_id: selectedclassfordatesheet,
      // note:note
    };
    console.log(data);
    if (dates.length == 0 || selectedsubjects.length == 0) {
      setisallfields(true);
      return;
    }

    setloading(true);
    setisallfields(false);

    fetch(`${baseUrl}/date_sheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setloading(false);
        setselectedsubjects([]);
        setDates([]);
        setoptionSelected([]);
        getPreviousDatasheet();
        // onHide(false)
        // showdetailmodal(false)
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show("Date Sheet Created Successfully");
          } else {
            let message = data.metadata.message[0];
            alert.show(message);
          }
        } else {
          let message = data.metadata.message[0];
          alert.show(message);
        }
      })
      .catch((err) => {
        showdetailmodal(false);
        onHide(false);
        setloading(false);
        setselectedsubjects([]);
        setDates([]);
        setoptionSelected([]);

        alert.show("Error Occured");
        console.log(err);
      });
  };

  const addNote = () => {
    let data = {
      note: note,
      id: wholeData.id,
    };

    setloading(true);
    setisallfields(false);

    fetch(`${baseUrl}/note-date-sheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setloading(false);
        setselectedsubjects([]);
        setDates([]);
        setoptionSelected([]);
        getPreviousDatasheet();
        // onHide(false)
        // showdetailmodal(false)
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show(data.metadata.message);
          } else {
            let message = data.metadata.message[0];
            alert.show(message);
          }
        } else {
          let message = data.metadata.message[0];
          alert.show(message);
        }
      })
      .catch((err) => {
        showdetailmodal(false);
        onHide(false);
        setloading(false);
        setselectedsubjects([]);
        setDates([]);
        setoptionSelected([]);

        alert.show("Error Occured");
        console.log(err);
      });
  };
  //multiselect configration
  const Options = [];
  allsubjects.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleChangeClasses = (selected) => {
    console.log(selected);
    if (optionSelected.length == 30 && selected.length == 30) {
      setoptionSelected([]);
      return;
    }
    setoptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    console.log(valuearray);
    setselectedsubjects([...valuearray]);
  };

  const openDelete = (item) => {
    setselectedObject(item);
    setdeletemodal(true);
  };

  const DeleteSheet = () => {
    setdeleteLoading(true);
    fetch(`${baseUrl}/delete-date-sheet-subject/${selectedObject.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.metadata.success) {
          setdeleteLoading(false);
          setdeletemodal(false);
          getPreviousDatasheet();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Modal size="lg" show={show}>
      <Modal.Header className="modal_header_style" closeButton>
        <Modal.Title
          className="Modal_Heading_font"
          id="contained-modal-title-vcenter"
        >
          Exam Date Sheet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="form-group col-sm-12  d-flex">
          <div className=" col-md-6  flex-column d-flex ">
            <label>
              <b>
                Select Date <span style={{ color: "red" }}>*</span>
              </b>
            </label>

            <input
              value={currentdate}
              className="form-control"
              type="date"
              format="YYYY-MM-DD"
              onChange={(e) => {
                setcurrentdate(e.target.value);
              }}
            />

            {/* <DatePicker
                            value={dates}
                            onChange={setDates}
                            multiple
                            sort
                            placeholder="YYYY-MM-DD"
                            format="YYYY-MM-DD"
                            calendarPosition="bottom-center"
                            plugins={[<DatePanel />]}
                        /> */}
          </div>
          <br />

          <div className=" col-md-6  flex-column d-flex ">
            <label>
              <b>
                Select Subjects <span style={{ color: "red" }}>*</span>
              </b>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedsubject}
              onChange={(e) => setselectedsubject(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Subject
              </option>
              {allsubjects.map((subject, index) => (
                <option key={index} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
            {/* 
                        <MySelect

                            options={Options}
                            isMulti
                            name="selectedOptions"
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}

                            components={{ Option, MultiValue, animatedComponents }}
                            onChange={handleChangeClasses}
                            allowSelectAll={true}
                            value={optionSelected}
                        /> */}
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="col-md-12">
              <div>
                <label>
                  <b>Add Note</b>
                </label>
              </div>
              <textarea
                value={note}
                placeholder="Enter Note..."
                style={{ width: "100%" }}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="col-sm-12 d-flex justify-content-end mt-3">
          <button
            disabled={
              selectedsubject != "" ? (currentdate != "" ? false : true) : true
            }
            onClick={() => {
              setselectedsubjects((prev) => [...prev, selectedsubject]);
              setDates((prev) => [...prev, currentdate]);
              setselectedsubject("");
              setcurrentdate("");
            }}
            className="btn btn-success button_blue"
          >
            Add
          </button>
          <button
            onClick={addNote}
            type="button"
            className="btn btn-success button_blue mx-2"
          >
            Add Note
          </button>
        </div>
        <Table responsive>
          <thead>
            <tr id="tbl">
              <th>Date</th>
              <th>Subject</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>

          <tbody>
            {selectedsubjects?.map((item, index) => {
              return (
                <tr id="tbl">
                  <td style={{ color: "black" }} className="text_align_start">
                    {dates[index]}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {
                      allsubjects.find((subject) => {
                        return subject.id == item;
                      }).name
                    }
                  </td>
                  <td
                    style={{ paddingLeft: "0px" }}
                    className="text_align_start"
                  >
                    <a
                      key={index}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={(e) => {
                        setselectedsubject(item);
                        setcurrentdate(dates[index]);

                        let updatesubjectsarray = [...selectedsubjects];
                        updatesubjectsarray.splice(index, 1);
                        setselectedsubjects([...updatesubjectsarray]);

                        let updatedatesarray = [...dates];
                        updatedatesarray.splice(index, 1);
                        setDates([...updatedatesarray]);

                        // setDates((prevState =>

                        // prevState.splice(index, 1)));
                      }}
                    >
                      <span
                        key={index}
                        className="svg-icon svg-icon-md svg-icon-primary"
                      >
                        <SVG
                          key={index}
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                  <td className="text_align_start">
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => {
                        let updatesubjectsarray = [...selectedsubjects];
                        updatesubjectsarray.splice(index, 1);
                        setselectedsubjects([...updatesubjectsarray]);

                        let updatedatesarray = [...dates];
                        updatedatesarray.splice(index, 1);
                        setDates([...updatedatesarray]);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Error-circle.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        {datesheetlist?.length > 0 ? (
          <>
            <h3 className="text-center">
              <b>Previous Datesheet</b>
            </h3>

            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>Sr.no</th>
                  <th>Date</th>
                  <th>Subject</th>
                  <th>Class Name</th>
                  <th>Delete</th>
                </tr>
              </thead>

              <tbody>
                {datesheetlist
                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((item, index) => (
                    <tr id="tbl">
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.date}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.subject.name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {classes.name}
                      </td>
                      {/* <th>CNIC/ B FORM</th> */}

                      <td
                        onClick={() => openDelete(item)}
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        <span
                          // onClick={() => openDelete(row)}
                          style={{ cursor: "pointer" }}
                          className="svg-icon svg-icon-md svg-icon-danger"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : null}
      </Modal.Body>
      {isallfields ? (
        <>
          <Alert
            variant="danger"
            onClose={() => setisallfields(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>Please Fill All Fields</p>
          </Alert>
        </>
      ) : null}

      <Modal.Footer>
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            {" "}
            <span className="spinner-border"></span>{" "}
          </div>
        ) : (
          <>
            <button
              onClick={() => {
                createDateSheetHandler();
              }}
              className="btn btn-success button_blue"
            >
              Declare
            </button>

            <button
              onClick={() => {
                setisallfields(false);
                setloading(false);
                setselectedsubjects([]);
                setDates([]);
                setoptionSelected([]);
                onHide(false);
              }}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </>
        )}
      </Modal.Footer>

      <Modal show={deleteModal}>
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          {deleteLoading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <button
                onClick={() => setdeletemodal(false)}
                class="btn btn-danger"
              >
                Cancel
              </button>
              <button onClick={DeleteSheet} class="btn btn-primary">
                Delete
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </Modal>
  );
};

export default DateSheetModal;
