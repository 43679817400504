import React, { useEffect, useState } from "react";
import { Modal, Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import { Divider } from "antd";

const FeeReturnModal = ({
  show,
  hide,
  studentDetails,
  reload,
  challanHistory,
  past6monthsChallans,
  feeReturnHistory,
}) => {
  const [loading, setloading] = useState(false);
  const [subAccountList, setsubAccountList] = useState([]);
  const [subAccountcontrList, setsubAccountcontrList] = useState([]);
  const [fee_return_amount, setfee_return_amount] = useState("");
  const [remarks, setremarks] = useState("");
  const [sub_account_id, setsub_account_id] = useState("");
  const [sub_account_contra_id, setsub_account_contra_id] = useState("");

  console.log("student details==>", studentDetails);

  const authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  useEffect(() => {
    getSubAccountList();
    getSubContr();
  }, []);

  const getSubAccountList = () => {
    fetch(`${baseUrl}/show_fees_account_head`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("list", data);
        setsubAccountList(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // contr list
  const getSubContr = () => {
    fetch(`${baseUrl}/subaccount_contra`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("list", data);
        setsubAccountcontrList(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const returnFee = () => {
    const data = {
      student_id: studentDetails.id,
      fee_sub_account_id: sub_account_id,
      fee_return_amount,
      sub_account_id: sub_account_contra_id,
      remarks,
    };

    if (fee_return_amount == "" || sub_account_id == "") {
      alert.error("Please fill all fields");
    } else {
      setloading(true);
      fetch(`${baseUrl}/fee_return`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata.success) {
            hide();
            alert.success(data.metadata.message);
            reload();
            setloading(false);
          } else {
            alert.error(data.metadata.message);
            setloading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    }
  };
  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };
  return (
    <Modal size="lg" show={show}>
      <Modal.Header className="modal_header_style" closeButton>
        <Modal.Title
          className="Modal_Heading_font"
          id="contained-modal-title-vcenter"
        >
          Fee Return To {studentDetails?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-4">
            <label>Student Campus</label>
            <input
              disabled
              className="form-control"
              value={studentDetails?.campus?.name}
            />
          </div>
          <div className="col-md-4">
            <label>Student Class</label>
            <input
              disabled
              className="form-control"
              value={studentDetails?.student_class?.name}
            />
          </div>
          <div className="col-md-4">
            <label>Student Section</label>
            <input
              disabled
              className="form-control"
              value={studentDetails?.global_section?.name}
            />
          </div>
        </div>
        <div className="MydividerContainer">
          <h4>Fee Return</h4>
          <div></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>Return Amount</label>
            <input
              type={"number"}
              className="form-control"
              onChange={(e) => setfee_return_amount(e.target.value)}
              placeholder="Return Amount"
            />
          </div>
          <div className="col-md-4">
            <label>Sub Account</label>
            <select
              onChange={(e) => setsub_account_id(e.target.value)}
              className="form-control"
            >
              <option>Select</option>
              {subAccountList.map((item) => (
                <option
                  value={item.id}
                  key={item.id}
                >{`${item.title}-${item.acode}`}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label>Sub Account Contra</label>
            <select
              onChange={(e) => setsub_account_contra_id(e.target.value)}
              className="form-control"
            >
              <option>Select</option>
              {subAccountcontrList.map((item) => (
                <option
                  value={item.id}
                  key={item.id}
                >{`${item.title}-${item.acode}`}</option>
              ))}
            </select>
          </div>
          <div className="col-md-12 mt-3">
            <label>Remarks</label>
            <input
              onChange={(e) => setremarks(e.target.value)}
              className="form-control"
              placeholder="Remarks"
            />
          </div>
        </div>

        <div className="d-flex">
          <div style={{ width: "400px", height: "400px", overflow: "scroll" }}>
            <div className="MydividerContainer">
              <h4>Fee Challan History</h4>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Fee Challan Id
                  </th>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Fee Month
                  </th>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Fee Name
                  </th>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Amount
                  </th>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Status
                  </th>
                  {/* <th>Edit</th>
                    <th>Delete</th>
                    <th>Print</th> */}
                </tr>
              </thead>
              <tbody>
                {challanHistory &&
                  challanHistory.map((challan) => (
                    <tr>
                      <td style={{ fontSize: 10 }} className="text-center">
                        {challan.fee_challan?.challan_no}
                      </td>
                      <td style={{ fontSize: 10 }} className="text-center">
                        {getMonthName(challan.fee_month)}
                      </td>
                      <td style={{ fontSize: 10 }} className="text-center">
                        {challan.fee_name}
                      </td>
                      <td style={{ fontSize: 10 }} className="text-center">
                        {challan.amount}
                      </td>
                      <td
                        style={{
                          fontSize: 10,
                          color:
                            challan?.fee_challan?.status == 0
                              ? "Red"
                              : challan?.fee_challan?.status == 1
                              ? "orange"
                              : challan?.fee_challan?.status == 2
                              ? "Green"
                              : "",
                        }}
                        className="text-center"
                      >
                        {challan?.fee_challan?.status == 0 ? "UnPaid" : "Paid"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div style={{ width: "400px", height: "400px", overflow: "scroll" }}>
            <div className="MydividerContainer">
              <h4>Fee Return History</h4>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Fee Return Id
                  </th>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Fee Type
                  </th>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Account
                  </th>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Amount
                  </th>
                  <th style={{ fontSize: 10 }} className="text-center">
                    Remarks
                  </th>
                  {/* <th>Edit</th>
                    <th>Delete</th>
                    <th>Print</th> */}
                </tr>
              </thead>
              <tbody>
                {feeReturnHistory &&
                  feeReturnHistory.map((feereturn) => (
                    <tr>
                      <td style={{ fontSize: 10 }} className="text-center">
                        {feereturn?.id}
                      </td>
                      <td style={{ fontSize: 10 }} className="text-center">
                        {feereturn?.fee_sub_account?.title}
                      </td>
                      <td style={{ fontSize: 10 }} className="text-center">
                        {feereturn?.sub_account?.title}
                      </td>
                      <td style={{ fontSize: 10 }} className="text-center">
                        {feereturn?.fee_return_amount}
                      </td>
                      <td
                        style={{
                          fontSize: 10,
                        }}
                        className="text-center"
                      >
                        {feereturn?.remarks}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <button onClick={returnFee} className="btn btn-primary">
              Submit
            </button>
            <button onClick={hide} className="btn btn-danger">
              Close
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default FeeReturnModal;
