import React, { useEffect, useState, useRef } from "react";
import { Divider } from "antd";
import { Hidden, LinearProgress, Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { Spinner, Table, Button, Modal } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { DatePicker, Space } from "antd";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../components/usePagination/usePagination";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { DownloadTableExcel } from "react-export-table-to-excel";
const { RangePicker } = DatePicker;

const AdvancedFeeReceive = () => {
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  const [sessionname, setsessionname] = useState([]);
  const user = useSelector((state) => state.user.user);
  let authtoken = localStorage.getItem("userToken");

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [pastChallanModal, setPastChallanModal] = useState(false);
  const [selectedChallan, setSelectedChallan] = useState([]);

  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();
  const pageStyle = `
  @page {
    size: landscape !important;
}
`;
  useEffect(() => {
    getSettingData();
    getAllSessions();
  }, []);

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const printingDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            setsessionname(item.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleShow = () => {
    setLoading(true);
    setIsVisible(false);

    fetch(`${baseUrl}/advance-fee-received-report`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setIsVisible(true);
        if (data.metadata.success) {
          // let res = data.payload.sort(
          //   (a, b) => a.student.admission_id - b.student.admission_id
          // );
          let temparr = [];
          data.payload.map((item, index) => {
            item["SrNo"] = index + 1;
            temparr[index] = item;
          });
          console.log(temparr);
          setreportData(temparr);
        } else {
          alert.error("No Record Found");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  const gotoviewpage = (data) => {
    setSelectedChallan(data);
    setPastChallanModal(true);
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(reportData.length / PER_PAGE);
  const _DATA = usePagination(reportData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <Paper className="mb-6 mt-6 p-5">
        <div className="row mt-3">
          <div className="col-md-6 d-flex">
            <button onClick={handleShow} className="btn btn-primary mx-2">
              Get Advance Fee Receive
            </button>
            {reportData.length > 0 ? (
              <>
                <ReactToPrint
                  pageStyle={pageStyle}
                  documentTitle={`Advance Fee Receive Report`}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-105px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <DownloadTableExcel
                  filename="Advance Fee Receive Report"
                  sheet="Advance Fee Receive Report"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-primary mx-2"> Export </button>
                </DownloadTableExcel>
              </>
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        {isVisible ? (
          <>
            <div className="mt-8">
              <div className="d-flex">
                <div>
                  <img
                    style={{ height: 120, width: 110 }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h4> Student Advanced Fee Receive Report</h4>
                  <div className="d-flex justify-content-between">
                    <h5>Session : {sessionname}</h5>
                    <h5>Printing Date: {printingDate()}</h5>
                  </div>
                </div>
              </div>
            </div>
            <Table responsive className="mt-5">
              <thead>
                <tr id="tbl" className="text-center">
                  <th>Sr No</th>
                  <th>AdmID</th>
                  <th>Student Name</th>
                  <th>Father Name</th>
                  <th>Mobile No</th>
                  <th>Campus Name</th>
                  <th>Class (Section)</th>
                  <th>Monthly Fee</th>
                  <th>Last Month</th>
                  <th>Last Fee Paid</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {_DATA.currentData().map((reportData, index) => (
                  <tr key={index}>
                    <td>{reportData.SrNo}</td>
                    <td>{reportData?.admission_id}</td>
                    <td>{reportData?.name}</td>
                    <td>{reportData?.father_name}</td>
                    <td>{reportData?.mobile_no}</td>
                    <td>{reportData?.campus?.name}</td>
                    <td>
                      {reportData?.student_class?.name} (
                      {reportData?.global_section?.name})
                    </td>
                    <td>
                      {
                        reportData?.fee_challans[
                          reportData?.fee_challans.length - 1
                        ]?.payable
                      }
                    </td>
                    <td>
                      {getMonthName(
                        reportData?.fee_challans[
                          reportData?.fee_challans.length - 1
                        ]?.fee_challan_details[0]?.fee_month
                      )}
                    </td>
                    <td>
                      {
                        reportData?.fee_challans[
                          reportData?.fee_challans.length - 1
                        ]?.received_date
                      }
                    </td>
                    <td>
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm "
                        onClick={() => {
                          setSelectedChallan(reportData);
                          setPastChallanModal(true);
                        }}
                      >
                        <span className="svg-icon text_align_start svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Devices/Display2.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Pagination
              color="primary"
              count={count}
              size="large"
              page={page}
              variant="text"
              shape="round"
              onChange={handleChange}
            />
          </>
        ) : null}

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mainContainer">
            <div className="logocontainer" style={{ height: "100px" }}>
              <div className="d-flex">
                <div>
                  <img
                    style={{ height: 120, width: 110 }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h4> Student Advanced Fee Receive Report</h4>
                  <div className="d-flex justify-content-between">
                    <h5>Session : {sessionname}</h5>
                    <h5>Printing Date: {printingDate()}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <Table
                style={{
                  fontSize: "12px",
                  whiteSpace: "normal",
                  padding: "1px",
                  marginTop: "15px",
                }}
                responsive
              >
                <thead
                  style={{
                    fontSize: "12px",
                    whiteSpace: "normal",
                    padding: "1px",
                  }}
                >
                  <tr
                    id="tbl"
                    className="text-center"
                    style={{
                      fontSize: "12px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Sr No
                    </th>
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      AdmID
                    </th>
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Father Name
                    </th>
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Mobile No
                    </th>
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Campus Name
                    </th>
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Class (Section)
                    </th>
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Monthly Fee
                    </th>
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Last Month
                    </th>
                    <th
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Last Fee Paid
                    </th>
                  </tr>
                </thead>
                {reportData.map((reportData, index) => (
                  <tr
                    key={index}
                    style={{
                      fontSize: "12px",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {reportData?.SrNo}
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {reportData?.admission_id}
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {/* {campus.student.name} */}
                      {reportData?.name.length > 15
                        ? reportData?.name.substring(0, 15) + "..."
                        : reportData?.name}
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {/* {campus.student.father_name} */}
                      {reportData?.father_name.length > 15
                        ? reportData?.father_name.substring(0, 15) + "..."
                        : reportData?.father_name}
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {reportData?.mobile_no}
                    </td>

                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {reportData?.campus?.name}
                    </td>

                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {reportData?.student_class?.name.length > 7
                        ? reportData?.student_class?.name.substring(0, 5)
                        : reportData?.student_class?.name}
                      -
                      {reportData?.global_section?.name.length > 7
                        ? reportData?.global_section?.name.substring(0, 5)
                        : reportData?.global_section?.name}
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {
                        reportData?.fee_challans[
                          reportData?.fee_challans.length - 1
                        ]?.payable
                      }
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {getMonthName(
                        reportData?.fee_challans[
                          reportData?.fee_challans.length - 1
                        ]?.fee_challan_details[0]?.fee_month
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {
                        reportData?.fee_challans[
                          reportData?.fee_challans.length - 1
                        ]?.received_date
                      }
                    </td>
                  </tr>
                ))}
              </Table>
            </div>
          </div>
        </div>

        {/* //for export///// */}
        <div
          ref={tableRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <Table responsive className="mt-5">
            <thead>
              <tr id="tbl" className="text-center">
                <th>Sr No</th>
                <th>AdmID</th>
                <th>Student Name</th>
                <th>Father Name</th>
                <th>Mobile No</th>
                <th>Campus Name</th>
                <th>Class (Section)</th>
                <th>Monthly Fee</th>
                <th>Last Month</th>
                <th>Last Fee Paid</th>
              </tr>
            </thead>
            <tbody>
              {reportData.map((reportData, index) => (
                <tr key={index}>
                  <td>{reportData.SrNo}</td>
                  <td>{reportData?.admission_id}</td>
                  <td>{reportData?.name}</td>
                  <td>{reportData?.father_name}</td>
                  <td>{reportData?.mobile_no}</td>
                  <td>{reportData?.campus?.name}</td>
                  <td>
                    {reportData?.student_class?.name} (
                    {reportData?.global_section?.name})
                  </td>
                  <td>
                    {
                      reportData?.fee_challans[
                        reportData?.fee_challans.length - 1
                      ]?.payable
                    }
                  </td>
                  <td>
                    {getMonthName(
                      reportData?.fee_challans[
                        reportData?.fee_challans.length - 1
                      ]?.fee_challan_details[0]?.fee_month
                    )}
                  </td>
                  <td>
                    {
                      reportData?.fee_challans[
                        reportData?.fee_challans.length - 1
                      ]?.received_date
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Modal show={pastChallanModal} size="xl">
          <Modal.Header>
            <Modal.Title>Student Fee Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column justify-content-end">
            <div style={{ height: "400px", overflow: "scroll" }}>
              <Table>
                <thead>
                  <tr id="tbl">
                    <th style={{ fontSize: 15 }}>
                      <span style={{ color: "#121291" }}>Student Name:</span>{" "}
                      {selectedChallan?.name}
                    </th>
                    <th style={{ fontSize: 15 }}>
                      <span style={{ color: "#121291" }}>Father Name:</span>{" "}
                      {selectedChallan?.father_name}
                    </th>
                    <th style={{ fontSize: 15 }}>
                      <span style={{ color: "#121291" }}> Admission ID: </span>{" "}
                      {selectedChallan?.admission_id}
                    </th>
                  </tr>
                  <tr id="tbl">
                    <th style={{ fontSize: 15 }}>
                      <span style={{ color: "#121291" }}>Class:</span>{" "}
                      {selectedChallan?.student_class?.name +
                        " " +
                        selectedChallan?.global_section?.name}
                    </th>
                    <th style={{ fontSize: 15 }}>
                      <span style={{ color: "#121291" }}>Campus: </span>{" "}
                      {selectedChallan?.campus?.name}
                    </th>
                  </tr>
                </thead>
              </Table>
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{ fontSize: 15 }} className="text-center">
                      Challan No
                    </th>
                    <th style={{ fontSize: 15 }} className="text-center">
                      Fee Month
                    </th>
                    <th style={{ fontSize: 15 }} className="text-center">
                      Fee Name
                    </th>
                    <th style={{ fontSize: 15 }} className="text-center">
                      Received Date
                    </th>
                    <th style={{ fontSize: 15 }} className="text-center">
                      Due Date
                    </th>
                    <th style={{ fontSize: 15 }} className="text-center">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedChallan &&
                    selectedChallan?.fee_challans?.map((challan) => (
                      <tr>
                        <td style={{ fontSize: 14 }} className="text-center">
                          {challan.challan_no}
                        </td>
                        <td style={{ fontSize: 14 }} className="text-center">
                          {getMonthName(
                            challan?.fee_challan_details[0]?.fee_month
                          )}
                        </td>
                        <td style={{ fontSize: 14 }} className="text-center">
                          {challan?.fee_challan_details[0]?.fee_name}
                        </td>
                        <td style={{ fontSize: 14 }} className="text-center">
                          {challan?.received_date}
                        </td>
                        <td style={{ fontSize: 14 }} className="text-center">
                          {challan?.due_date}
                        </td>
                        <td style={{ fontSize: 14 }} className="text-center">
                          {challan?.fee_challan_details[0]?.amount}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-end mt-5 mb-4">
              <button
                onClick={() => {
                  setPastChallanModal(false);
                }}
                className="btn btn-danger me-2"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </Paper>
    </>
  );
};

export default AdvancedFeeReceive;
